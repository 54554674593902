/**
 * Imports
 */
import * as React from 'react';

import styled from 'styled-components';
import { Text, VibrantIcon, Anchor, CreditCard } from 'components';
import { version } from '../../../../../package.json';

/**
 * Styling
 */
const Root = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.Border.BorderPrimary};
  padding: 24px 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Left = styled.div`
  display: flex;
  align-items: center;
`;
const Right = styled.div`
  display: flex;
  align-items: center;
`;
const StyledAnchor = styled(Anchor)`
  margin-right: 48px;
`;
const PartnerText = styled(Text)`
  margin-left: 12px;
`;

/**
 * Types
 */
export interface FooterProps {}

/**
 * Footer component
 */
const Footer: React.FC<FooterProps> = (_props) => {
  return (
    <Root>
      <Left>
        <StyledAnchor size="ui3" href="https://www.vibrant.io/" target="_blank">
          © Vibrant.io
        </StyledAnchor>
        <CreditCard card="VISA" size="sm" />
        <PartnerText size="ui3">Official partner of Visa</PartnerText>
      </Left>
      <Right>
        <StyledAnchor
          size="ui3"
          href="https://www.vibrant.io/policies/privacy-policy"
          target="_blank"
        >
          Privacy policy
        </StyledAnchor>
        <StyledAnchor
          size="ui3"
          href="https://www.vibrant.io/cookie-policy"
          target="_blank"
        >
          Cookie policy
        </StyledAnchor>
        <Text size="ui3">v. {version}</Text>
      </Right>
    </Root>
  );
};

/**
 * Exports
 */
export { Footer };
