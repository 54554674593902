/**
 * Imports
 */
import { List as AntdList } from 'antd';
import styled from 'styled-components';

/**
 * Styled components
 */
const List = styled(AntdList)`
  && {
    .ant-list-item {
      cursor: pointer;
      padding: 32px;
      &:hover {
        background-color: ${({ theme }) => theme.colors.Background.Primary};
      }
    }
    .ant-list-item-meta {
      align-items: center;
    }
    .ant-list-item-meta-title {
      font-size: 17px;
      line-height: 21.76px;
      margin-bottom: 0;
    }
  }
`;

List.Item = styled(List.Item)`
  && {
    .ant-list-item {
      padding: 40px;
    }
  }
`;

/**
 * Exports
 */
export { List };
