/**
 * Imports
 */
import { Spin } from 'antd';
import { integrationFactory } from 'apis';
import { addedIntegration } from 'features/integrations/redux/actions';
import { maybeUserSelector } from 'features/user/redux/selectors';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Firebase } from 'types/firebase';
import { captureError } from 'utils/error-logging';

const Root = styled.div`
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
`;

/**
 * LoginPage component
 */
const IntegrationCallbackPage: React.FC = () => {
  const [badIntegration, setBadIntegration] = React.useState(false);
  const [statusText, setStatusText] = React.useState('Finalising integration');
  const { integrationName } = useParams<{
    integrationName: Firebase.IntegrationNames;
  }>();
  const { search } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(maybeUserSelector);

  const tryPushCredentials = async () => {
    try {
      const integration = integrationFactory(integrationName);
      if (integration) {
        const done = await integration.syncData(search, user?.meta.merchantId);
        if (done) {
          if (integrationName === 'dinero') {
            dispatch(addedIntegration({}));
            setStatusText('Integration done');
            window.close();
          }
          if (integrationName === 'economic') {
            dispatch(addedIntegration({}));
            setStatusText('Integration done');
            history.replace('/integrations?open_modal=economic');
          }
        }
      }
    } catch (e) {
      captureError('Integration failed', e);
      setBadIntegration(true);
    }
  };

  React.useEffect(() => {
    tryPushCredentials();
  }, []);

  if (badIntegration) {
    return <Root>Integration failed</Root>;
  }

  return (
    <Root>
      <Spin /> <div>{statusText}</div>
    </Root>
  );
};

/**
 * Exports
 */
export { IntegrationCallbackPage };
