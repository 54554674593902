/**
 * Imports
 */
import { createSelector } from '@reduxjs/toolkit';
import { IntegrationFeature } from 'features/integrations';
import { FirebaseCollectionsType } from 'firebaseAPI';

import { VibrantlyState } from 'store/types';
import { Firebase } from 'types/firebase';

/**
 * Selectors
 */
export const rootSelector = (state: VibrantlyState) => state.user;
const firebaseSelector = (state: VibrantlyState) => state.firebase;
/**
 * Firestore
 */
export const firebaseUserSelector = createSelector(
  [firebaseSelector],
  (root) => root.auth,
);
export const firestoreRootSelector = createSelector(
  (state: VibrantlyState) => state.firestore,
  (root) => root,
);
/**
 * User
 */
export const emailSelector = createSelector(
  [firebaseUserSelector],
  (root) => root.email,
);
export const maybeUserSelector = createSelector(
  [rootSelector],
  (root) => root.user,
);
export const userSelector = createSelector(
  [rootSelector],
  (root) => root.user!,
);
export const userFirstnameSelector = createSelector(
  [firebaseUserSelector],
  (user) => {
    return user.displayName?.split(' ')?.[0];
  },
);
/**
 * Collection
 */
export const dashboardSettingsSelector = createSelector(
  [firestoreRootSelector],
  (firestore) => firestore.data.softPosDashboardSettings,
);
export const merchantCollectionSelector = createSelector(
  [firestoreRootSelector],
  (firestore) => firestore.data.merchants as Firebase.Merchant,
);
export const isDanishMerchantSelector = createSelector(
  [merchantCollectionSelector],
  (merchant) => {
    return merchant?.countryCode === 'DK';
  },
);
export const integrationCollectionSelector = createSelector(
  [firestoreRootSelector],
  (firestore) =>
    firestore.data[FirebaseCollectionsType.MERCHANT_INTEGRATIONS] as {
      [key in IntegrationFeature.Options]: Firebase.MerchantIntegration;
    },
);
export const terminalCollectionSelector = createSelector(
  [merchantCollectionSelector],
  (root) => root?.terminalCollection,
);
/**
 * User types
 */
export const isFeatureTesterSelector = createSelector([rootSelector], (root) =>
  root.user?.userRoles.includes('feature-tester'),
);
export const isLink4PaySelector = createSelector(
  [firestoreRootSelector],
  (firestore) => ['GR', 'CY'].includes(firestore.data?.merchants?.countryCode),
);
export const isSupporterSelector = createSelector([rootSelector], (root) =>
  root.user?.userRoles.includes('softpos-supporter'),
);
export const merchantRolesSelector = createSelector(
  [rootSelector],
  (root) => root.user?.merchantRoles,
);
