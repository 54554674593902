/**
 * Imports
 */
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { firebaseTokenLogin } from 'firebaseAPI';
import { BaseLayout } from 'features/navigation';
import { Anchor, Spinner, Text } from 'components';
import { captureError } from 'utils/error-logging';
import styled from 'styled-components';
import { Trans } from 'react-i18next';

const Root = styled.div`
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
`;

/**
 * LoginPage component
 */
const TokenLoginPage: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [tokenInvalid, setTokenInvalid] = React.useState(false);
  const navigate = useHistory();
  const { token } = useParams<{ token: string }>();

  const _navigateToHome = () => {
    navigate.replace({ pathname: '/' });
  };
  const tryTokenLogin = async () => {
    setIsLoading(true);
    setTokenInvalid(false);
    try {
      await firebaseTokenLogin(token);
      setIsLoading(false);
      _navigateToHome();
    } catch (e) {
      captureError('Token login failed', e);
      setTokenInvalid(true);
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    tryTokenLogin();
  }, []);

  if (tokenInvalid) {
    return (
      <BaseLayout disableBreadcrumb>
        <Root>
          <Text size="h5">
            <Trans i18nKey="_Common.actions.contact_support">
              <Anchor
                size="h5"
                href="https://www.vibrant.io/contact"
                color="brand"
              />
            </Trans>
          </Text>
        </Root>
      </BaseLayout>
    );
  }

  return (
    <BaseLayout disableBreadcrumb>
      <Root>
        <Spinner showSpinner={isLoading} size={128} />
      </Root>
    </BaseLayout>
  );
};

/**
 * Exports
 */
export { TokenLoginPage };
