/**
 * Notes
 */
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
// https://www.iban.com/currency-codes
// https://www2.1010data.com/documentationcenter/beta/1010dataReferenceManual/DataTypesAndFormats/currencyUnitCodes.html

/**
 * Imports
 */
import { SoftPos } from 'types/softpos';

export const formatCurrency = (
  amount: number,
  currencyIsoNumber?: SoftPos.CurrencyISOCode,
  language?: string,
): string | undefined => {
  try {
    // TODO: Get lang form  dashboard settings
    const lang = language ? language : 'da'; // en-GB | da

    if (currencyIsoNumber) {
      const formattedCurrency = new Intl.NumberFormat(lang, {
        style: 'currency',
        currency: currencyIsoNumber,
      }).format(amount);

      return formattedCurrency;
    } else {
      const formattedCurrency = new Intl.NumberFormat(lang).format(amount);
      return formattedCurrency;
    }
  } catch (e) {
    console.log('error in currency');
  }
};
