/**
 * Imports
 */
import { ColumnsType } from 'antd/lib/table/interface';
import { SoftPos } from 'types/softpos';
import { CreditCard, Table, Text, VibrantIcon } from 'components';
import {
  TransactionsStatus,
  useRecentTransactions,
} from 'features/transactions';
import moment from 'moment';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'router/RootRouter';
import styled from 'styled-components';
import { DATE_FORMATS, formatCurrency } from 'utils';
import { Firebase } from 'types/firebase';
import { useSelector } from 'react-redux';
import { terminalCollectionSelector } from 'features/user/redux/selectors';

/**
 * Styling
 */
const Root = styled.div`
  margin: 48px 0;
  .ant-pagination {
    display: none;
  }
`;
const HeaderRow = styled.div`
  padding: 48px 0 24px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 600px) {
    padding: 0 0 16px;
  }
`;
const Button = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .vibranticon {
    margin-left: 12px;
  }
`;
/**
 * Types
 */
export interface RecentTransactionsProps {}

/**
 * Table columns
 */
const columns = (
  terminalCollection: Firebase.Terminal[],
): ColumnsType<SoftPos.Trxn> => [
  {
    title: () => <Trans i18nKey="Transactions.table_header.card_source" />,
    dataIndex: 'cardSource',
    key: 'cardSouce',
    render: (cardSource: SoftPos.CardSource) => {
      return <CreditCard rounded card={cardSource} />;
    },
  },
  {
    title: () => <Trans i18nKey="Transactions.table_header.date" />,
    dataIndex: 'date',
    key: 'date',
    render: (date: string) => {
      return <Text size="ui2">{moment(date).format(DATE_FORMATS.time)}</Text>;
    },
  },
  {
    title: () => <Trans i18nKey="Transactions.table_header.status" />,
    dataIndex: 'status',
    responsive: ['sm'],
    key: 'status',
    render: (_status: SoftPos.TrxnStatus, record: SoftPos.Trxn) => {
      return <TransactionsStatus trxn={record} />;
    },
  },
  {
    title: () => <Trans i18nKey="Transactions.table_header.terminal_id" />,
    dataIndex: 'terminalId',
    responsive: ['sm'],
    key: 'terminalId',
    render: (terminalId: string) => {
      return <Text size="ui2">{terminalId}</Text>;
    },
  },
  {
    title: () => <Trans i18nKey="Transactions.table_header.amount" />,
    dataIndex: 'amount',
    key: 'amount',
    render: (amount: number, record: SoftPos.Trxn) => {
      return <Text size="ui2">{formatCurrency(amount, record.currency)}</Text>;
    },
  },
  {
    title: () => <Trans i18nKey="Transactions.table_header.terminal_name" />,
    dataIndex: 'terminalId',
    key: 'terminalId',
    render: (terminalId: string) => {
      const name = terminalCollection.find(
        (terminal) => terminal.terminalId === terminalId,
      )?.name;
      return <Text size="ui2">{name}</Text>;
    },
  },
];

/**
 * RecentTransactions component
 */
const RecentTransactions: React.FC<RecentTransactionsProps> = (props) => {
  const navigation = useHistory();
  const { transactions, isFecthing } = useRecentTransactions();
  const terminalCollection = useSelector(terminalCollectionSelector);

  /**
   * OnRowClick
   */
  const _onRowClick = (trxnId: string) => {
    const path = `${ROUTES.transactionDetails.path}/${trxnId}`;
    navigation.push(path);
  };
  const _viewTrxn = () => {
    const path = `${ROUTES.transactions.path}`;
    navigation.push(path);
  };

  return (
    <Root>
      <HeaderRow>
        <Text size="ui2" bold>
          <Trans i18nKey="Home.recent_transactions.header" />
        </Text>
      </HeaderRow>
      <Table<SoftPos.Trxn>
        columns={columns(terminalCollection)}
        dataSource={transactions}
        pagination={false}
        loading={isFecthing}
        rowKey={(record) => record.trxnId}
        onRow={(record) => {
          const id = record.trxnId;
          return {
            onClick: () => _onRowClick(id),
          };
        }}
      />
      <Button>
        <Text onClick={_viewTrxn} size="ui2" link color="brand">
          <Trans i18nKey="Home.recent_transactions.btn_view_more" />
          <VibrantIcon icon="arrow-long-right" color="Orange500" />
        </Text>
      </Button>
    </Root>
  );
};

/**
 * Exports
 */
export { RecentTransactions };
