/**
 * Imports
 */
import { createTerminal } from 'apis';
import * as React from 'react';

/**
 * Types
 */
export type TerminalAPIHandlers = {
  onCreateTerminal: (name: string) => Promise<void>;
};
export type TerminalAPIType = {
  handlers: TerminalAPIHandlers;
  isLoading: boolean;
};

/**
 * useTerminal
 */
const useTerminal = (): TerminalAPIType => {
  const [isLoading, setIsLoading] = React.useState(false);

  const handlers: TerminalAPIHandlers = {
    /**
     * onCreateTerminal
     */
    onCreateTerminal: async (name: string) => {
      setIsLoading(true);
      await createTerminal(name);
      setIsLoading(false);
    },
  };

  return { handlers, isLoading };
};

/**
 * Exports
 */
export { useTerminal };
