/**
 * Imports
 */
import { createAction } from '@reduxjs/toolkit';
import { VibrantlyAction } from 'store/types';

/**
 * Constants
 */
const PREFIX = 'integrations';

/**
 * ADDED_INTEGRATION
 */
export const ADDED_INTEGRATION = `${PREFIX}/ADDED_INTEGRATION`;
export type AddIntegrationAction = VibrantlyAction<object>;
export const addedIntegration = createAction<AddIntegrationAction['payload']>(
  ADDED_INTEGRATION,
);
