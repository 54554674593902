import React, { FC, useState } from 'react';
import { Input, Modal as AntdModal } from 'antd';
import styled, { keyframes } from 'styled-components';
import { ModalProps } from 'antd/lib/modal';
import { Button, Space, Text, VibrantIcon } from 'components';
import { ProductInput, VatSlider } from '..';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { SchemaOf, object, string, number } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ProductData } from 'features/products/types';
import {
  closeProductModal,
  openProductModal,
} from 'features/products/redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { productModalOpen } from 'features/products/redux/selectors';
import { userSelector } from 'features/user/redux/selectors';
import { addProduct } from 'features/products/api';

const Modal = styled(AntdModal)`
  .ant-modal-content {
    border-radius: 16px;
  }

  .ant-modal-footer {
    border: none;
    padding: 24px;
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const animationDuration = '1s';
const animationIterationCount = '1';

const fadeInCheckmark = keyframes`
0% {
  stroke: transparent;
}
25% {
  stroke: white;
}
100% {
  stroke: white;
}
`;

const fadeInInnerCircle = keyframes`
  0% {
    stroke: transparent;
  }
  25% {
    stroke: transparent;
  }
  50% {
    stroke: #EFC8AC;
  }
  100% {
    stroke: #EFC8AC;
  }
`;

const fadeInOuterCircle = keyframes`
  0% {
    stroke: transparent;
  }
  50% {
    stroke: transparent;
  }
  75% {
    stroke: #F4E5DA;
  }
  100% {
    stroke: #F4E5DA;
  }
`;

const fadeInText = keyframes`
0% {
  color: transparent;
}
50% {
  color: transparent;
}
75%{
  color: black;
}
100% {
  color: black;
}
`;

const Done = styled.article`
  width: 100%;
  height: 100%;
  min-height: 375px;
  display: flex;
  flex-direction: column;

  .top {
    display: flex;
    justify-content: flex-end;
  }

  .main {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    animation-name: ${fadeInText};
    animation-duration: ${animationDuration};
    animation-iteration-count: ${animationIterationCount};

    svg {
      padding-bottom: 16px;
    }

    .check {
      animation-name: ${fadeInCheckmark};
      animation-duration: ${animationDuration};
      animation-iteration-count: ${animationIterationCount};
    }

    .inner-circle {
      animation-name: ${fadeInInnerCircle};
      animation-duration: ${animationDuration};
      animation-iteration-count: ${animationIterationCount};
    }

    .outer-circle {
      animation-name: ${fadeInOuterCircle};
      animation-duration: ${animationDuration};
      animation-iteration-count: ${animationIterationCount};
    }
  }

  .foot {
    display: flex;
    justify-content: center;
  }
`;

export type AddProductModalProps = Omit<
  ModalProps,
  'closable' | 'title' | 'onCancel'
> & {
  vat: string[];
};

const ProductSchema: SchemaOf<ProductData> = object().shape({
  name: string().required(),
  price: number().required().positive(),
  vat: number().required(),
});

export const AddProductModal: FC<AddProductModalProps> = ({ vat, ...rest }) => {
  const { t } = useTranslation();
  const { formState, control, handleSubmit, reset } = useForm<ProductData>({
    resolver: yupResolver(ProductSchema),
  });
  type ModalState = 'adding' | 'done';
  const [modalState, setModalState] = useState<ModalState>('adding');
  const dispatch = useDispatch();
  const open = useSelector(productModalOpen);
  const toggleModal = () => {
    dispatch(open ? closeProductModal({}) : openProductModal({}));
  };

  const { meta } = useSelector(userSelector);

  const onSubmit = handleSubmit(
    async (data) => {
      await addProduct(data, meta.merchantId);
      reset();
      setModalState('done');
    },
    ({ name, price, vat }) => {
      console.log('error', { name, price, vat });
    },
  );

  return (
    <Modal {...rest} closable={false} footer={null}>
      {modalState === 'done' && (
        <Done>
          <div className="top">
            <Button
              type="text"
              onClick={() => {
                toggleModal();
                setModalState('adding');
              }}
            >
              <VibrantIcon icon="close" />
            </Button>
          </div>
          <div className="main">
            <svg
              width="106"
              height="106"
              viewBox="0 0 106 106"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                className="orange-circle"
                cx="53.2"
                cy="53"
                r="31.2"
                fill="#FF6B00"
              />
              <circle
                className="inner-circle"
                cx="52.6"
                cy="53.0001"
                r="41.6"
                stroke="#EFC8AC"
              />
              <circle
                className="outer-circle"
                cx="53"
                cy="53"
                r="52"
                stroke="#F4E5DA"
              />
              <path
                className="check"
                d="M42.6001 54.3L49.1001 60.8L63.4001 47.8"
                strokeWidth="2"
                stroke="#FFFFFF"
                strokeLinejoin="round"
              />
            </svg>
            <Text bold size="ui2">
              {t('Products.AddProductModal.product_added_succesfully')}
            </Text>
          </div>
          <div className="foot">
            <Button
              type="default"
              style={{ borderRadius: '28px', width: '100%', maxWidth: '320px' }}
              onClick={() => setModalState('adding')}
            >
              {t('Products.AddProductModal.add_new_product')}
            </Button>
          </div>
        </Done>
      )}
      {modalState === 'adding' && (
        <form onSubmit={onSubmit}>
          <Text size="ui2" bold>
            {t('Products.Common.add_product')}
          </Text>
          <Space />
          <ProductInput
            error={formState.errors.name}
            label={t('Products.AddProductModal.product_name')}
          >
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <Input
                  type="text"
                  placeholder={t(
                    'Products.AddProductModal.product_name_placeholder',
                  )}
                  {...field}
                />
              )}
            />
          </ProductInput>
          <Space />
          <ProductInput
            error={formState.errors.price}
            label={t('Products.AddProductModal.product_price')}
          >
            <Controller
              name="price"
              control={control}
              render={({ field }) => (
                <Input
                  type="number"
                  placeholder={t(
                    'Products.AddProductModal.product_price_placeholder',
                  )}
                  {...field}
                />
              )}
            />
          </ProductInput>
          <Space />
          <Controller
            control={control}
            name="vat"
            render={({ field }) => <VatSlider values={vat} field={field} />}
          />
          <Space />
          <Footer>
            <Button
              type="ghost"
              onClick={() => {
                reset();
                toggleModal();
              }}
              style={{ marginRight: '32px' }}
            >
              {t('Products.AddProductModal.cancel')}
            </Button>
            <Button
              type="default"
              disabled={formState.isSubmitting}
              style={{ borderRadius: '28px' }}
              onClick={onSubmit}
            >
              {t('Products.Common.add_product')}
            </Button>
          </Footer>
        </form>
      )}
    </Modal>
  );
};
