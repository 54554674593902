/**
 * Imports
 */
import React, { useMemo } from 'react';
import { Button } from 'antd';

import { Text, VibrantIcon } from 'components';
import { useLocation, useHistory } from 'react-router-dom';
import { ROUTES, RoutesType } from 'router/RootRouter';

import * as Styles from './Breadcrumb.styles';

/**
 * Types
 */
export interface BreadcrumbProps {}

/**
 * Breadcrumb component
 */
const Breadcrumb: React.FC<BreadcrumbProps> = (props) => {
  const location = useLocation();
  const history = useHistory();

  const pageDetails = useMemo(() => {
    const params = location.pathname.split('/');
    const pathName = params?.[1] as RoutesType | null;
    const pathValue = params?.[2];

    if (pathName == null) {
      return null;
    }

    // if there are more than 2 slashes then search an appropirate path
    const pathKey = params.length > 2 ? `/${pathName}/` : pathName;
    const route = Object.values(ROUTES).find((x) => x.route.includes(pathKey));

    if (route == null) {
      return null;
    }

    const routePageName = route.pageName;

    return { title: routePageName, value: pathValue };
  }, [location.pathname]);

  return (
    <Styles.Container>
      {pageDetails?.title != null && pageDetails?.value == null && (
        <Text size="ui2" bold>
          {pageDetails.title()}
        </Text>
      )}

      {pageDetails?.value != null && (
        <>
          <Button type="text" onClick={() => history.goBack()}>
            <VibrantIcon icon="arrow-long-left" />
          </Button>

          <Styles.TextValueContainer>
            <Text size="ui2">
              {pageDetails.title}: <b>#{pageDetails.value}</b>
            </Text>
          </Styles.TextValueContainer>
        </>
      )}
    </Styles.Container>
  );
};

/**
 * Exports
 */
export { Breadcrumb };
