/**
 * Imports
 */
import { SelectValue } from 'antd/lib/select';
import { Select } from 'components';
import { BaseLayout } from 'features/navigation';
import { FixedDatePicker } from 'features/shared';
import {
  DatePicker,
  TransactionsExportSelect,
  TransactionsTable,
  TrxnKeyNumbers,
} from 'features/transactions';
import { filterTransactions } from 'features/transactions/redux/actions';
import {
  terminalIdSelector,
  trxnItemsSelector,
} from 'features/transactions/redux/selectors';
import { merchantCollectionSelector } from 'features/user/redux/selectors';
import { sortBy } from 'lodash';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
/**
 *Styling
 */
const StyledSelect = styled(Select)`
  && {
    min-width: 180px;
    .ant-select-selector .ant-select-selection-search-input {
      height: 42px;
    }
    .ant-select-arrow svg {
      fill: ${({ theme }) => theme.colors.Text.UI};
    }
  }
`;
const StyledOption = styled(Select.Option)``;

/**
 * Types
 */
export type TerminalFilterOpt = { value: string; text: string };
export interface TransactionsPageProps {}

/**
 * TransactionsPage component
 */
const TransactionsPage: React.FC<TransactionsPageProps> = (_props) => {
  const { t } = useTranslation();
  const transactionsTableRef = useRef<HTMLDivElement | null>(null);
  const trxnItems = useSelector(trxnItemsSelector);
  const terminalId = useSelector(terminalIdSelector);
  const merchantCollection = useSelector(merchantCollectionSelector);
  const dispatch = useDispatch();
  const [terminalFilterOpt, setTerminalFilterOpt] = React.useState<
    TerminalFilterOpt[]
  >([]);

  /**
   * _buildTerminalFilter
   */
  const _buildTerminalFilter = () => {
    const terminals = merchantCollection?.terminalCollection.map((terminal) => {
      return {
        value: terminal.terminalId,
        text: terminal.name,
      };
    });

    const orderedTerminals = sortBy(terminals, 'text', 'desc');
    setTerminalFilterOpt(orderedTerminals);
  };

  const _setTerminal = (terminalId: SelectValue) => {
    dispatch(filterTransactions({ terminalId: terminalId as string }));
  };

  React.useEffect(() => {
    _buildTerminalFilter();
  }, [trxnItems]);

  return (
    <BaseLayout
      options={[
        <DatePicker key="trxn-date-picker" />,
        <StyledSelect
          key="terminal-id"
          placeholder={t('Transactions.filter_opt.select_terminal')}
          value={terminalId ? terminalId : undefined}
          onChange={_setTerminal}
        >
          <StyledOption value={null}>
            {t('Transactions.filter_opt.all_terminals')}
          </StyledOption>
          {Object.values(terminalFilterOpt).map((c) => {
            return (
              <StyledOption key={c.value} value={c.value}>
                {c.text}
              </StyledOption>
            );
          })}
        </StyledSelect>,
        <TransactionsExportSelect
          key="trsc-export-select"
          printElementRef={transactionsTableRef}
        />,
        <FixedDatePicker key="trsc-date-picker" clearable type="transaction" />,
      ]}
    >
      <TrxnKeyNumbers />

      <TransactionsTable ref={transactionsTableRef} />
    </BaseLayout>
  );
};

/**
 * Exports
 */
export { TransactionsPage };
