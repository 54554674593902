import React from 'react';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';

import { Text } from 'components';
import { firebaseUserSelector } from 'features/user/redux/selectors';

import * as Styles from '../Sidebar.styles';

export type SidebarAccountDetailsProps = {
  collapsed?: boolean;
  onOpenAccount: () => void;
};

const SidebarAccountDetails: React.FC<SidebarAccountDetailsProps> = ({
  collapsed,
  onOpenAccount,
}) => {
  const user = useSelector(firebaseUserSelector);

  const userName = user.displayName || user.email;

  return (
    <Styles.AccountDetailsContainer>
      <Styles.ProfilePicture
        src={user.photoURL}
        isCollapsed={Boolean(collapsed)}
      >
        {userName}
      </Styles.ProfilePicture>

      {!collapsed && (
        <Styles.AccountDetailsTextWrapper onClick={onOpenAccount}>
          <Styles.AccountNameText size="ui3">{userName}</Styles.AccountNameText>
          <Text size="ui3">
            <Trans i18nKey="Sidebar.links.view_settings" />
          </Text>
        </Styles.AccountDetailsTextWrapper>
      )}
    </Styles.AccountDetailsContainer>
  );
};

export { SidebarAccountDetails };
