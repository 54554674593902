/**
 * Imports
 */
import { getTransactionsApi } from 'apis';
import { getTransactions } from 'features/overview/redux/actions';
import { meanBy, round, sumBy } from 'lodash';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { VibrantAPI } from 'types/vibrant';
import {
  endDateSelector,
  prevEndDateSelector,
  prevStartDateSelector,
  startDateSelector,
} from '../redux/selectors';
/**
 * types
 */
export type KeyNumbersHandlers = {
  onGetTransactions: (period: string) => Promise<void>;
};
export type KeyNumbersAPIType = {
  handlers: KeyNumbersHandlers;
  isFecthing: boolean;
};

const _getTransactions = () => {};

/**
 * useKeyNumbers API
 */
const useKeyNumbers = (): KeyNumbersAPIType => {
  const [isFecthing, setIsFetching] = React.useState(false);
  const dispatch = useDispatch();
  const startDate = useSelector(startDateSelector);
  const endDate = useSelector(endDateSelector);
  const prevStartDate = useSelector(prevStartDateSelector);
  const prevEndDate = useSelector(prevEndDateSelector);

  const fecthTrxnItems = React.useCallback(
    async (currentPage, pageSize) => {
      setIsFetching(true);
      try {
        const resp = await Promise.all([
          (await getTransactionsApi(
            startDate,
            endDate,
            currentPage,
            pageSize,
          )) as VibrantAPI.GetTrxnsResult,
          (await getTransactionsApi(
            prevStartDate,
            prevEndDate,
            currentPage,
            pageSize,
          )) as VibrantAPI.GetTrxnsResult,
        ]);

        const newTrxns = resp[0].trxns.filter((t) => t.status === 'approved');
        const prevTrxns = resp[1].trxns.filter((t) => t.status === 'approved');
        const newTrxnsCount = newTrxns.length;
        const prevTrxnsCount = prevTrxns.length;
        // New
        const sumRaw = sumBy(newTrxns, (p) => p.amount);
        const trxnSum = round(sumRaw, 2);
        const averageRaw = meanBy(newTrxns, (p) => p.amount) || 0;
        const trxnAverage = Math.round(averageRaw * 100) / 100;
        // Prev
        const prevSumRaw = sumBy(prevTrxns, (p) => p.amount);
        const prevTrxnSum = round(prevSumRaw, 2);
        const prevAverageRaw = meanBy(prevTrxns, (p) => p.amount) || 0;
        const prevTrxnAverage = Math.round(prevAverageRaw * 100) / 100;
        // Growth
        const sumGrowth =
          parseInt(((trxnSum / prevTrxnSum - 1) * 100).toFixed(2)) || 0;
        const countGrowth =
          parseInt(((newTrxnsCount / prevTrxnsCount - 1) * 100).toFixed(2)) ||
          0;
        const averageGrowth =
          parseInt(((trxnAverage / prevTrxnAverage - 1) * 100).toFixed(2)) || 0;

        dispatch(
          getTransactions({
            trxnCount: newTrxnsCount,
            trxnAverage,
            trxnSum,
            prevTrxnCount: prevTrxnsCount,
            prevTrxnAverage,
            prevTrxnSum,
            averageGrowth,
            countGrowth,
            sumGrowth,
          }),
        );
        setIsFetching(false);
      } catch (exception) {
        console.log('exception', exception);
        setIsFetching(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [endDate, startDate],
  );

  React.useEffect(() => {
    fecthTrxnItems(1, 10000000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate, startDate]);

  const handlers: KeyNumbersHandlers = {
    /**
     * onGetTransactions
     */
    onGetTransactions: async (period: string) => {
      console.log('onGetTransactions', period);
    },
  };

  return { handlers, isFecthing };
};

/**
 * Exports
 */

export { useKeyNumbers };
