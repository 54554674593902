/**
 * Imports
 */
import { Dropdown as AntdDropdown } from 'antd';
import styled from 'styled-components';

/**
 * Dropdown override style
 */
const Dropdown = styled(AntdDropdown)`
  && {
    cursor: pointer;
    .ant-dropdown-menu {
      padding: 8px;
    }
  }
`;

/**
 * Exports
 */
export { Dropdown };
