/**
 * Imports
 */
import { Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import { Space, Table, Text, VibrantIcon } from 'components';
import { useSettlementsApi } from 'features/settlements';
import { settlementsSelector } from 'features/settlements/redux/selectors';
import moment from 'moment';
import React, { forwardRef } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'router/RootRouter';
import styled from 'styled-components';
import { SoftPos } from 'types/softpos';
import { formatCurrency } from 'utils';
import * as Styles from './SettlementsTable.styles';

/**
 * Styling
 */
const StyledTooltip = styled(Tooltip)`
  margin-top: 4px;
  margin-left: 8px;
`;
/**
 * Types
 */
export interface SettlementsTableProps {}

/**
 * Table columns
 */
const columns: ColumnsType<SoftPos.Settlement> = [
  {
    title: () => <Trans i18nKey="SettlementDetails.table_header.period" />,
    dataIndex: 'startedAt',
    key: 'startedAt',
    render: (startedAt: string, record: SoftPos.Settlement) => {
      return (
        <>
          {moment(startedAt).format('DD.MM')}-
          {moment(record.endedAt).format('DD.MM')}
        </>
      );
    },
  },
  {
    title: () => (
      <Tooltip
        title={<Trans i18nKey="Settlements.table_header.payout_date_tooltip" />}
      >
        <div>
          <Trans i18nKey="Settlements.table_header.payout_date" />*
        </div>
      </Tooltip>
    ),
    dataIndex: 'payoutDate',
    key: 'payoutDate',
    render: (payoutDate: number) => {
      return payoutDate && moment(payoutDate).format('DD.MM.YYYY');
    },
  },
  {
    title: () => (
      <Trans i18nKey="Settlements.table_header.transaction_amount" />
    ),
    dataIndex: 'grossAmount',
    key: 'grossAmount',
    render: (grossAmount: number, record: SoftPos.Settlement) => {
      return (
        <Text size="ui2">
          {formatCurrency(grossAmount / 100, record.settlementCurrencyCode)}
        </Text>
      );
    },
  },
  {
    title: () => <Trans i18nKey="Settlements.table_header.fee_amount" />,
    dataIndex: 'settlementFeeAmount',
    key: 'settlementFeeAmount',
    render: (settlementFeeAmount: number, record: SoftPos.Settlement) => {
      return (
        <Text size="ui2">
          {formatCurrency(
            settlementFeeAmount / 100,
            record.settlementCurrencyCode,
          )}
        </Text>
      );
    },
  },
  {
    title: () => <Trans i18nKey="Settlements.table_header.transfer_amount" />,
    dataIndex: 'settlementAmount',
    key: 'settlementAmount',
    render: (settlementAmount: number, record: SoftPos.Settlement) => {
      return (
        <Text size="ui2">
          {formatCurrency(
            settlementAmount / 100,
            record.settlementCurrencyCode,
          )}
        </Text>
      );
    },
  },
];

const itemRender = (_current: any, type: any, originalElement: any) => {
  if (type === 'prev') {
    return <VibrantIcon icon="arrow-long-left" />;
  }
  if (type === 'next') {
    return <VibrantIcon icon="arrow-long-right" />;
  }
  return originalElement;
};

/**
 * SettlementsTable component
 */
const SettlementsTable = forwardRef<HTMLDivElement, SettlementsTableProps>(
  (props, ref) => {
    const navigation = useHistory();
    const settlements = useSelector(settlementsSelector);
    const { isFecthing, tableChangeHandler, pagination } = useSettlementsApi();
    /**
     * OnRowClick
     */
    const _onRowClick = (settlementId: string) => {
      const path = `${ROUTES.settlementDetails.path}/${settlementId}`;
      navigation.push(path);
    };

    return (
      <Styles.Container ref={ref}>
        <Text size="ui2" bold>
          <Trans i18nKey="_Common.titles.overview" />
        </Text>
        <Space />
        <Table<SoftPos.Settlement>
          columns={columns}
          dataSource={settlements}
          loading={isFecthing}
          pagination={
            false
            //   {
            //   ...pagination,
            //   itemRender,
            //   showSizeChanger: false,
            //   defaultPageSize: 50,
            //   showTotal: (total, range) => (
            //     <Trans
            //       i18nKey="_Common.pagination.total"
            //       values={{ rangeStart: range[0], rangeEnd: range[1], total }}
            //     />
            //   ),
            // }
          }
          rowKey={(record) => record.transferId}
          onChange={tableChangeHandler}
          // TODO: Implement when postings feature are made
          onRow={(record) => {
            const id = record.settlementId;
            return {
              onClick: () => _onRowClick(id),
            };
          }}
        />
      </Styles.Container>
    );
  },
);

/**
 * Exports
 */
export { SettlementsTable };
