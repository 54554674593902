import * as React from 'react';
import styled from 'styled-components';
import { ColorShadesType, VibrantColors } from 'theme/colors';
// Custom icons
import { ReactComponent as AndroidSvg } from '../../assets/icons/icon-android.svg';
import { ReactComponent as AppleSvg } from '../../assets/icons/icon-apple.svg';
import { ReactComponent as ArrowDownwardSvg } from '../../assets/icons/icon-arrow-downward.svg';
import { ReactComponent as ArrowLongLeftSvg } from '../../assets/icons/icon-arrow-left.svg';
import { ReactComponent as ArrowPointerSvg } from '../../assets/icons/icon-arrow-pointer.svg';
import { ReactComponent as ArrowLongRightSvg } from '../../assets/icons/icon-arrow-right.svg';
import { ReactComponent as ArrowUpwardSvg } from '../../assets/icons/icon-arrow-upward.svg';
import { ReactComponent as CardSvg } from '../../assets/icons/icon-card.svg';
import { ReactComponent as CheckmarkCheckedSvg } from '../../assets/icons/icon-checkmark-checked.svg';
import { ReactComponent as ChevronDownSvg } from '../../assets/icons/icon-chevron-down.svg';
import { ReactComponent as ChevronUpSvg } from '../../assets/icons/icon-chevron-up.svg';
import { ReactComponent as CloseSvg } from '../../assets/icons/icon-close.svg';
import { ReactComponent as GoogleSvg } from '../../assets/icons/icon-google.svg';
import { ReactComponent as HomeSvg } from '../../assets/icons/icon-home.svg';
import { ReactComponent as MailSvg } from '../../assets/icons/icon-mail.svg';
import { ReactComponent as MasterCardSvg } from '../../assets/icons/icon-mastercard.svg';
import { ReactComponent as MasterCardSingleSvg } from '../../assets/icons/icon-mastercard-single.svg';
import { ReactComponent as MenuSvg } from '../../assets/icons/icon-menu.svg';
import { ReactComponent as NfcSvg } from '../../assets/icons/icon-nfc.svg';
import { ReactComponent as OverviewSvg } from '../../assets/icons/icon-overview.svg';
import { ReactComponent as PenSvg } from '../../assets/icons/icon-pen.svg';
import { ReactComponent as PinSvg } from '../../assets/icons/icon-pin.svg';
import { ReactComponent as PrintSvg } from '../../assets/icons/icon-print.svg';
import { ReactComponent as RadioCheckedSvg } from '../../assets/icons/icon-radio-checked.svg';
import { ReactComponent as RadioUncheckedSvg } from '../../assets/icons/icon-radio-unchecked.svg';
import { ReactComponent as RefundSvg } from '../../assets/icons/icon-refund.svg';
import { ReactComponent as SunSvg } from '../../assets/icons/icon-sun.svg';
import { ReactComponent as TransactionSvg } from '../../assets/icons/icon-transaction.svg';
import { ReactComponent as VisaCardSvg } from '../../assets/icons/icon-visa.svg';
import { ReactComponent as VisaCardSingleSvg } from '../../assets/icons/icon-visa-single.svg';
import { ReactComponent as WatchSvg } from '../../assets/icons/icon-watch.svg';
import { ReactComponent as PlusSvg } from '../../assets/icons/icon-plus.svg';
import { ReactComponent as ProductSvg } from '../../assets/icons/icon-product.svg';
import { ReactComponent as GuideSvg } from '../../assets/icons/icon-guide.svg';
import { ReactComponent as LockSvg } from '../../assets/icons/icon-lock.svg';
import { ReactComponent as UnLockSvg } from '../../assets/icons/icon-unlock.svg';
import { ReactComponent as QRSvg } from '../../assets/icons/icon-qr.svg';

/**
 * Types
 */
export type VibrantIconTypes =
  | 'android'
  | 'apple'
  | 'arrow-downward'
  | 'arrow-long-left'
  | 'arrow-long-right'
  | 'arrow-upward'
  | 'arrow-pointer'
  | 'card'
  | 'checkmark-checked'
  | 'chevron-down'
  | 'chevron-up'
  | 'close'
  | 'google'
  | 'guides'
  | 'home'
  | 'lock'
  | 'mail'
  | 'master-card-single'
  | 'master-card'
  | 'menu'
  | 'nfc'
  | 'overview'
  | 'pen'
  | 'pin'
  | 'plus'
  | 'print'
  | 'product'
  | 'qr'
  | 'radio-checked'
  | 'radio-unchecked'
  | 'refund'
  | 'sun'
  | 'transaction'
  | 'unlock'
  | 'visa-single'
  | 'visa'
  | 'watch';

export type CustomIconType = React.FunctionComponent<
  React.SVGProps<SVGSVGElement>
>;
export type VibrantIconProps = {
  color?: ColorShadesType;
  icon: VibrantIconTypes;
  size?: number;
};

/**
 * Styling
 */
const StyledIcon = styled.div`
  display: flex;
  align-items: center;

  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
`;
/**
 * CONST
 */
const VibrantIconTypes: { [key in VibrantIconTypes]: any } = {
  'arrow-downward': <ArrowDownwardSvg />,
  'arrow-long-left': <ArrowLongLeftSvg />,
  'arrow-long-right': <ArrowLongRightSvg />,
  'arrow-pointer': <ArrowPointerSvg />,
  'arrow-upward': <ArrowUpwardSvg />,
  'checkmark-checked': <CheckmarkCheckedSvg />,
  'chevron-down': <ChevronDownSvg />,
  'chevron-up': <ChevronUpSvg />,
  'master-card-single': <MasterCardSingleSvg />,
  'master-card': <MasterCardSvg />,
  'radio-checked': <RadioCheckedSvg />,
  'radio-unchecked': <RadioUncheckedSvg />,
  'visa-single': <VisaCardSingleSvg />,
  android: <AndroidSvg />,
  apple: <AppleSvg />,
  card: <CardSvg />,
  close: <CloseSvg />,
  google: <GoogleSvg />,
  guides: <GuideSvg />,
  home: <HomeSvg />,
  lock: <LockSvg />,
  mail: <MailSvg />,
  menu: <MenuSvg />,
  nfc: <NfcSvg />,
  overview: <OverviewSvg />,
  pen: <PenSvg />,
  pin: <PinSvg />,
  plus: <PlusSvg />,
  print: <PrintSvg />,
  product: <ProductSvg />,
  refund: <RefundSvg />,
  sun: <SunSvg />,
  transaction: <TransactionSvg />,
  qr: <QRSvg />,
  unlock: <UnLockSvg />,
  visa: <VisaCardSvg />,
  watch: <WatchSvg />,
};

const VibrantIcon: React.FC<VibrantIconProps> = ({ icon, color, size }) => (
  <StyledIcon
    className="vibranticon"
    style={{
      color: color ? VibrantColors.Shades[color] : VibrantColors.Shades.Grey900,
      height: size ? `${size}px` : '16px',
      width: size ? `${size}px` : '16px',
    }}
  >
    {VibrantIconTypes[icon]}
  </StyledIcon>
);

export { VibrantIcon };
