/**
 * Imports
 */
import { Anchor, CreditCard, Text } from 'components';
import * as React from 'react';
import styled from 'styled-components';

/**
 * Styling
 */
const Root = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.Border.BorderPrimary};
  padding: 24px 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Left = styled.div`
  display: flex;
  align-items: center;
`;
const Right = styled.div`
  display: flex;
  align-items: center;
`;
const StyledAnchor = styled(Anchor)`
  margin-right: 48px;
`;
const PartnerText = styled(Text)`
  margin-left: 12px;
`;

/**
 * Types
 */
export interface FooterLink4PayProps {}

/**
 * FooterLink4Pay component
 */
const FooterLink4Pay: React.FC<FooterLink4PayProps> = (_props) => {
  return (
    <Root>
      <Left>
        <StyledAnchor size="ui3" href="https://link4pay.com/" target="_blank">
          © Link4Pay
        </StyledAnchor>
        <CreditCard card="VISA" size="sm" />
        <PartnerText size="ui3">Official partner of Visa</PartnerText>
      </Left>
      <Right>
        <StyledAnchor
          size="ui3"
          href="https://link4pay.com/privacy-policy/"
          target="_blank"
        >
          Privacy policy
        </StyledAnchor>
        <StyledAnchor
          size="ui3"
          href="https://link4pay.com/cookie-policy/"
          target="_blank"
        >
          Cookie policy
        </StyledAnchor>
        {/* TODO: Add package version */}
        <Text size="ui3">v. 1.23</Text>
      </Right>
    </Root>
  );
};

/**
 * Exports
 */
export { FooterLink4Pay };
