/**
 * Imports
 */

import {
  FirebaseCollectionsType,
  getFirebaseInstance,
} from '../../../../firebaseAPI';
import { Firebase } from '../../../../types/firebase';

const updateEconomicsAccounts = async (
  merchantId: string,
  economicsData: Firebase.EconomicsData,
): Promise<void> => {
  const firestore = getFirebaseInstance().firestore();

  await firestore
    .collection(FirebaseCollectionsType.MERCHANT_INTEGRATIONS)
    .doc(merchantId)
    .update({
      'economic.settings': economicsData,
    });
};
export const getEconomicsAccount = async (merchantId: string): Promise<any> => {
  const firestore = getFirebaseInstance().firestore();
  const doc = await firestore
    .collection(FirebaseCollectionsType.MERCHANT_INTEGRATIONS)
    .doc(merchantId)
    .get();
  if (doc.exists) {
    const data = doc.data();
    if (data && data.economic) {
      return data.economic;
    }
  }
  return null;
};
const updateEconomicsEnabled = async (
  merchantId: string,
  enabled: boolean,
): Promise<void> => {
  const firestore = getFirebaseInstance().firestore();

  await firestore
    .collection(FirebaseCollectionsType.MERCHANT_INTEGRATIONS)
    .doc(merchantId)
    .update({
      'economic.enabled': enabled,
    });
};
export { updateEconomicsAccounts, updateEconomicsEnabled };
