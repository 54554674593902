/**
 * Imports
 */
import { Spin, Tooltip } from 'antd';
import { Anchor, RoundedCard, Space, Switch, Text } from 'components';
import {
  EconomicIntegrationSettings,
  IntegrationFeature,
  IntegrationSettings,
  INTEGRATION_OPTIONS,
  useIntegrationApi,
} from 'features/integrations';
import { integrationAddedLoadingSelector } from 'features/integrations/redux/selectors';
import { SplashScreen } from 'features/navigation';
import {
  integrationCollectionSelector,
  merchantCollectionSelector,
  isFeatureTesterSelector,
} from 'features/user/redux/selectors';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import OauthPopup from 'react-oauth-popup';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Firebase } from 'types/firebase';
import { useLocation, useHistory } from 'react-router-dom';

const IntegrationLogo = styled.img`
  height: 20px;
  margin-top: 16px;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
`;
const Content = styled.div``;
const Overlay = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
`;
const OverlayContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 8px;
  right: 0;
  bottom: 0;
  left: calc(50% - 20px);
  width: 100%;
`;
const Footer = styled.div<{ loading?: boolean }>`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: ${({ loading }) => (loading ? '0.25' : '1')};
`;
const Tag = styled.div`
  background-color: rgba(20, 20, 20, 0.2);
  border-radius: 12px;
  padding: 4px 8px;

  display: flex;
  align-items: center;
`;

/**
 * Types
 */
export interface IntegrationCardProps {
  integration: IntegrationFeature.OptionType;
  integrationId: IntegrationFeature.Options;
  hasIntegration: boolean;
}
export interface EnableIntegrationProps {
  merchantLocationId: string;
  merchantId: string;
  integrationId: IntegrationFeature.Options;
  hasTerminal: boolean;
  hasIntegration: false | Firebase.MerchantIntegration;
  isEnabled: boolean;
}
export interface DisableIntegrationProps {
  integrationId: IntegrationFeature.Options;
  integrationName: string;
}
/**
 * Enable Integration
 */
const EnableIntegration: React.FC<EnableIntegrationProps> = (props) => {
  const { t } = useTranslation();
  const integrationAddedLoading = useSelector(integrationAddedLoadingSelector);
  const integrationName =
    INTEGRATION_OPTIONS[props.integrationId].integrationId;

  const history = useHistory();
  const createUrl = () => {
    const redirect = encodeURIComponent(
      'https://me.vibrant.io/integration_callback/' + integrationName,
    );
    if (integrationName === 'dinero') {
      const state = btoa(
        JSON.stringify({
          merchantLocationId: props.merchantLocationId,
          merchantId: props.merchantId,
        }),
      );
      const scope = encodeURIComponent(
        'dineropublicapi:read dineropublicapi:write offline_access',
      );
      return `https://connect.visma.com/connect/authorize?client_id=isv_vibrant_io&scope=${scope}&redirect_uri=${redirect}&response_type=code&response_mode=query&state=${state}`;
    }
    if (integrationName === 'economic') {
      return 'https://secure.e-conomic.com/secure/api1/requestaccess.aspx?appPublicToken=qXI2D3sXyhToKhqNKYQVBitSO3WF4RtBJOO7gFsESVc1&redirectUrl=https://me.vibrant.io/integration_callback/economic';
    }
    return '';
  };

  const url = createUrl();

  const _onClose = () => console.log('onClose!');
  const _onCode = () => {
    const { search } = useLocation(); // weird code that makes it work
    console.log(search); // weird code that makes it work
  };

  const _enableIntegration = () => {
    if (integrationName === 'economic' && props.hasIntegration) {
      history.replace('/integrations?open_modal=economic');
    }
  };

  // lets not use oauth for economic
  React.useEffect(() => {
    if (integrationName === 'economic' && props.hasIntegration) {
      //location.href = url;
      console.log('henning', props);
    }
  }, []);

  const economicEnable = (
    <div onClick={() => (location.href = url)}>
      <Overlay>
        <OverlayContent>{integrationAddedLoading && <Spin />}</OverlayContent>
      </Overlay>
      <Footer loading={integrationAddedLoading}>
        <Text size="ui2" color="white" bold>
          {t('Integrations.actions.add_integration')}
        </Text>
        <Switch checked={false} />
      </Footer>
    </div>
  );

  // No terminals -> lets show the KYC.
  if (!props.hasTerminal) {
    return (
      <Tooltip title={t('Integrations.tooltips.kyc')} placement="topRight">
        <Footer onClick={_enableIntegration}>
          <Text size="ui2" color="white" bold>
            {t('Integrations.actions.add_integration')}
          </Text>
          <Switch checked={false} />
        </Footer>
      </Tooltip>
    );
  }

  const dineroEnable = (
    <OauthPopup
      title={integrationName}
      width={500}
      height={800}
      url={url}
      onCode={_onCode}
      onClose={_onClose}
    >
      <Overlay>
        <OverlayContent>{integrationAddedLoading && <Spin />}</OverlayContent>
      </Overlay>
      <Footer loading={integrationAddedLoading}>
        <Text size="ui2" color="white" bold>
          {t('Integrations.actions.add_integration')}
        </Text>
        <Switch checked={false} />
      </Footer>
    </OauthPopup>
  );

  const show =
    !props.hasIntegration || (props.hasIntegration && !props.isEnabled);

  if (props.integrationId === 'dinero' && show) {
    return dineroEnable;
  }

  if (props.integrationId === 'economic' && show) {
    return economicEnable;
  }

  return <></>;
};
/**
 * Disable Integration
 */

const DisableIntegration: React.FC<DisableIntegrationProps> = (props) => {
  const { t } = useTranslation();
  const {
    handlers: { onDisableIntegration },
  } = useIntegrationApi();
  const _disableIntegration = async () => {
    if (
      window.confirm(
        t('Integrations.actions.disable_integration_confirm', {
          integration: props.integrationName,
        }),
      )
    ) {
      await onDisableIntegration(props.integrationId);
    }
  };

  return (
    <Tooltip
      title={t('Integrations.actions.disable_integration_btn')}
      placement="topRight"
    >
      <Footer onClick={_disableIntegration}>
        <Text size="ui2" color="white" bold>
          {t('Integrations.actions.disable_integration_text')}
        </Text>
        <Switch checked={true} />
      </Footer>
    </Tooltip>
  );
};

/**
 * IntegrationCard component
 */
const IntegrationCard: React.FC<IntegrationCardProps> = (props) => {
  const { t } = useTranslation();
  const merchant = useSelector(merchantCollectionSelector);
  const integrationCollection = useSelector(integrationCollectionSelector);
  const isfeatureTester = useSelector(isFeatureTesterSelector);

  const isEnabled =
    props.hasIntegration && integrationCollection[props.integrationId]?.enabled;
  const hasIntegration =
    props.hasIntegration && integrationCollection[props.integrationId];

  console.log('hasIntegration', hasIntegration);

  if (!merchant) {
    return <SplashScreen />;
  }

  if (!isfeatureTester && props.integrationId === 'economic') {
    return null;
  }
  return (
    <RoundedCard
      top={
        <>
          <IntegrationLogo
            src={INTEGRATION_OPTIONS[props.integrationId].logo}
            alt={INTEGRATION_OPTIONS[props.integrationId].name}
          />
          {hasIntegration && props.integrationId === 'dinero' && (
            <Tooltip title={t('Integrations.tooltips.settings')}>
              <div>
                <IntegrationSettings integrationId={props.integrationId} />
              </div>
            </Tooltip>
          )}
          {hasIntegration && props.integrationId === 'economic' && (
            <Tooltip title={t('Integrations.tooltips.settings')}>
              <EconomicIntegrationSettings merchantId={merchant.merchantId} />
            </Tooltip>
          )}
        </>
      }
      guideLinkHref={t([
        `Integrations.${props.integrationId}.card_guide_link_href`,
      ])}
      guideLinkText={t([
        `Integrations.${props.integrationId}.card_guide_link_text`,
      ])}
      body={
        <Content>
          <Row>
            <Tag>
              <Text size="ui3" color="white">
                {t([`Integrations.${props.integrationId}.card_label`])}
              </Text>
            </Tag>
            <Space size="xs" vertical />
            <Tag>
              <Anchor
                size="ui3"
                href={t([
                  `Integrations.${props.integrationId}.card_integration_link_href`,
                ])}
                target="_blank"
                color="white"
              >
                {t([
                  `Integrations.${props.integrationId}.card_integration_link_text`,
                ])}
              </Anchor>
            </Tag>
          </Row>
        </Content>
      }
      heading={t([`Integrations.${props.integrationId}.card_heading`])}
      subtext={t([`Integrations.${props.integrationId}.card_subtext`])}
      footer={
        isEnabled ? (
          <DisableIntegration
            integrationId={props.integrationId}
            integrationName={INTEGRATION_OPTIONS[props.integrationId].name}
          />
        ) : (
          <EnableIntegration
            integrationId={props.integrationId}
            merchantLocationId={merchant.merchantId}
            merchantId={merchant.clearhausMerchantId}
            hasTerminal={merchant.terminalCollection.length > 0}
            hasIntegration={hasIntegration}
            isEnabled={isEnabled}
          />
        )
      }
    />
  );
};

/**
 * Exports
 */
export { IntegrationCard };
