import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { maybeUserSelector } from 'features/user/redux/selectors';

export type PublicRouteProps = RouteProps & {
  dontCheckAuth?: boolean;
};

const PublicRoute: React.FC<PublicRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const user = useSelector(maybeUserSelector);

  return (
    <Route
      {...rest}
      render={(props) =>
        (user == null || rest.dontCheckAuth) && Component ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
            }}
          />
        )
      }
    />
  );
};

export { PublicRoute };
