/**
 * Imports
 */
import { GlobalOutlined } from '@ant-design/icons';
import { Icon } from 'components';
import * as React from 'react';

import styled from 'styled-components';
import { Text } from 'components';
import { Trans } from 'react-i18next';
import { LanguageSelector } from '../LanguageSelector';

/**
 * Styling
 */
const Root = styled.div``;
const Wrapper = styled.div`
  margin-top: 24px;
`;
const Row = styled.div`
  .anticon {
    margin-right: 12px;
  }

  display: flex;
  align-items: center;
`;

/**
 * Types
 */
export interface ChangeLanguageFormProps {}

/**
 * ChangeLanguageForm component
 */
const ChangeLanguageForm: React.FC<ChangeLanguageFormProps> = (_props) => {
  return <Root />;
};

/**
 * Exports
 */
export { ChangeLanguageForm };
