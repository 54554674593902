/**
 * Imports
 */
import { Icon as CustomIcon, Text, VibrantIcon } from 'components';
import Icon from '@ant-design/icons';
import * as React from 'react';
import styled from 'styled-components';
import { AnyCnameRecord } from 'dns';
import { CustomIconType } from 'components/VibrantIcon/VibrantIcon';

/**
 * Styling
 */
const IntroStepBox = styled.div<{
  noBorder?: boolean;
}>`
  border-right: ${(props) =>
    !props.noBorder && '1px solid rgba(73, 33, 11, 0.1)'};
  padding: 32px 48px;
  min-width: 348px;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  flex: 1;

  & .anticon {
    height: 48px;
  }

  @media (max-width: 1365px) {
    padding: 16px 24px;
    min-width: 280px;
    & svg {
      font-size: 20px;
    }
  }

  @media (max-width: 1024px) {
    min-width: auto;
    max-width: 68px;
  }
`;
const CloseButton = styled.div`
  cursor: pointer;
  margin-bottom: auto;
  margin-right: auto;
  height: 20px;
  width: 20px;
  & svg {
    transition: ${({ theme }) => theme.transitions.HoverTextColor};
  }
  &:hover svg {
    color: ${({ theme }) => theme.colors.Shades.Orange500};
  }
`;
const IntroTextWrapper = styled.div`
  margin-top: 100px;
  @media (max-width: 1365px) {
    margin-top: 48px;
  }
  @media (max-width: 1024px) {
    display: none;
  }
`;
const Subheading = styled(Text)`
  font-family: 'BasierRegular';
`;
// Pattern
const ImageWrapper = styled.div<{ patternLeft?: boolean }>`
  position: absolute;
  top: 0;
  right: ${(props) => (props.patternLeft ? 'auto' : '0')};
  left: ${(props) => (props.patternLeft ? '48px' : 'auto')};

  & .anticon svg {
    fill: none;
    height: 48px;
    width: 160px;
  }

  @media (max-width: 1024px) {
    & .anticon svg {
      display: none;
    }
  }
`;
/**
 * Types
 */
export type IntroType = {
  noBorder?: boolean;
  heading: React.ReactNode;
  subheading?: React.ReactNode;
  pattern: CustomIconType;
  patternLeft?: boolean;
  onClose?: () => void;
};

/**
 * Intro Step
 */
const IntroStep: React.FC<IntroType> = ({
  heading,
  noBorder,
  pattern,
  patternLeft,
  subheading,
  onClose,
}) => {
  return (
    <IntroStepBox noBorder={noBorder}>
      <ImageWrapper patternLeft={patternLeft}>
        <CustomIcon icon={<Icon component={pattern} />} color="Orange100" />
      </ImageWrapper>
      {onClose && (
        <CloseButton onClick={onClose}>
          <VibrantIcon icon="close" />
        </CloseButton>
      )}
      <IntroTextWrapper>
        <Text size="h5">{heading}</Text>
        {subheading && <Subheading size="h5">{subheading}</Subheading>}
      </IntroTextWrapper>
    </IntroStepBox>
  );
};

export { IntroStep };
