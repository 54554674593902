import { PROJECT_CONFIG } from 'config';
import amplitude from 'amplitude-js';
import Amplitude from '@redux-beacon/amplitude';
import { createMiddleware } from 'redux-beacon';
import { EventsMapper } from 'redux-beacon';
import { userIdEvent, userLoggedInEvent } from './events';

// init
const amplitudeInstance = amplitude.getInstance();
amplitudeInstance.init(PROJECT_CONFIG.amplitudeApiKey);
const amplitudeTarget = Amplitude({ instance: amplitudeInstance });

const eventsMapper: EventsMapper = (action) => {
  switch (action.type) {
    case 'user/USER_UPDATED':
      return [userIdEvent, userLoggedInEvent];
    default:
      return [];
  }
};
const amplitudeMiddleware = createMiddleware(eventsMapper, amplitudeTarget);

export { amplitudeMiddleware };
