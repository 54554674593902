/**
 * Imports
 */
import * as React from 'react';
import { Skeleton } from 'antd';
import styled from 'styled-components';
import { Card, Space, Text } from 'components';
import { VibrantIcon } from 'components/VibrantIcon';
import { Currency } from 'features/shared';
import { formatCurrency } from 'utils';

/**
 * Styling
 */

const StyledCard = styled(Card)`
  max-height: 159px;
  min-height: 159px;
  @media (max-width: 600px) {
    max-height: auto;
    min-height: auto;
  }
`;
const Left = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
`;
/* Growth */
const GrowthRoot = styled.div`
  display: flex;
  align-self: flex-end;
`;
const ArrowUp = styled.div`
  margin-left: 2px;
`;
const ArrowDown = styled.div`
  margin-left: 2px;
`;
const Number = styled(Text)`
  line-height: 1;
`;
const GrowthNumber = styled(Text)`
  line-height: 1;
  margin-top: 6px;
`;

/**
 * Types
 */
export interface KeyNumberCardProps {
  title?: React.ReactNode;
  number?: number;
  currency?: boolean;
  loading?: boolean;
  growth?: number;
}
export type GrowthProps = {
  growth: number;
  negative?: boolean;
};

/**
 * Growth
 */
const Growth: React.FC<GrowthProps> = ({ growth }) => {
  const isNegative = growth < 0;
  return (
    <GrowthRoot>
      <GrowthNumber size="ui3" color={isNegative ? 'danger' : 'success'}>
        {growth}%
      </GrowthNumber>
      {isNegative ? (
        <ArrowDown>
          <VibrantIcon icon="arrow-downward" color="Red700" />
        </ArrowDown>
      ) : (
        <ArrowUp>
          <VibrantIcon icon="arrow-upward" color="Green700" />
        </ArrowUp>
      )}
    </GrowthRoot>
  );
};

/**
 * KeyNumberCard component
 */
const KeyNumberCard: React.FC<KeyNumberCardProps> = ({
  title,
  number,
  currency,
  growth,
  loading,
}) => {
  return (
    <StyledCard title={title}>
      <Skeleton loading={loading} active paragraph={{ rows: 1 }}>
        <Left>
          <Number size="h3" color="brand">
            {number && formatCurrency(number)}
          </Number>
          <Space vertical size="sm" />
          {currency && <Currency />}
        </Left>
      </Skeleton>
      {number !== 0 && growth && <Growth growth={growth} />}
    </StyledCard>
  );
};

/**
 * Exports
 */
export { KeyNumberCard };
