/**
 * Imports
 */
import { getPostingsApi } from 'apis';
import { SoftPos } from 'types/softpos';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPostings } from '../redux/actions';
import { userSelector } from 'features/user/redux/selectors';
import { orderBy } from 'lodash';

/**
 * Types
 */
type UsePostingsReturnType = {
  isFecthing: boolean;
};

/**
 * usePostingsApi component
 */
const usePostingsApi = (postingsId: string): UsePostingsReturnType => {
  const [isFecthing, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const user = useSelector(userSelector);

  const fecthPostings = React.useCallback(
    async () => {
      setLoading(true);
      try {
        const resp = (await getPostingsApi(
          user.meta.merchantId,
          postingsId,
        )) as SoftPos.Posting[];
        const postings = orderBy(resp, ['processedAt'], ['desc']);
        dispatch(
          getPostings({
            postings: postings,
          }),
        );
        setLoading(false);
      } catch (exception) {
        console.log('Fetching postings failed: ', exception);
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  React.useEffect(() => {
    fecthPostings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postingsId]);

  return { isFecthing };
};

/**
 * Exports
 */
export { usePostingsApi };
