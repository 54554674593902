/**
 * Imports
 */
import { merchantCollectionSelector } from 'features/user/redux/selectors';
import { FirebaseCollectionsType } from 'firebaseAPI';
import { useSelector } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';
import { SoftPos } from 'types/softpos';

/**
 * Types
 */
type RequestRefundType = {
  onHandleRequestRefund: (txn: SoftPos.Trxn) => Promise<void>;
  onRequestRefundStatus: (txn: SoftPos.Trxn) => Promise<boolean | undefined>;
};

/**
 * useRequestRefund component
 */
const useRequestRefund = (): RequestRefundType => {
  const merchant = useSelector(merchantCollectionSelector);
  const firestore = useFirestore();

  const onHandleRequestRefund = async (trxn: SoftPos.Trxn) => {
    await firestore
      .collection(FirebaseCollectionsType.REFUND_REQUEST)
      .doc(merchant?.accountId ?? merchant.merchantId)
      .collection(FirebaseCollectionsType.REFUND_REQUEST_SUBCOLLECTION)
      .doc(trxn.trxnId)
      .set({ ...trxn, refunded: false, requestedAt: Date.now() });
  };

  const onRequestRefundStatus = async (trxn: SoftPos.Trxn) => {
    const documentRef = firestore
      .collection(FirebaseCollectionsType.REFUND_REQUEST)
      .doc(merchant?.accountId ?? merchant.merchantId)
      .collection(FirebaseCollectionsType.REFUND_REQUEST_SUBCOLLECTION)
      .doc(trxn.trxnId);
    const data = (await documentRef.get()).data();

    return data?.refunded;
  };

  return { onHandleRequestRefund, onRequestRefundStatus };
};

/**
 * Exports
 */
export { useRequestRefund };
