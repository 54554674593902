/**
 * Imports
 */
import React, { useRef, useState } from 'react';

import { PostingsTable } from 'features/settlements';
import {
  PayoutNumbers,
  PostingsExportSelect,
} from 'features/settlements/components';
import { BaseLayout } from 'features/navigation';
import { Trans } from 'react-i18next';
import { Space, Switch } from 'antd';
import { DailySummationPostingTable } from 'features/settlements/components/DailySummationPostingTable';

/**
 * Types
 */
export interface SettlementDetailsPageProps {}

/**
 * SettlementDetailsPage component
 */
const SettlementDetailsPage: React.FC<SettlementDetailsPageProps> = (props) => {
  const postingsTableRef = useRef<HTMLDivElement | null>(null);
  const [dailySummation, setDailySummation] = useState<boolean>(false);

  return (
    <BaseLayout
      options={[
        <PostingsExportSelect
          key="pstgs-export-select"
          printElementRef={postingsTableRef}
        />,
      ]}
    >
      <PayoutNumbers />
      <Space align="center" style={{ marginBottom: 16 }}>
        <Trans i18nKey="SettlementDetails.daily_summation.switch_text" />
        <Switch checked={dailySummation} onChange={setDailySummation} />
      </Space>
      {dailySummation ? (
        <DailySummationPostingTable ref={postingsTableRef} />
      ) : (
        <PostingsTable ref={postingsTableRef} />
      )}
    </BaseLayout>
  );
};

/**
 * Exports
 */
export { SettlementDetailsPage };
