/**
 * Imports
 */
import * as React from 'react';
import styled from 'styled-components';
import {
  PaymentInformation,
  Receipt,
  TransactionOptionsMenu,
  useSingleTransactionApi,
} from 'features/transactions';
import { BaseLayout } from 'features/navigation';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  trxnItemSelector,
  trxnItemsSelector,
} from 'features/transactions/redux/selectors';
import { merchantCollectionSelector } from 'features/user/redux/selectors';

/**
 * Styling
 */
const Root = styled.div`
  margin: 48px 0;
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 2fr 1fr;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
`;
const TransactionDetailsWrapper = styled.div`
  @media print {
    padding: 64px;
  }
`;
const ReceiptWrapper = styled.div`
  @media print {
    padding: 64px;
  }
`;

/**
 * Types
 */
export type TransactionDetailsPageProps = object;

/**
 * TransactionDetailsPage component
 */
const TransactionDetailsPage: React.FC<any> = (props) => {
  const componentRef = React.useRef() as React.MutableRefObject<HTMLDivElement>;
  const params = useParams<{ id: string }>();
  const trxnItem = useSelector(trxnItemSelector);
  const trxnItems = useSelector(trxnItemsSelector);
  const router = useHistory();
  const merchantCollection = useSelector(merchantCollectionSelector);
  const { isFecthing } = useSingleTransactionApi(params.id);

  React.useEffect(() => {
    if (trxnItems.length < 1) {
      router.push('/transactions');
    }
  }, []);
  return (
    <BaseLayout>
      {trxnItem.length > 0 && (
        <Root>
          <TransactionDetailsWrapper>
            <ReceiptWrapper ref={componentRef}>
              <Receipt
                trxnItem={trxnItem[0]}
                isFecthing={isFecthing}
                vat={merchantCollection?.vat?.toString() || '25'}
              />
            </ReceiptWrapper>
            <PaymentInformation trxnItem={trxnItem[0]} />
          </TransactionDetailsWrapper>
          <TransactionOptionsMenu
            printContent={componentRef}
            trxnItem={trxnItem[0]}
          />
        </Root>
      )}
    </BaseLayout>
  );
};

/**
 * Exports
 */
export { TransactionDetailsPage };
