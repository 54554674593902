/**
 * Imports
 */
import { Button, Alert, notification } from 'antd';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import userFeature from 'features/user';

import { firebaseLogin } from 'firebaseAPI';
import { Input, Space, Text } from 'components';

import { GoogleLogin } from '..';

import * as Styles from './LoginForm.styles';

/**
 * LoginForm component
 */
const LoginForm: React.FC = () => {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const isAuthenticated = !!useSelector(
    userFeature.redux.selectors.maybeUserSelector,
  );

  React.useEffect(() => {
    if (isAuthenticated) {
      setIsLoading(false);
      history.replace({ pathname: '/' });
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  const _handleLogin = async () => {
    try {
      setIsLoading(true);
      const user = await firebaseLogin(email, password);
      dispatch(userFeature.redux.actions.userUpdated(user));
      history.replace({ pathname: '/' });
    } catch (error) {
      setIsLoading(false);
      notification.error({ message: error.message || error });
    }
  };

  const _handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      _handleLogin();
    }
  };

  return (
    <Styles.Root>
      <GoogleLogin />

      <Space size="lg" />

      <Styles.Divider>
        <Trans i18nKey="Auth.text_use_email" />
      </Styles.Divider>

      <Space size="lg" />

      <Input
        label={t('Auth.labels.email')}
        placeholder="hello@example.com"
        value={email}
        onChange={(event) => setEmail(event.target.value)}
        onKeyDown={_handleKeyDown}
      />

      <Space size="lg" />

      <Input
        label={t('Auth.labels.password')}
        placeholder={t('Auth.labels.password')}
        type="password"
        value={password}
        onChange={(event) => setPassword(event.target.value)}
        onKeyDown={_handleKeyDown}
      />

      <Space size="xl" />

      <Button type="primary" onClick={_handleLogin} loading={isLoading} block>
        <Text size="ui2" bold>
          <Trans i18nKey="Auth.btn_login_email" />
        </Text>
      </Button>

      <Styles.Divider>
        <Link to="/forgot-password">
          <Trans i18nKey="ForgotPassword.title" />
        </Link>
      </Styles.Divider>
    </Styles.Root>
  );
};

/**
 * Exports
 */
export { LoginForm };
