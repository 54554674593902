import { getFirebaseInstance } from 'firebaseAPI';
import { ProductData, ProductDataWithId } from '../types';

export const addProduct = async (
  { name, price, vat }: ProductData,
  merchantId: string,
): Promise<void> => {
  const db = getFirebaseInstance().firestore();
  const col = `products/${merchantId}/productList`;
  const doc = db.collection('cities').doc();
  const productId = doc.id;
  await db
    .collection(col)
    .doc(productId)
    .set({
      productId,
      name,
      price,
      priceInCents: Math.round(price * 100),
      vat,
    });
};

export const deleteProduct = async (
  productId: string,
  merchantId: string,
): Promise<void> => {
  const now = Date.now();
  const db = getFirebaseInstance().firestore();
  db.collection(`products/${merchantId}/productList`)
    .doc(productId)
    .update({ archivedAt: now });
};

export const updateProduct = async (
  { id, ...rest }: ProductDataWithId,
  merchantId: string,
): Promise<void> => {
  console.log('update product', id);
  const db = getFirebaseInstance().firestore();
  await db
    .collection(`products/${merchantId}/productList`)
    .doc(id)
    .update({ ...rest });
};
