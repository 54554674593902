import { SoftPos } from 'types/softpos';
import { settlementsSelector } from 'features/settlements/redux/selectors';
import { ExportSelect } from 'features/shared';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { formatCurrency } from 'utils';

export type SettlementsExportSelectProps = {
  printElementRef: React.MutableRefObject<HTMLDivElement | null>;
};

const SettlementsExportSelect: React.FC<SettlementsExportSelectProps> = (
  props,
) => {
  const settlementsItems = useSelector(settlementsSelector);
  const { t } = useTranslation();

  const csvData = useMemo(() => {
    const data = [];

    const headers: {
      label: string;
      key: keyof (SoftPos.Settlement & { period: string });
    }[] = [
      { label: t('SettlementDetails.table_header.period'), key: 'period' },
      {
        label: t('Settlements.table_header.payout_date'),
        key: 'payoutDate',
      },
      {
        label: t('Settlements.table_header.transaction_amount'),
        key: 'grossAmount',
      },
      {
        label: t('Settlements.table_header.fee_amount'),
        key: 'settlementFeeAmount',
      },
      {
        label: t('Settlements.table_header.transfer_amount'),
        key: 'settlementAmount',
      },
    ];

    for (const trxn of settlementsItems) {
      const temp: Record<string, string | number | null> = {};

      for (const header of headers) {
        if (header.key === 'period') {
          temp[header.key] =
            moment(trxn.startedAt).format('DD.MM') +
            '-' +
            moment(trxn.endedAt).format('DD.MM');
        } else if (header.key === 'payoutDate') {
          const payoutDate = trxn.payoutDate;
          temp[header.key] = payoutDate
            ? moment(payoutDate).format('DD.MM.YYYY')
            : 'NA';
        } else if (header.key === 'grossAmount') {
          const gross = formatCurrency(trxn.grossAmount / 100);
          temp[header.key] = gross ? gross : '';
        } else if (header.key === 'settlementAmount') {
          const sa = formatCurrency(trxn.settlementAmount / 100);
          temp[header.key] = sa ? sa : '';
        } else if (header.key === 'settlementFeeAmount') {
          const sfa = formatCurrency(trxn.settlementFeeAmount / 100);
          temp[header.key] = sfa ? sfa : '';
        } else {
          temp[header.key] = trxn[header.key];
        }
      }

      data.push(temp);
    }

    return { headers, data };
  }, [settlementsItems]);

  return (
    <ExportSelect
      filename="payouts"
      headers={csvData.headers}
      data={csvData.data}
      printElementRef={props.printElementRef}
    />
  );
};

export { SettlementsExportSelect };
