import React, { forwardRef } from 'react';
import { postingsSelector } from 'features/settlements/redux/selectors';
import { SoftPos } from 'types/softpos';
import { useSelector } from 'react-redux';
import { groupBy } from 'lodash';
import { getDay } from 'date-fns';
import { Trans } from 'react-i18next';
import { Col, Row, Table, Typography } from 'antd';
import { Table as VibrantTable, Text } from 'components';
import {
  columns,
  PostingsTableProps,
} from 'features/settlements/components/PostingsTable';
import { formatCurrency } from 'utils';
import styled from 'styled-components';
import { terminalCollectionSelector } from 'features/user/redux/selectors';

type TotalSummation = {
  total: number;
  currency: SoftPos.CurrencyISOCode;
};

const DailySummationPostingTable = forwardRef<
  HTMLDivElement,
  PostingsTableProps
>((props, ref) => {
  const postings: Array<SoftPos.Posting> = useSelector(postingsSelector);
  const terminalCollection = useSelector(terminalCollectionSelector);

  const groupedPostings = groupBy(postings, (posting) => {
    const date = new Date(posting.processedAt);
    return (
      date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate()
    );
  });

  const StyledTableSummaryRow = styled(Table.Summary.Row)`
    line-height: 50px;
    font-weight: bolder;
  `;

  return (
    <Row ref={ref}>
      <Col span={24}>
        {Object.keys(groupedPostings)
          .sort((date) => new Date(date).getTime())
          .map((weekDay) => (
            <div key={weekDay} style={{ marginBottom: 24 }}>
              <Typography.Title level={5}>
                <Trans
                  i18nKey={`DailySummationPostingTable.weekday.${getDay(
                    new Date(weekDay),
                  )}`}
                />
              </Typography.Title>
              <VibrantTable<SoftPos.Posting>
                columns={columns(terminalCollection)}
                dataSource={groupedPostings[weekDay]}
                rowKey={(record) => record.postingIndex}
                pagination={false}
                summary={(pageData) => {
                  console.log({ pageData });
                  let netTotal: TotalSummation = { total: 0, currency: 'DKK' };
                  let feeTotal: TotalSummation = { total: 0, currency: 'DKK' };
                  let grossTotal: TotalSummation = {
                    total: 0,
                    currency: 'DKK',
                  };

                  pageData.forEach((row) => {
                    netTotal = {
                      total: netTotal.total += row.settlementAmount,
                      currency: row.currencyCode,
                    };

                    feeTotal = {
                      total: feeTotal.total += row.settlementFeeAmount,
                      currency: row.currencyCode,
                    };

                    grossTotal = {
                      total: grossTotal.total += row.transactionAmount,
                      currency: row.currencyCode,
                    };
                  });

                  return (
                    <React.Fragment>
                      <StyledTableSummaryRow>
                        <Table.Summary.Cell index={1}>
                          <Text size="ui2">
                            <Trans i18nKey="DailySummationPostingTable.total.total" />
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={2} />
                        <Table.Summary.Cell index={3} />
                        <Table.Summary.Cell index={4} />
                        <Table.Summary.Cell index={5} />
                        <Table.Summary.Cell index={6}>
                          {formatCurrency(
                            netTotal.total / 100,
                            netTotal.currency,
                          )}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={7}>
                          {formatCurrency(
                            feeTotal.total / 100,
                            feeTotal.currency,
                          )}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={8}>
                          {formatCurrency(
                            grossTotal.total / 100,
                            grossTotal.currency,
                          )}
                        </Table.Summary.Cell>
                      </StyledTableSummaryRow>
                    </React.Fragment>
                  );
                }}
              />
            </div>
          ))}
      </Col>
    </Row>
  );
});
export { DailySummationPostingTable };
