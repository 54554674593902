/**
 * Imports
 */
import firebase from 'firebase';
import * as React from 'react';
import { Firebase } from 'types/firebase';

/**
 * Types
 */
export type PhotoidsApiAPIHandlers = {
  onDownloadImage: any;
};
export type FileUrlObj = {
  downloadUrl: string;
  storagePath: string;
  fileName: string;
  fileIndex: number;
};
export type PhotoidsApiAPIType = {
  handlers: PhotoidsApiAPIHandlers;
  isLoading: boolean;
  fileUrls: FileUrlObj[];
};

export interface UploadPhotoFormProps {
  owner: Firebase.Owner;
  ownerIndex: number;
}

/**
 * usePhotoidsApi
 */
const usePhotoidsApi = ({
  owner,
}: UploadPhotoFormProps): PhotoidsApiAPIType => {
  /* Local state */
  const [isLoading, setIsLoading] = React.useState(false);
  const [fileUrls, setFileUrls] = React.useState<FileUrlObj[]>([]);

  /**
   * _getImageUrl
   */
  const _getImageUrl = (path: string): Promise<string> => {
    const storage = firebase.storage();
    return storage.ref(path).getDownloadURL();
  };

  /**
   * GetImages
   * TODO: useMemo instead of usestate
   * TODO: fix wrong index when uploading new to not override
   */
  const _getImages = () => {
    const fileUrlMap = owner.fileUrl;
    setFileUrls([]);
    if (owner?.addressId) {
      _getImageUrl(owner.addressId).then((downloadUrl) => {
        const fileName = owner
          .addressId!.split('/')[2]
          .replace(/.jpeg|.png/gi, '');
        return setFileUrls((prev) => [
          ...prev,
          {
            downloadUrl,
            storagePath: owner.addressId!,
            fileName,
            fileIndex: 0,
          },
        ]);
      });
    }
    if (owner?.photoId) {
      _getImageUrl(owner.photoId).then((downloadUrl) => {
        const fileName = owner
          .photoId!.split('/')[2]
          .replace(/.jpeg|.png/gi, '');
        return setFileUrls((prev) => [
          ...prev,
          { downloadUrl, storagePath: owner.photoId!, fileName, fileIndex: 1 },
        ]);
      });
    }
    if (fileUrlMap != null) {
      Object.entries(fileUrlMap).map((fileKV) => {
        const storagePath = fileKV[1];
        const fileIndex = parseInt(fileKV[0], 10);
        _getImageUrl(storagePath).then((downloadUrl) => {
          const fileName = storagePath
            .split('/')[2]
            .replace(/.jpeg|.png/gi, '');
          return setFileUrls((prev) => [
            ...prev,
            {
              downloadUrl,
              storagePath,
              fileName,
              fileIndex,
            },
          ]);
        });
      });
    }
  };

  React.useEffect(() => {
    _getImages();
  }, [owner]);

  const handlers: PhotoidsApiAPIHandlers = {
    /**
     * onDownloadImage
     */
    onDownloadImage: async () => {
      // onDownloadImage
    },
  };

  return { handlers, isLoading, fileUrls };
};

/**
 * Exports
 */
export { usePhotoidsApi };
