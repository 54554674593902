/**
 * Imports
 */
import {
  CopyOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  PlusOutlined,
  QrcodeOutlined,
} from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import {
  Box,
  Button,
  Icon,
  List,
  messageInfo,
  Space,
  Table,
  Text,
  VibrantIcon,
} from 'components';
import { useDashboardSettings, useMerchantApi } from 'features/shared';
import moment from 'moment';
import * as React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Firebase } from 'types/firebase';
import { DATE_FORMATS } from 'utils';
const { Item } = List;
import { Modal } from 'antd';
import { CreateQRLogin } from 'features/terminals';
/**
 * Styling
 */
const CodeWrapper = styled.div`
  .vibranticon {
    margin-right: 12px;
  }
  display: flex;
  align-items: center;
`;
const Hide = styled.div``;
const Copy = styled.div``;
const Plus = styled.button``;
const TerminalDetails = styled.div``;
const LoginDetailsCell = styled.div``;
const LoginDetailsButton = styled.div`
  display: flex;
  align-items: center;
`;
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const ListItem = styled(Item)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StyledList = styled(List)`
  && {
    & .ant-list-item {
      padding: 12px 16px;
    }
  }
`;
/**
 * Types
 */
export type TerminalsLoginDetailsProps = {
  merchantId: string;
  terminal: Firebase.Terminal;
};
export interface TerminalsTableProps {}

/**
 * View code
 */
const ViewCode = ({
  activationCode,
}: {
  activationCode: Pick<Firebase.Terminal, 'activationCode'>;
}) => {
  const [visible, setVisible] = React.useState(false);
  const { t } = useTranslation();
  // const {
  //   dashboardSettings,
  //   handlers: { onSetViewedCode },
  // } = useDashboardSettings();
  const _setVisible = async () => {
    // if (!dashboardSettings.viewedCode) {
    //   await onSetViewedCode();
    // }
    setVisible(!visible);
  };
  const _onCopy = (content: string) => {
    messageInfo({ content });
  };

  return (
    <>
      {visible ? (
        <CodeWrapper>
          <CopyToClipboard
            text={activationCode.toString()}
            onCopy={() => _onCopy(t('Terminal.code_copied_text'))}
          >
            <Copy>
              <Icon icon={<CopyOutlined />} />
            </Copy>
          </CopyToClipboard>
          <Space vertical />
          <Hide onClick={_setVisible}>
            <Icon icon={<EyeInvisibleOutlined />} />
          </Hide>
          <Space vertical />
          <Text size="ui2">{activationCode}</Text>
        </CodeWrapper>
      ) : (
        <CodeWrapper>
          <Text size="ui2" onClick={_setVisible} color="brand">
            <VibrantIcon icon="pen" color="Orange500" />
            <Trans i18nKey="Terminal.table_cell.view_code" />
          </Text>
        </CodeWrapper>
      )}
    </>
  );
};

/**
 * RenameTerminal
 */
const RenameTerminal = ({ terminal }: { terminal: Firebase.Terminal }) => (
  <Text size="ui2">{terminal.name}</Text>
);

/**
 * TerminalLoginDetails
 */
const TerminalLoginDetails: React.FC<TerminalsLoginDetailsProps> = (props) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = React.useState(false);

  const _onCopy = (content: string) => {
    messageInfo({ content });
  };

  return (
    <TerminalDetails>
      <Modal
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={null}
        closeIcon={<VibrantIcon icon="close" size={20} />}
        style={{ maxWidth: 500, width: '100%' }}
      >
        <CreateQRLogin terminal={props.terminal} />
      </Modal>
      <StyledList>
        <CopyToClipboard
          text={props.merchantId.toString()}
          onCopy={() =>
            _onCopy(
              t('Terminal.terminal_login_details.merchant_id_copied_text'),
            )
          }
        >
          <ListItem>
            <TextWrapper>
              <Text size="ui3" bold>
                <Trans i18nKey="Terminal.terminal_login_details.merchant_id" />
              </Text>
              <Text size="p2">{props.merchantId}</Text>
            </TextWrapper>
            <Copy>
              <Icon icon={<CopyOutlined />} />
            </Copy>
          </ListItem>
        </CopyToClipboard>
        <CopyToClipboard
          text={props.merchantId.toString()}
          onCopy={() =>
            _onCopy(
              t('Terminal.terminal_login_details.terminal_id_copied_text'),
            )
          }
        >
          <ListItem>
            <TextWrapper>
              <Text size="ui3" bold>
                <Trans i18nKey="Terminal.terminal_login_details.terminal_id" />
              </Text>
              <Text size="p2">{props.terminal.terminalId}</Text>
            </TextWrapper>
            <Copy>
              <Icon icon={<CopyOutlined />} />
            </Copy>
          </ListItem>
        </CopyToClipboard>
        <CopyToClipboard
          text={props.merchantId.toString()}
          onCopy={() =>
            _onCopy(t('Terminal.terminal_login_details.code_copied_text'))
          }
        >
          <ListItem>
            <TextWrapper>
              <Text size="ui3" bold>
                <Trans i18nKey="Terminal.terminal_login_details.activation_code" />
              </Text>
              <Text size="p2">{props.terminal.activationCode}</Text>
            </TextWrapper>

            <Copy>
              <Icon icon={<CopyOutlined />} />
            </Copy>
          </ListItem>
        </CopyToClipboard>
        <ListItem>
          <TextWrapper>
            <Button
              onClick={() => setShowModal(true)}
              type="primary"
              icon={<Icon icon={<QrcodeOutlined />} color="Brand500" />}
            >
              &nbsp;
              {t('Terminal.create_terminal_login.submit_btn')}
            </Button>
          </TextWrapper>
        </ListItem>
      </StyledList>
    </TerminalDetails>
  );
};

/**
 * TerminalsTable component
 */
const TerminalsTable: React.FC<TerminalsTableProps> = (_props) => {
  const [_expanded, setExpanded] = React.useState('');

  const { merchantCollection } = useMerchantApi();

  /**
   * Table columns
   */
  const columns: ColumnsType<Firebase.Terminal> = [
    {
      title: <Trans i18nKey="Terminal.table_header.terminal_id" />,
      dataIndex: 'terminalId',
      key: 'terminalId',
      render: (terminalId: string) => {
        return <Text size="ui2">{terminalId}</Text>;
      },
    },
    {
      title: <Trans i18nKey="Terminal.table_header.terminal_name" />,
      dataIndex: 'name',
      key: 'name',
      render: (_name: string, terminal: Firebase.Terminal) => {
        return <RenameTerminal terminal={terminal} />;
      },
    },
    {
      title: <Trans i18nKey="Terminal.table_header.terminal_created" />,
      dataIndex: 'createdAt',
      key: 'createdAt',
      responsive: ['sm'],
      render: (createdAt: string) => {
        return (
          <Text size="ui2">{moment(createdAt).format(DATE_FORMATS.date)}</Text>
        );
      },
    },
    {
      title: <Trans i18nKey="Terminal.table_header.terminal_login_details" />,
      dataIndex: 'activationCode',
      key: 'activationCode',
      render: (_activationCode, record: Firebase.Terminal) => {
        const isExpanded = record.terminalId === _expanded;
        return (
          <LoginDetailsCell>
            {isExpanded ? (
              <LoginDetailsButton>
                <Trans i18nKey="Terminal.terminal_login_details.button_hide" />
                <Space vertical size="xs" />
                <Icon icon={<EyeInvisibleOutlined />} />
              </LoginDetailsButton>
            ) : (
              <LoginDetailsButton>
                <Trans i18nKey="Terminal.terminal_login_details.button_view" />
                <Space vertical size="xs" />
                <Icon icon={<EyeOutlined />} />
              </LoginDetailsButton>
            )}
          </LoginDetailsCell>
        );
      },
    },
  ];

  return (
    <Table<Firebase.Terminal>
      columns={columns}
      dataSource={merchantCollection?.terminalCollection ?? []}
      pagination={false}
      expandRowByClick
      childrenColumnName="Login"
      expandIconColumnIndex={-1}
      expandable={{
        onExpand: (expanded: boolean, record) => {
          setExpanded(expanded ? record.terminalId : '');
        },
        expandedRowRender: (record: Firebase.Terminal) => (
          <TerminalLoginDetails
            activationCode={record.activationCode}
            merchantId={merchantCollection.merchantId}
            terminal={record}
          />
        ),
      }}
      // loading={
      //   !merchantCollection ||
      //   merchantCollection.terminalCollection.length === 0
      // }
      rowKey={(record) => record.terminalId}
    />
  );
};

/**
 * Exports
 */
export { TerminalsTable };
