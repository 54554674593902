import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  max-width: 431px;
`;

export const TextContainer = styled.div`
  margin-top: 24px;
`;
