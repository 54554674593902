/**
 * Imports
 */
import { createAction } from '@reduxjs/toolkit';
import { VibrantlyAction } from 'store/types';
import { SoftPos } from 'types/softpos';
import { Settlements } from '../types';

/**
 * Constants
 */
const PREFIX = 'settlements';
/**
 * SET_DATES
 */
export const SET_DATES = `${PREFIX}/SET_DATES`;
export type SetDatesAction = VibrantlyAction<Settlements.SetDatesPayload>;
/**
 * GET_SETTLEMENTS
 */
export const GET_SETTLEMENTS = `${PREFIX}/GET_SETTLEMENTS`;
export type GetSettlementsAction = VibrantlyAction<
  Settlements.GetSettlementsPayload
>;
export const getSettlements = createAction<GetSettlementsAction['payload']>(
  GET_SETTLEMENTS,
);
/**
 * CURRENT_BALANCE
 */
export const CURRENT_BALANCE = `${PREFIX}/CURRENT_BALANCE`;
export type CurrentBalanceAction = VibrantlyAction<{
  settlements: SoftPos.Settlement[];
}>;
export const getCurrentBalance = createAction<CurrentBalanceAction['payload']>(
  CURRENT_BALANCE,
);
/**
 * GET_POSTINGS
 */
export const GET_POSTINGS = `${PREFIX}/GET_POSTINGS`;
export type GetPostingsAction = VibrantlyAction<Settlements.GetPostingsPayload>;
export const getPostings = createAction<GetPostingsAction['payload']>(
  GET_POSTINGS,
);

export const setDates = createAction<SetDatesAction['payload']>(SET_DATES);
