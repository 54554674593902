import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Testimonial feature styling */
  /* display: grid;
  grid-template-columns: 2fr 5fr;
  width: 100vw;
  height: 100vh; */
`;

export const TestimonialContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-color: ${(props) => props.theme.colors.Background.LoginTestimonal};
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TestimonialImage = styled.img`
  width: 100%;
  object-fit: scale-down;
`;

export const TestimonialContent = styled.div`
  padding: 48px;
`;

export const TextContainer = styled.div`
  & > :last-child {
    margin-top: 14px;
  }
`;

export const LoginFormContainer = styled.div`
  margin-top: 32px;
`;
