/**
 * Imports
 */
import * as React from 'react';

import { createDynamicLink } from 'apis/dynamicLinkAPI';
import { Box, Button, Text, Icon, messageInfo } from 'components';
import { emailSelector, userSelector } from 'features/user/redux/selectors';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import QRCode from 'react-qr-code';
import { Firebase } from 'types/firebase';
import { CopyOutlined, QrcodeOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
/**
 * Types
 */
export interface CreateQRLoginProps {
  terminal: Firebase.Terminal;
}

/**
 * CreateQRLogin component
 */
const CreateQRLogin: React.FC<CreateQRLoginProps> = (props) => {
  const [signInLink, setSignInLink] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const { t } = useTranslation();
  const email = useSelector(emailSelector);
  const user = useSelector(userSelector);

  const _onCopy = (content: string) => {
    messageInfo({ content });
  };

  const _onCreateDynamicLink = async () => {
    setIsLoading(true);
    const resp = await createDynamicLink(user.id, props.terminal.terminalId);
    setIsLoading(false);
    if (resp?.data.url) {
      setSignInLink(resp.data.url);
    } else {
      // handle error
    }
  };

  return (
    <Box
      minHeight={256}
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
    >
      <Box>
        <Text size="h5">{props.terminal.name}</Text>
      </Box>
      <Box flexDirection="column" mt={24}>
        <Box display="flex" justifyContent="space-between">
          <Text size="p2">{t('_Common.label.user')}</Text>
          <CopyToClipboard
            text={email}
            onCopy={() =>
              _onCopy(t('Terminal.terminal_login_details.email_copied_text'))
            }
          >
            <Box
              display="flex"
              alignItems="center"
              style={{ cursor: 'pointer' }}
            >
              <Text size="p2" bold>
                {email}
              </Text>
              <Box ml={12}>
                <Icon icon={<CopyOutlined />} />
              </Box>
            </Box>
          </CopyToClipboard>
        </Box>
        <Box display="flex" justifyContent="space-between" mt={12}>
          <Text size="p2">{t('Terminal.table_header.terminal_id')}</Text>
          <CopyToClipboard
            text={props.terminal.terminalId}
            onCopy={() =>
              _onCopy(
                t('Terminal.terminal_login_details.terminal_id_copied_text'),
              )
            }
          >
            <Box
              display="flex"
              alignItems="center"
              style={{ cursor: 'pointer' }}
            >
              <Text size="p2" bold>
                {props.terminal.terminalId}
              </Text>
              <Box ml={12}>
                <Icon icon={<CopyOutlined />} />
              </Box>
            </Box>
          </CopyToClipboard>
        </Box>
      </Box>
      {signInLink && (
        <Box mt={24} alignItems="center" justifyContent="center">
          <QRCode value={signInLink} size={256} />
        </Box>
      )}
      <Box display="flex" justifyContent="space-between" mt={24}>
        <Button
          loading={isLoading}
          onClick={_onCreateDynamicLink}
          type="primary"
          icon={<Icon icon={<QrcodeOutlined />} color="Brand500" />}
        >
          &nbsp;
          {t('Terminal.create_terminal_login.submit_btn')}
        </Button>
      </Box>
    </Box>
  );
};

/**
 * Exports
 */
export { CreateQRLogin };
