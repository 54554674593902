/**
 * Imports
 */
import { Space, Text, VibrantIcon } from 'components';
import { currentBalanceSelector } from 'features/settlements/redux/selectors';
import { Currency } from 'features/shared';
import moment from 'moment';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ROUTES } from 'router/RootRouter';
import styled from 'styled-components';
import { formatCurrency } from 'utils';

/**
 * Styling
 */
const Root = styled.div`
  margin: 48px 0;
  display: flex;
  flex-direction: column;
`;
const BoxRow = styled.div`
  display: flex;
  margin: 0 0 24px 0;
`;
const Box = styled.div<{ first?: boolean; last?: boolean }>`
  background-color: ${({ theme }) => theme.colors.Background.Primary};
  border: ${(props) =>
    props.last && '1px solid' + props.theme.colors.Base.Brand};
  border-left: none;
  height: 114px;
  position: relative;
  padding: ${({ first }) => (first ? '24px' : '24px 24px 24px 40px')};
  width: 25%;

  display: flex;
  flex-direction: column;

  & svg {
    color: ${(props) => props.last && props.theme.colors.Base.Brand}!important;
    fill: transparent;
  }
`;
const ArrowPointer = styled.div`
  position: absolute;
  top: 0;
  left: -48px;
  & svg {
    color: ${(props) => props.theme.colors.Border.BorderPrimary};
    fill: transparent;
  }
`;
const EntryRow = styled.div`
  display: flex;
  align-items: flex-end;
`;
const Details = styled.div`
  margin-left: auto;
  margin-bottom: 6px;
`;
/**
 * Types
 */
export interface CurrentBalanceProps {}

/**
 * CurrentBalance component
 */
const CurrentBalance: React.FC<CurrentBalanceProps> = (_props) => {
  const currentBalance = useSelector(currentBalanceSelector);
  const navigation = useHistory();

  if (!currentBalance[0]) {
    return null;
  }
  // if (currentBalance[0].startedAt === null) {
  //   return null;
  // }
  /**
   * OnRowClick
   */
  const _viewDetails = () => {
    const path = `${ROUTES.settlementDetails.path}/${currentBalance[0].settlementId}`;
    navigation.push(path);
  };

  return (
    <Root>
      <Text size="ui2" bold>
        <Trans i18nKey="SettlementDetails.balance.header" />
      </Text>
      <Space />
      <BoxRow>
        {/* Period */}
        <Box first>
          <Text size="ui2" color="uiDark">
            <Trans i18nKey="SettlementDetails.table_header.period" />
          </Text>
          <Space size="xs" />
          <Text size="h5">
            {currentBalance[0].startedAt && currentBalance[0].endedAt && (
              <>
                {moment(currentBalance[0].startedAt).format('DD.MM')}-
                {moment(currentBalance[0].endedAt).format('DD.MM')}
              </>
            )}
          </Text>
        </Box>
        {/* Gross */}
        <Box>
          <Text size="ui2" color="uiDark">
            <Trans i18nKey="SettlementDetails.summary_payout.payout_sales" />
          </Text>
          <Space size="xs" />
          <EntryRow>
            <Text size="h5">
              {formatCurrency(currentBalance[0].grossAmount / 100)}
            </Text>
            <Space vertical size="sm" />
            <Currency />
          </EntryRow>
          <ArrowPointer>
            <VibrantIcon icon="arrow-pointer" size={114} />
          </ArrowPointer>
        </Box>
        <Box>
          <Text size="ui2" color="uiDark">
            <Trans i18nKey="SettlementDetails.summary_payout.payout_fees" />
          </Text>
          <Space size="xs" />
          <EntryRow>
            <Text size="h5">
              {formatCurrency(currentBalance[0].settlementFeeAmount / 100)}
            </Text>
            <Space vertical size="sm" />
            <Currency />
          </EntryRow>
          <ArrowPointer>
            <VibrantIcon icon="arrow-pointer" size={114} />
          </ArrowPointer>
        </Box>
        {/* Payout */}
        <Box last>
          <Text size="ui2" color="uiDark">
            <Trans i18nKey="SettlementDetails.balance.title" />
          </Text>
          <Space size="xs" />
          <EntryRow>
            <Text size="h5" color="brand">
              {formatCurrency(currentBalance[0].settlementAmount / 100)}
            </Text>
            <Space vertical size="sm" />
            <Currency />
            <Details>
              <Text size="ui3" link onClick={_viewDetails}>
                <Trans i18nKey="_Common.actions.details" />
              </Text>
            </Details>
          </EntryRow>
          <ArrowPointer>
            <VibrantIcon icon="arrow-pointer" size={114} />
          </ArrowPointer>
        </Box>
      </BoxRow>
      {/* Payout information */}
    </Root>
  );
};

/**
 * Exports
 */
export { CurrentBalance };
