import styled from 'styled-components';
import { Text } from 'components';

export const Container = styled.div<{ isDisabled: boolean }>`
  display: flex;
  flex-direction: column;

  ${({ isDisabled }) => `
   > :not(${ContentContainer.toString()}) {
      opacity: ${isDisabled ? 0.4 : 1};
    }
  `}
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    fill: none;
    color: ${(props) => props.theme.colors.Text.Brand};
  }
`;

export const TitleText = styled(Text)`
  margin-left: 14px;
`;

export const ContentContainer = styled.div`
  margin-top: 17px;
`;
