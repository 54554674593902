/**
 * Imports
 */
import { Modal } from 'antd';
import { VibrantIcon } from 'components';
import { hideAddOwnerModal } from 'features/navigation/redux/actions';
import { showAddOwnerModalSelector } from 'features/navigation/redux/selectors';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AddOwnerForm } from '../AddOwnerForm';

/**
 * AddOwnerModal component
 */
const AddOwnerModal: React.FC = () => {
  const showOwnerModal = useSelector(showAddOwnerModalSelector);
  const dispatch = useDispatch();

  const _handleCancel = () => {
    dispatch(hideAddOwnerModal({}));
  };

  return (
    <Modal
      visible={showOwnerModal}
      onCancel={_handleCancel}
      footer={null}
      closeIcon={<VibrantIcon icon="close" size={20} />}
    >
      <AddOwnerForm />
    </Modal>
  );
};

/**
 * Exports
 */
export { AddOwnerModal };
