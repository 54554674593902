import * as React from 'react';
import styled from 'styled-components';
import { VibrantColors } from 'theme';
import { LockScreenContent } from './LockScreenContent';

const StFlex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: ${VibrantColors.Background.InReview};

  @media screen and (max-width: 968px) {
    padding: 0 48px;
  }
`;

const LockScreen: React.FC = () => (
  <StFlex>
    <LockScreenContent />
  </StFlex>
);

export { LockScreen };
