import * as React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';
import { VibrantIcon } from '../VibrantIcon';
import { Text, Box } from 'components';
import { VibrantColors } from 'theme';
import {
  firebaseUserSelector,
  terminalCollectionSelector,
  dashboardSettingsSelector,
} from 'features/user/redux/selectors';
import { useDashboardSettings } from 'features/shared';

const StWrapper = styled.div`
  display: flex;
  margin: -48px -48px 32px -48px;
  background-color: ${VibrantColors.Background.ReadyToWork};
  transition: all 0.2s linear;
`;

const StRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 37px 48px 32px 48px;
`;

const StFlex = styled(Box)`
  position: relative;
  display: flex;
`;

const CloseButton = styled.div`
  cursor: pointer;
  height: 20px;
  width: 20px;
  & svg {
    transition: ${({ theme }) => theme.transitions.HoverTextColor};
  }
  &:hover svg {
    color: ${({ theme }) => theme.colors.Shades.Orange500};
  }
`;

const ReadyToWork: React.FC = () => {
  const [isOpenScreen, setIsOpenScreen] = React.useState<boolean>(false);
  const { handlers } = useDashboardSettings();

  const { t } = useTranslation();
  const dashboardSettings = useSelector(dashboardSettingsSelector);
  const fbUser = useSelector(firebaseUserSelector);
  const name = fbUser.displayName?.split(' ')[0] || fbUser.email;

  React.useEffect(() => {
    if (dashboardSettings?.guides?.setup) {
      setIsOpenScreen(!isOpenScreen);
    }
  }, []);

  const handleClose = () => {
    setIsOpenScreen(!isOpenScreen);
    handlers.onCloseGuide('setup');
  };

  if (!isOpenScreen) {
    return null;
  }

  return (
    <StWrapper>
      <StRow>
        <CloseButton onClick={handleClose}>
          <VibrantIcon icon="close" />
        </CloseButton>
        <StFlex margin="73px 0 18px" alignItems="center">
          <VibrantIcon icon="unlock" color="Green800" />
          <Text size="p2" bold textIndent="10px" color="readyToWork">
            {t('LockScreen.Common.status')}
          </Text>
          <Text size="p2" textIndent="4px" color="readyToWork">
            {t('LockScreen.ReadyToWork.done')}
          </Text>
        </StFlex>
        <StFlex width="100%">
          <StFlex flexDirection="column" alignItems="flex-start">
            <Text size="h5" color="readyToWork" bold>
              {t('LockScreen.ReadyToWork.congratulations')}
            </Text>
            <Text size="h5" color="paragraph">
              <Trans
                i18nKey="LockScreen.ReadyToWork.welcome"
                values={{ name }}
              />
            </Text>
          </StFlex>
          <StFlex justifyContent="flex-end" marginLeft="calc(100% - 95%)">
            <Text size="p1" color="paragraph">
              {t('LockScreen.ReadyToWork.ready_to_work')}
            </Text>
          </StFlex>
        </StFlex>
      </StRow>
    </StWrapper>
  );
};

export { ReadyToWork };
