/**
 * Types
 */
export enum SelectTypeEnum {
  transaction = 'transaction',
  settlement = 'settlement',
  keynumbers = 'keynumbers',
}
export enum FixedDatesRange {
  NONE = 'NONE',
  TODAY = 'TODAY',
  YESTERDAY = 'YESTERDAY',
  LAST_WEEK = 'LAST_WEEK',
  MONTH_TO_DATE = 'MONTH_TO_DATE',
  LAST_MONTH = 'LAST_MONTH',
  YEAR_TO_DATE = 'YEAR_TO_DATE',
  LAST_YEAR = 'LAST_YEAR',
}
/**
 * Shared feature namespace
 */
export declare namespace Shared {}
