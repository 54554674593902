import { Button, Text } from 'components';
import {
  closeProductModal,
  openProductModal,
} from 'features/products/redux/actions';
import { productModalOpen } from 'features/products/redux/selectors';
import { ProductDataWithId } from 'features/products/types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ProductRow } from '../ProductRow';
import { Row } from '../Row';

const Container = styled.section``;

const Topbar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 32px;
`;

interface Props {
  products: ProductDataWithId[];
}

export const ProductTable: FC<Props> = ({ products }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modalOpened = useSelector(productModalOpen);
  const toggleModal = () => {
    dispatch(modalOpened ? closeProductModal({}) : openProductModal({}));
  };

  return (
    <>
      <Topbar>
        <Text size="h5">{t('Products.Common.product_list')}</Text>
        <Button type="text" onClick={() => toggleModal()}>
          {t('Products.Common.add_product')}
        </Button>
      </Topbar>
      <Container>
        <Row className="head">
          <div className="column name">
            <Text size="ui3">{t('Products.Common.name')}</Text>
          </div>
          <div className="column vat">
            <Text size="ui3">{t('Products.Common.vat')}</Text>
          </div>
          <div className="column price">
            <Text size="ui3">{t('Products.Common.price')}</Text>
          </div>
          <div className="column actions" />
        </Row>
        {products
          .filter((p) => !p.archivedAt)
          .map((product) => {
            return <ProductRow product={product} key={product.id} />;
          })}
      </Container>
    </>
  );
};
