import styled from 'styled-components';
import { Button, Text } from 'components';

export const EditLabelContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Container = styled(EditLabelContainer)<{ isDisabled: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0px;
  border-top: 1px solid;
  border-color: ${(props) => props.theme.colors.Background.Primary};
  &:last-child {
    border-bottom: 1px solid;
    border-color: ${(props) => props.theme.colors.Background.Primary};
  }

  opacity: ${(props) => (props.isDisabled ? 0.4 : 1)};
`;

export const LabelContainer = styled.div``;

export const InputContainer = styled.form`
  margin-top: 16px;
`;

export const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  gap: 12px;
  align-items: center;
`;

export const ChangeButton = styled(Button)`
  color: ${(props) => props.theme.colors.Text.Brand};
`;

export const SaveButton = styled(Button)`
  width: 100%;
  height: 100%;
`;

export const LabelText = styled(Text)`
  margin-bottom: 8px;
`;
