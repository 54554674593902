/**
 * Imports
 */
import { PaginationProps } from 'antd/lib/pagination';
import { TablePaginationConfig } from 'antd/lib/table';
import { getTransactionsApi } from 'apis';
import { VibrantAPI } from 'types/vibrant';
import { orderBy } from 'lodash';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTransactions } from '../redux/actions';
import {
  endDateSelector,
  startDateSelector,
  terminalIdSelector,
} from '../redux/selectors';
import { SoftPos } from 'types/softpos';
import { messageError } from 'components';

/**
 * Types
 */
type UseTransactionsReturnType = {
  isFecthing: boolean;
  tableChangeHandler: (pagination: PaginationProps) => void;
  pagination: TablePaginationConfig;
  filteredTrxns: SoftPos.Trxn[];
};

/**
 * useTransactionsApi component
 */
const useTransactionsApi = (): UseTransactionsReturnType => {
  const [isFecthing, setIsFetching] = React.useState(false);
  const [pagination, setPagination] = React.useState<PaginationProps>({});
  const [filteredTrxns, setFilteredTrxns] = React.useState<SoftPos.Trxn[]>([]);
  const terminalId = useSelector(terminalIdSelector);

  const dispatch = useDispatch();

  const startDate = useSelector(startDateSelector);
  const endDate = useSelector(endDateSelector);

  const _buildPagination = (totalRecords: number) => {
    const nextPagination = { ...pagination };
    nextPagination.total = totalRecords ? totalRecords : undefined;
    setPagination(nextPagination);
  };

  const fecthTrxnItems = React.useCallback(
    async (currentPage, pageSize) => {
      setIsFetching(true);
      try {
        const resp = (await getTransactionsApi(
          startDate,
          endDate,
          currentPage,
          pageSize,
        )) as VibrantAPI.GetTrxnsResult;

        // Rm null transactions
        const filterNullTrxn = resp.trxns.filter(
          (trxn) => trxn.responseCode !== null,
        );
        // if terminalId is set, filter trxn
        const trxns = terminalId
          ? filterNullTrxn.filter((trxn) => trxn.terminalId === terminalId)
          : filterNullTrxn;
        const trxnItems = orderBy(trxns, ['date'], ['desc']);

        setFilteredTrxns(trxnItems);

        dispatch(
          getTransactions({
            trxnItems,
            trxnCount: resp.count,
          }),
        );
        _buildPagination(resp.count);
        setIsFetching(false);
      } catch (exception) {
        messageError({
          content: exception?.message,
        });
        setIsFetching(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [endDate, startDate, terminalId],
  );

  const tableChangeHandler = (pagination: PaginationProps) => {
    const pager = { ...pagination };
    pager.current = pagination.current;
    setPagination(pager);
    fecthTrxnItems(pagination.current, pagination.pageSize);
  };

  React.useEffect(() => {
    fecthTrxnItems(1, 100000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate, startDate, terminalId]);

  return { isFecthing, tableChangeHandler, pagination, filteredTrxns };
};

/**
 * Exports
 */
export { useTransactionsApi };
