import React, { useEffect, useState } from 'react';
import { Spin, Result, Button } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';

import { confirmEmail } from 'apis';
import { firebaseTokenLogin } from 'firebaseAPI';
import { firebaseUserSelector } from 'features/user/redux/selectors';

import * as Styles from './VerifyEmail.styles';

const VerifyEmail = () => {
  const [showSuccessPage, setShowSuccessPage] = useState(false);
  const [showErrorPage, setShowErrorPage] = useState(false);
  const user = useSelector(firebaseUserSelector);
  const { token } = useParams<{ token: string }>();
  const navigate = useHistory();

  useEffect(() => {
    if (token != null) {
      init();
    }
  }, [token]);

  const init = async () => {
    const loginToken = await confirmEmail(token);

    if (loginToken == null) {
      displayErrorPage();
      return;
    }

    // don't reauthenticate the user if already authenticated
    if (user == null) {
      await firebaseTokenLogin(loginToken);
    }

    displaySuccessPage();
  };

  const displaySuccessPage = () => {
    setShowSuccessPage(true);

    setTimeout(() => {
      // navigate.replace({ pathname: '/' });
    }, 2000);
  };

  const displayErrorPage = () => {
    setShowErrorPage(true);
  };

  if (showErrorPage) {
    return (
      <Styles.Container>
        <Result
          status="warning"
          title={<Trans i18nKey="VerifyEmail.error.title" />}
          subTitle={<Trans i18nKey="VerifyEmail.error.message" />}
          extra={
            <a href="mailto:support@vibrant.io">
              <Button type="primary" key="contact">
                <Trans i18nKey="VerifyEmail.error.cta" />
              </Button>
            </a>
          }
        />
      </Styles.Container>
    );
  }

  if (showSuccessPage) {
    return (
      <Styles.Container>
        <Result
          status="success"
          title={<Trans i18nKey="VerifyEmail.success.title" />}
          subTitle={<Trans i18nKey="VerifyEmail.success.message" />}
        />
      </Styles.Container>
    );
  }

  return (
    <Styles.Container>
      <Spin />
    </Styles.Container>
  );
};

export { VerifyEmail };
