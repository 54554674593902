import { createReducer } from '@reduxjs/toolkit';
import { closeProductModal, openProductModal } from './actions';

export interface ProductModalState {
  open: boolean;
}

const INITIAL_STATE: ProductModalState = {
  open: false,
};

const productModalReducer = createReducer(INITIAL_STATE, {
  [closeProductModal.type]: (state) => {
    state.open = false;
  },
  [openProductModal.type]: (state) => {
    state.open = true;
  },
});

export default productModalReducer;
