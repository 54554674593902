import React, { FC } from 'react';
import { BaseLayout } from 'features/navigation';
import { ProductList } from 'features/products/components';

export interface ProductsPageProps {}

export const ProductsPage: FC<ProductsPageProps> = () => {
  return (
    <BaseLayout disableBreadcrumb>
      <ProductList />
    </BaseLayout>
  );
};
