/**
 * Imports
 */
import Icon from '@ant-design/icons';
import { Icon as CustomIcon, Text, VibrantIcon } from 'components';
import { CustomIconType } from 'components/VibrantIcon/VibrantIcon';
import * as React from 'react';
import styled from 'styled-components';

/**
 * Styling
 */
const StepBox = styled.div<{
  hasAction?: boolean;
  done?: boolean;
  wide?: boolean;
}>`
  border-right: ${(props) => !props.wide && '1px solid rgba(73, 33, 11, 0.1)'};
  cursor: ${(props) => props.hasAction && 'pointer'};
  /* min-height: 292px; */
  padding: 48px 40px 32px;
  padding: ${(props) =>
    props.done ? '16px 40px 32px 40px' : '48px 40px 32px 40px'};

  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.wide ? 'flex-start' : 'center')};
  justify-content: flex-end;
  flex: 1;

  & svg {
    font-size: 128px;
  }

  @media (max-width: 1365px) {
    /* max-height: 224px; */
    /* min-height: auto; */
    padding: ${(props) =>
      props.done ? '16px 16px 16px 16px' : '32px 16px 16px 16px'};
    & svg {
      font-size: 64px;
    }
  }
`;

const Checkmark = styled.div`
  margin-right: auto;
  margin-bottom: auto;

  & .vibranticon {
    height: 40px !important;
    width: 40px !important;
  }
  @media (max-width: 1365px) {
    & .vibranticon {
      height: 30px !important;
      width: 30px !important;
    }
  }
`;
const StepImageWrapper = styled.div<{ done?: boolean }>`
  opacity: ${(props) => (props.done ? '0.5' : '1')};

  & svg {
    color: transparent;
  }
`;
const StepTextWrapper = styled.div<{ done?: boolean; wide?: boolean }>`
  opacity: ${(props) => (props.done ? '0.5' : '1')};
  max-width: ${(props) => (props.wide ? '260px' : '128px')};
  text-align: ${(props) => (props.wide ? 'left' : 'center')};
`;
const StepText = styled(Text)`
  margin-top: 40px;
  @media (max-width: 1365px) {
    margin-top: 40px;
    font-size: 16px !important;
  }
`;
const StyledLink = styled.a`
  cursor: pointer;
  font-size: 17px;
  line-height: 21.76px;
  text-decoration: underline;
  margin-top: 16px;
  transition: color 0.2s ease;
  font-family: 'BasierBold';

  &:hover {
    color: ${({ theme }) => theme.colors.Text.Brand};
    text-decoration: underline;
  }
`;

/**
 * Types
 */
export type StepType = {
  done?: boolean;
  wide?: boolean;
  image: CustomIconType;
  text: React.ReactNode;
  action?: () => void;
};

type StepLinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  href: string;
  text: React.ReactNode;
};

interface StepSubComponents {
  Link: typeof Link;
}

const Link: React.FC<StepLinkProps> = ({ href, text }) => (
  <StyledLink href={href}>{text}</StyledLink>
);

/**
 * Step component
 */
const Step: React.FC<StepType> & StepSubComponents = ({
  action,
  children,
  done,
  image,
  wide,
  text,
}) => {
  return (
    <StepBox
      done={done}
      wide={wide}
      hasAction={action ? true : false}
      onClick={action}
    >
      {done && (
        <Checkmark>
          <VibrantIcon icon="checkmark-checked" color="Green700" />
        </Checkmark>
      )}
      <StepImageWrapper done={done}>
        <CustomIcon icon={<Icon component={image} />} />
      </StepImageWrapper>
      <StepTextWrapper done={done} wide={wide}>
        <StepText size="ui2">{text}</StepText>
      </StepTextWrapper>
      {children}
    </StepBox>
  );
};

Step.Link = Link;

/**
 * Exports
 */
export { Step };
