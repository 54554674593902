/**
 * Imports
 */
import axios from 'axios';
import { PROJECT_API_URL, ACCOUNTING_API_URL } from 'config';
import { getIdToken } from 'firebaseAPI';
import { Firebase } from 'types/firebase';
import { VibrantAPI } from 'types/vibrant';
import { captureError } from 'utils/error-logging';

export interface IIntegration {
  syncData(code: string, merchantLocationId?: string): Promise<boolean>;
}

export class DineroIntegration implements IIntegration {
  syncData = async (urlQuery: string): Promise<boolean> => {
    const idToken = await getIdToken();
    const url = `${PROJECT_API_URL}/api/v1/integrations/dinero/enableAccount`;

    try {
      await axios.post(url, { urlQuery }, { headers: { token: idToken } });
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  };
}

export class EconomicIntegration implements IIntegration {
  syncData = async (
    urlQuery: string,
    merchantLocationId?: string,
  ): Promise<boolean> => {
    const urlParams = new URLSearchParams(urlQuery);
    const token = urlParams.get('token');

    const idToken = await getIdToken();
    const url = `${ACCOUNTING_API_URL}/accounting/createEconomicUser?merchantLocationId=${merchantLocationId}&token=${token}`;

    await axios.post(url, { urlQuery }, { headers: { token: idToken } });
    return true;
  };
}

export const integrationFactory = (
  integrationName: Firebase.IntegrationNames | null,
): IIntegration | null => {
  if (integrationName === 'dinero') {
    return new DineroIntegration();
  }
  if (integrationName === 'economic') {
    return new EconomicIntegration();
  }
  return null;
};

/**
 * disableIntegration
 */
export const disableIntegration = async (
  integrationName: string,
  mid: string,
  cid: string,
): Promise<void> => {
  const idToken = await getIdToken();
  try {
    const response = await axios.put(
      `${PROJECT_API_URL}/api/v1/integrations/${integrationName}/disableAccount`,
      {
        merchantLocationId: mid,
        merchantId: cid,
      },
      {
        headers: { token: idToken },
      },
    );
    return response.data.result;
  } catch (error) {
    const errText = 'Failed to disable integration';
    const errMsg = error.message ? error.message : errText;
    captureError(errText, error);

    throw new Error(errMsg);
  }
};
/**
 * getTransferAccounts
 */
export const getTransferAccounts = async (
  integrationName: string,
  mid: string,
  cid: string,
): Promise<VibrantAPI.TransferAccount[]> => {
  const idToken = await getIdToken();
  try {
    const response = (await axios.get(
      `${PROJECT_API_URL}/api/v1/integrations/${integrationName}/getAccounts?merchantLocationId=${mid}&merchantId=${cid}`,
      {
        headers: { token: idToken },
      },
    )) as VibrantAPI.GetTransferAccountsResponse;
    return response.data.result;
  } catch (error) {
    const errText = 'Failed to get accounts';
    const errMsg = error.message ? error.message : errText;
    captureError(errText, error);
    throw new Error(errMsg);
  }
};
/**
 * changeAccount
 */
export const changeAccount = async (
  integrationName: string,
  accountNumber: number,
  merchantLocationId: string,
  merchantId: string,
): Promise<any> => {
  const idToken = await getIdToken();
  try {
    const response = (await axios.put(
      `${PROJECT_API_URL}/api/v1/integrations/${integrationName}/changeAccount`,
      {
        accountNumber,
        merchantLocationId,
        merchantId,
      },
      {
        headers: { token: idToken },
      },
    )) as VibrantAPI.ChangeTransferAccountResponse;
    return response.data;
  } catch (error) {
    const errText = 'Failed to change account';
    const errMsg = error.message ? error.message : errText;
    captureError(errText, error);
    throw new Error(errMsg);
  }
};
/**
 * getOrganizations
 */
export const getOrganizations = async (
  integrationName: string,
  cid: string,
): Promise<VibrantAPI.Organization[]> => {
  const idToken = await getIdToken();
  try {
    const response = (await axios.get(
      `${PROJECT_API_URL}/api/v1/integrations/${integrationName}/getOrganizations?merchantId=${cid}`,
      {
        headers: { token: idToken },
      },
    )) as VibrantAPI.GetOrganizationsResponse;
    return response.data.result;
  } catch (error) {
    const errText = 'Failed to get organizations';
    const errMsg = error.message ? error.message : errText;
    captureError(errText, error);
    throw new Error(errMsg);
  }
};
/**
 * changeOrganization
 */
export const changeOrganization = async (
  integrationName: string,
  organizationId: number,
  merchantLocationId: string,
  merchantId: string,
): Promise<any> => {
  const idToken = await getIdToken();
  try {
    const response = (await axios.put(
      `${PROJECT_API_URL}/api/v1/integrations/${integrationName}/selectOrganization`,
      {
        organizationId,
        merchantLocationId,
        merchantId,
      },
      {
        headers: { token: idToken },
      },
    )) as VibrantAPI.ChangeOrganizationResponse;
    return response.data;
  } catch (error) {
    const errText = 'Failed to change account';
    const errMsg = error.message ? error.message : errText;
    captureError(errText, error);
    throw new Error(errMsg);
  }
};
