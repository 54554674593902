/**
 * Imports
 */
import { IntegrationFeature } from 'features/integrations';
import DineroLogoImg from '../../../assets/images/integrations/visma_dinero_logo.png';
import EconomicLogoImg from '../../../assets/images/integrations/visma_economic_logo.png';
/**
 * Integrations options
 */
export const INTEGRATION_OPTIONS: {
  [key in IntegrationFeature.Options]: IntegrationFeature.OptionType;
} = {
  dinero: {
    enabled: false,
    integrationId: 'dinero',
    name: 'Dinero',
    logo: DineroLogoImg,
  },
  economic: {
    enabled: false,
    integrationId: 'economic',
    name: 'Economic',
    logo: EconomicLogoImg,
  },
};
