import axios from 'axios';
import { PROJECT_API_URL } from 'config';
import { getIdToken } from 'firebaseAPI';
import { Moment } from 'moment';
import { SoftPos } from 'types/softpos';
import { VibrantAPI } from 'types/vibrant';
import { captureError } from 'utils/error-logging';

/**
 * Get all transactions from a specific period
 */
export const getTransactionsApi = async (
  startDate: Moment,
  endDate: Moment,
  currentPage: number,
  pageSize: number,
): Promise<VibrantAPI.GetTrxnsResult> => {
  const idToken = await getIdToken();
  try {
    const utcStart = startDate.clone().utc();
    const utcEnd = endDate.clone().utc();

    const params = {
      from: utcStart.format(),
      to: utcEnd.format(),
      page: currentPage - 1, // arr 0 start
      size: pageSize,
    };
    const response = (await axios.get(
      `${PROJECT_API_URL}/api/softpos/v1/trxns`,
      {
        params,
        headers: { token: idToken },
      },
    )) as VibrantAPI.GetTrxnsResponse;
    return response.data.result;
  } catch (error) {
    captureError(`Failed to get transactions`, error);
    return { count: 0, trxns: [] };
  }
};
/**
 * Get a single transaction
 */
export const getSingleTransactionApi = async (
  trxnId: string,
): Promise<SoftPos.Trxn | undefined> => {
  const idToken = await getIdToken();
  try {
    const response = (await axios.get(
      `${PROJECT_API_URL}/api/softpos/v1/trxns/${trxnId}`,
      {
        headers: { token: idToken },
      },
    )) as VibrantAPI.GetTrxnResponse;
    return response.data.result;
  } catch (error) {
    captureError(`Failed to get transaction`, error);
    return undefined;
  }
};
