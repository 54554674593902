import axios from 'axios';
import { PROJECT_API_URL } from 'config';
import { getIdToken } from 'firebaseAPI';
import { Firebase } from 'types/firebase';

/**
 * get terminals
 */
export const getTerminals = async (): Promise<Firebase.Terminal[]> => {
  const idToken = await getIdToken();
  try {
    const response = await axios.get(
      `${PROJECT_API_URL}/api/v1/merchant/terminals`,
      {
        headers: { token: idToken },
      },
    );
    return response.data.result as Firebase.Terminal[];
  } catch (error) {
    console.log(error);
    return [];
  }
};
/**
 * Create terminal
 */
export const createTerminal = async (
  name: string,
): Promise<Firebase.Terminal> => {
  const idToken = await getIdToken();
  try {
    const response = await axios.post(
      `${PROJECT_API_URL}/api/softpos/v1/terminals/create`,
      {
        name,
      },
      {
        headers: { token: idToken },
      },
    );
    return response.data.result as Firebase.Terminal;
  } catch (error) {
    console.log(error);
    return error;
  }
};
