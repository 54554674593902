/**
 * Imports
 */
import { FirebaseCollectionsType, getFirebaseInstance } from 'firebaseAPI';
import moment from 'moment';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';
import { Firebase } from 'types/firebase';
import { userSelector } from '../redux/selectors';
const firebase = getFirebaseInstance();
/**
 * CONST
 */
const DOCUMENT_ROOT_FOLDER = 'onboarding_documents';
const IMAGE_ROOT_FOLDER = 'onboarding_photo_ids';

/**
 * Types
 */
export type UploadAPIHandlers = {
  onUpload: (file: File, ownerIndex: number) => Promise<void>;
  onUpdateImage: (
    file: File,
    ownerIndex: number,
    fileIndex: number,
  ) => Promise<void>;
};
export type UploadAPIType = {
  handlers: UploadAPIHandlers;
  isUploading: boolean;
};

/**
 * useUpload
 */
const useUpload = (): UploadAPIType => {
  const firestore = useFirestore();
  /* Redux */
  const user = useSelector(userSelector);
  /* Local state */
  const [isUploading, setIsUploading] = React.useState(false);

  const _getStoragePathForDocument = (
    userId: string,
    ownerIndex: number,
    fileType: string,
  ) => {
    const extension = fileType === 'application/pdf' ? 'pdf' : 'jpeg';
    const timestamp = moment().format('DD.MM.YY_HH.mm.SS');
    const path = `${IMAGE_ROOT_FOLDER}/${userId}/owner_${ownerIndex}_${timestamp}.${extension}`;
    return path;
  };

  const _uploadFile = async (file: File, ownerIndex: number) => {
    // get storage path
    const storagePath = _getStoragePathForDocument(
      user.id,
      ownerIndex,
      file.type,
    );
    const metadata = {
      cacheControl: 'public, max-age=900',
      contentType: file.type,
    };
    // store file
    await firebase.storage().ref().child(storagePath).put(file, metadata);
    // return storage path
    return storagePath;
  };

  const _updateExistingFileUrl = async (
    storagePath: string,
    ownerIndex: number,
    fileIndex: number,
  ) => {
    // get all current owner data
    const currentOwnerDataRef = await firestore
      .collection(FirebaseCollectionsType.MERCHANTS)
      .doc(user.meta.merchantId)
      .get();
    // get owner data by index
    const data = currentOwnerDataRef.data() as Firebase.Merchant;
    const currentOwnerData = data.ownerData[ownerIndex];

    const newFileUrlData = {
      ...currentOwnerData.fileUrl,
      [fileIndex]: storagePath,
    };

    // update owner fileUrl
    const newOwnerData = {
      ...currentOwnerData,
      fileUrl: newFileUrlData,
    };

    // update owner data
    data.ownerData[ownerIndex] = newOwnerData;

    // save owner data
    await firestore
      .collection(FirebaseCollectionsType.MERCHANTS)
      .doc(user.meta.merchantId)
      .update(data);
  };

  const _updateNewFileUrl = async (storagePath: string, ownerIndex: number) => {
    try {
      // get all current owner data
      const currentOwnerDataRef = await firestore
        .collection(FirebaseCollectionsType.MERCHANTS)
        .doc(user.meta.merchantId)
        .get();
      // get owner data by index
      const data = currentOwnerDataRef.data() as Firebase.Merchant;
      const currentOwnerData = data.ownerData[ownerIndex];
      // set map index, if none use 0
      const fileIndex = !currentOwnerData.fileUrl
        ? 0
        : Object.keys(currentOwnerData.fileUrl).map((file) => file).length;
      // Check if no file is already uploaded
      const newFileUrlData = !currentOwnerData.fileUrl
        ? { [fileIndex]: storagePath }
        : { ...currentOwnerData.fileUrl, [fileIndex]: storagePath };

      // update owner fileUrl
      const newOwnerData = {
        ...currentOwnerData,
        fileUrl: newFileUrlData,
      };
      // update owner data
      data.ownerData[ownerIndex] = newOwnerData;

      // save owner data
      await firestore
        .collection(FirebaseCollectionsType.MERCHANTS)
        .doc(user.meta.merchantId)
        .update(data);
    } catch (e) {
      throw e;
    }
  };

  const handlers: UploadAPIHandlers = {
    /**
     * onUpload
     */
    onUpdateImage: async (
      file: File,
      ownerIndex: number,
      fileIndex: number,
    ) => {
      try {
        setIsUploading(true);
        const storagePath = await _uploadFile(file, ownerIndex);
        await _updateExistingFileUrl(storagePath, ownerIndex, fileIndex);
        setIsUploading(false);
      } catch (e) {
        setIsUploading(false);
        throw e;
      }
    },
    onUpload: async (file: File, ownerIndex: number) => {
      try {
        setIsUploading(true);
        const storagePath = await _uploadFile(file, ownerIndex);
        await _updateNewFileUrl(storagePath, ownerIndex);
        setIsUploading(false);
      } catch (e) {
        setIsUploading(false);
        throw e;
      }
    },
  };

  return { handlers, isUploading };
};

/**
 * Exports
 */
export { useUpload };
