/**
 * Imports
 */
import { createSelector } from '@reduxjs/toolkit';

import { VibrantlyState } from 'store/types';

/**
 * Selectors
 */
export const rootSelector = (state: VibrantlyState) => state.integrations;

export const integrationAddedLoadingSelector = createSelector(
  [rootSelector],
  (root) => root.loading,
);
