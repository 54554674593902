import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import { firebaseLogout } from 'firebaseAPI';
import { Logo, Space, Text, VibrantIcon, Icon } from 'components';
import { ROUTES } from 'router/RootRouter';
import { SidebarAccountDetails } from '../Sidebar/components';
import * as Styles from './MobileMenu.styles';
import { terminalCollectionSelector } from 'features/user/redux/selectors';
import { getIsAvailablePage } from 'utils';

const MobileMenu: React.FC = () => {
  const [currentPath, setCurrentPath] = useState('');
  const [visible, setVisible] = useState(false);
  const navigate = useHistory();
  const terminalCollection = useSelector(terminalCollectionSelector);

  useEffect(() => {
    const pathName = location.pathname.split('/')[1];
    setCurrentPath(`/${[pathName]}`);
  }, [location.pathname]);

  const sidebarItems = useMemo(() => {
    return [
      {
        icon: <VibrantIcon icon="overview" />,
        path: ROUTES.home.path,
        text: <Trans i18nKey="Sidebar.links.overview" />,
      },
      {
        icon: <VibrantIcon icon="transaction" />,
        path: ROUTES.transactions.path,
        text: <Trans i18nKey="Sidebar.links.transactions" />,
      },
      {
        icon: <VibrantIcon icon="home" />,
        path: ROUTES.settlements.path,
        text: <Trans i18nKey="Sidebar.links.settlements" />,
      },
      {
        icon: <VibrantIcon icon="nfc" />,
        path: ROUTES.terminals.path,
        text: <Trans i18nKey="Sidebar.links.terminals" />,
      },
    ];
  }, []);

  const handleClose = () => {
    setVisible(false);
  };

  const handleOpen = () => {
    setVisible(true);
  };

  const navigateTo = (path: string) => {
    handleClose();
    navigate.push(path);
  };

  const handleLogout = async () => {
    await firebaseLogout();
    navigateTo(ROUTES.login.path);
  };

  return (
    <div>
      <Styles.Wrapper onClick={handleOpen}>
        <VibrantIcon icon="menu" size={20} />
      </Styles.Wrapper>

      <Styles.Drawer
        visible={visible}
        width="100vw"
        placement="right"
        closable
        closeIcon={<VibrantIcon icon="close" size={20} />}
        onClose={handleClose}
      >
        <Styles.Container>
          <Styles.Header>
            <Styles.LogoWrapper>
              <Logo size={24} single />
            </Styles.LogoWrapper>
          </Styles.Header>
          <Styles.Content>
            <Styles.Top>
              {sidebarItems.map((item) => {
                const isSelected = currentPath === item.path;

                return (
                  <Styles.Link
                    key={item.path}
                    selected={isSelected}
                    onClick={() => navigateTo(item.path)}
                    disabled={
                      terminalCollection?.length
                        ? false
                        : !getIsAvailablePage(item.path)
                    }
                  >
                    <Text size="p1">{item.text}</Text>
                    <Icon
                      icon={item.icon}
                      size={20}
                      color={isSelected ? 'Orange500' : undefined}
                    />
                  </Styles.Link>
                );
              })}
            </Styles.Top>
            <Styles.Bottom>
              <SidebarAccountDetails
                onOpenAccount={() => navigateTo(ROUTES.account.path)}
              />

              <Space size="lg" />

              <Text size="ui3" color="ui">
                <Trans i18nKey="Sidebar.links.guides" />
              </Text>

              <Space size="xs" />

              <Text size="ui3" color="ui">
                <Trans i18nKey="Sidebar.links.contact" />
              </Text>

              <Space size="xs" />

              <Text size="ui3" color="ui" onClick={handleLogout}>
                <Trans i18nKey="_Common.actions.logout_btn" />
              </Text>
            </Styles.Bottom>
          </Styles.Content>
          <Styles.Footer>
            <Styles.Row>
              <Styles.CardIcon>
                <VibrantIcon size={22} icon="visa" />
              </Styles.CardIcon>
              <Space size="xs" vertical />
              <Styles.CardIcon>
                <VibrantIcon size={19} icon="master-card" />
              </Styles.CardIcon>
            </Styles.Row>
          </Styles.Footer>
        </Styles.Container>
      </Styles.Drawer>
    </div>
  );
};

export { MobileMenu };
