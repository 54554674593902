/**
 * Imports
 */
import { useMerchantApi } from 'features/shared/api';
import { merchantCollectionSelector } from 'features/user/redux/selectors';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { Text } from 'components';
import styled from 'styled-components';

/**
 * Styling
 */
const Styledtext = styled(Text)`
  line-height: 2;
`;
/**
 * Types
 */
export interface CurrencyProps {}

/**
 * Currency component
 */
const Currency: React.FC<CurrencyProps> = (_props) => {
  const { merchantCollection } = useMerchantApi();
  if (!merchantCollection?.currency) {
    return null;
  }
  return (
    <Styledtext size="ui3" color="ui">
      {merchantCollection.currency}
    </Styledtext>
  );
};

/**
 * Exports
 */
export { Currency };
