/**
 * Imports
 */
import { createReducer } from '@reduxjs/toolkit';
import { SoftPos } from 'types/softpos';
import { FixedDatesRange } from 'features/shared/types';
import moment, { Moment } from 'moment';
import {
  getTransactions,
  GetTransctionsAction,
  setOverviewDates,
  SetDatesAction,
} from './actions';

/**
 * Types
 */
export interface OverviewState {
  trxnCount: number;
  trxnSum: number;
  trxnAverage: number;
  prevTrxnCount: number;
  prevTrxnSum: number;
  prevTrxnAverage: number;
  sumGrowth: number;
  countGrowth: number;
  averageGrowth: number;
  trxnCurrency: SoftPos.CurrencyISOCode;
  startDate: Moment;
  endDate: Moment;
  prevStartDate: Moment;
  prevEndDate: Moment;
  fixedRange: FixedDatesRange;
}

/**
 * Constants
 */
const INITIAL_STATE: OverviewState = {
  trxnCount: 0,
  trxnSum: 0,
  trxnAverage: 0,
  prevTrxnCount: 0,
  prevTrxnSum: 0,
  prevTrxnAverage: 0,
  sumGrowth: 0,
  countGrowth: 0,
  averageGrowth: 0,
  trxnCurrency: 'DKK', // TODO: Get this from dashboardsettings
  startDate: moment().startOf('day'),
  endDate: moment().endOf('day'),
  prevStartDate: moment().add('-1', 'day').startOf('day'),
  prevEndDate: moment().add('-1', 'day').endOf('day'),
  fixedRange: FixedDatesRange.TODAY,
};

/**
 * `overview` reducer
 */
const overviewReducer = createReducer(INITIAL_STATE, {
  /**
   * SET_DATES
   */
  [setOverviewDates.type]: (state, action: SetDatesAction) => {
    state.startDate = action.payload.startDate;
    state.endDate = action.payload.endDate;
    state.prevStartDate = action.payload.prevStartDate;
    state.prevEndDate = action.payload.prevEndDate;
    state.fixedRange = action.payload.fixedRange;
  },
  /**
   * GET_TRANSACTIONS
   */
  [getTransactions.type]: (state, action: GetTransctionsAction) => {
    state.trxnCount = action.payload.trxnCount;
    state.trxnSum = action.payload.trxnSum;
    state.trxnAverage = action.payload.trxnAverage;
    state.prevTrxnCount = action.payload.prevTrxnCount;
    state.prevTrxnSum = action.payload.prevTrxnSum;
    state.prevTrxnAverage = action.payload.prevTrxnAverage;
    state.sumGrowth = action.payload.sumGrowth;
    state.countGrowth = action.payload.countGrowth;
    state.averageGrowth = action.payload.averageGrowth;
  },
});

/**
 * Exports
 */
export default overviewReducer;
