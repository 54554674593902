/**
 * Imports
 */
import { StepGuide } from 'features/shared';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { ReactComponent as ActivationIllustration } from '../../../../assets/illustrations/illustration-activation-code.svg';
import { ReactComponent as DrawIllustration } from '../../../../assets/illustrations/illustration-drawing.svg';
import { ReactComponent as PatternIllustration } from '../../../../assets/illustrations/illustration-pattern-1.svg';

const { IntroStep, Step } = StepGuide;
const { Link } = Step;

/**
 * Types
 */
export interface TerminalGuideProps {}

/**
 * TerminalGuide component
 */
const TerminalGuide: React.FC<TerminalGuideProps> = (_props) => {
  return (
    <StepGuide>
      <IntroStep
        heading={'Heading main'}
        subheading
        noBorder
        pattern={PatternIllustration}
        patternLeft
      />
      <Step text={'Heading 1'} image={DrawIllustration} wide>
        <Link
          href="https://www.vibrant.io/terminal-guide"
          target="_blnak"
          text={'Link 1'}
        />
      </Step>

      <Step text={'Heading 2'} image={ActivationIllustration} wide>
        <Link
          href="https://www.vibrant.io/terminal-guide"
          target="_blank"
          text={'Link 2'}
        />
      </Step>
    </StepGuide>
  );
};

/**
 * Exports
 */
export { TerminalGuide };
