/**
 * Imports
 */
import * as React from 'react';

import { Text, Box, Button } from 'components';
import { Form, Upload } from 'antd';
import { useUpload } from 'features/user/api';
import { ALLOWED_IMAGE_TYPES } from 'utils';
import { UploadFile } from 'antd/lib/upload/interface';
import { useTranslation } from 'react-i18next';
const { Dragger } = Upload;
/**
 * Types
 */
export interface UploadOwnerDocumentsFormProps {
  ownerIndex: number;
}
export type UploadForm = {
  file: { file: File; fileList: UploadFile[] };
};
/**
 * UploadOwnerDocumentsForm component
 */
const UploadOwnerDocumentsForm: React.FC<UploadOwnerDocumentsFormProps> = (
  props,
) => {
  const { t } = useTranslation();
  const [formIsValid, setFormIsValid] = React.useState(false);
  const {
    handlers: { onUpload },
    isUploading,
  } = useUpload();

  const _onFinish = async (data: UploadForm) => {
    try {
      await onUpload(data.file.file, props.ownerIndex);
    } catch (e) {
      throw e;
    }
  };

  const _onChange = (data: UploadForm) => {
    if (data?.file?.fileList?.length > 0) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  };

  return (
    <Box>
      <Form
        name="upload_new_document"
        onFinish={_onFinish}
        onValuesChange={_onChange}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item name="file">
          <Dragger accept={`${ALLOWED_IMAGE_TYPES}`} beforeUpload={() => false}>
            {t('Account.upload_document.upload_text')}
          </Dragger>
        </Form.Item>
        <Box mt="16px">
          <Form.Item>
            <Button
              block
              disabled={!formIsValid}
              loading={isUploading}
              htmlType="submit"
              type="primary"
            >
              {t('Account.upload_document.upload_btn')}
            </Button>
          </Form.Item>
        </Box>
      </Form>
    </Box>
  );
};

/**
 * Exports
 */
export { UploadOwnerDocumentsForm };
