/**
 * Imports
 */
import overview from './overview';
import settlements from './settlements';
import shared from './shared';
import sidebar from './navigation';
import transactions from './transactions';
import integrations from './integrations';
import user from './user';
import products from './products';

/**
 * Exports
 */
export default {
  overview,
  user,
  sidebar,
  transactions,
  settlements,
  shared,
  integrations,
  products,
};
