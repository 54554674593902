import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import React from 'react';

export interface FilePreviewProps {
  downloadUrl: string;
}

export const FilePreview: React.FC<FilePreviewProps> = ({ downloadUrl }) => {
  return !downloadUrl.includes('.pdf') ? (
    <Avatar
      src={downloadUrl}
      icon={<UserOutlined />}
      size="large"
      shape="square"
    />
  ) : (
    <Avatar size="large" shape="square">
      PDF
    </Avatar>
  );
};
