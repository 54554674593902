import axios from 'axios';
import { PROJECT_API_URL } from 'config';
import { getIdToken } from 'firebaseAPI';
import { VibrantAPI } from 'types/vibrant';
import { captureError } from 'utils/error-logging';

/**
 * Create dynamic link
 */
export const createDynamicLink = async (
  userId: string,
  tid: string,
): Promise<VibrantAPI.GetDynamicLinkResponse | undefined> => {
  const idToken = await getIdToken();
  try {
    const response = (await axios.post(
      `${PROJECT_API_URL}/api/v2/terminals/${tid}/create_dynamic_link`,
      {
        userId,
      },
      {
        headers: { token: idToken },
      },
    )) as VibrantAPI.GetDynamicLinkResponse;
    return response;
  } catch (error) {
    captureError(`Failed to create dynamic link`, error);
    return undefined;
  }
};
