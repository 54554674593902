/**
 * Imports
 */
import { Text } from 'components';
import { useKeyNumbers } from 'features/overview';
import {
  averageGrowthSelector,
  countGrowthSelector,
  sumGrowthSelector,
  trxnAverageSelector,
  trxnCountSelector,
  trxnSumSelector,
} from 'features/overview/redux/selectors';
import { FixedDatePicker, KeyNumberCard } from 'features/shared';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

/**
 * Styling
 */
const Root = styled.div`
  display: grid;
`;
const CardHeader = styled.div`
  padding: 48px 0 24px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 600px) {
    padding: 0 0 0;
  }
`;
const CardRow = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 24px;

  @media (max-width: 600px) {
    padding: 24px 0 12px;
    max-width: calc(100vw - 48px);
    grid-template-columns: auto;
  }
`;

/**
 * Types
 */
export interface KeyNumbersProps {}

/**
 * KeyNumbers component
 */
const KeyNumbers: React.FC<KeyNumbersProps> = (_props) => {
  const count = useSelector(trxnCountSelector);
  const sum = useSelector(trxnSumSelector);
  const average = useSelector(trxnAverageSelector);
  const sumGrowth = useSelector(sumGrowthSelector);
  const countGrowth = useSelector(countGrowthSelector);
  const averageGrowth = useSelector(averageGrowthSelector);

  const { isFecthing } = useKeyNumbers();
  return (
    <Root>
      <CardHeader>
        <Text size="ui2" bold>
          <Trans i18nKey="_Common.key_numbers.title" />
        </Text>
        <FixedDatePicker type="keynumbers" comparision />
      </CardHeader>
      <CardRow>
        <KeyNumberCard
          title={<Trans i18nKey="_Common.key_numbers.revenue" />}
          number={sum}
          currency
          loading={isFecthing}
          growth={sumGrowth}
        />
        <KeyNumberCard
          title={<Trans i18nKey="_Common.key_numbers.transactions" />}
          number={count}
          loading={isFecthing}
          growth={countGrowth}
        />
        <KeyNumberCard
          title={<Trans i18nKey="_Common.key_numbers.average_order" />}
          number={average}
          currency
          loading={isFecthing}
          growth={averageGrowth}
        />
      </CardRow>
    </Root>
  );
};

/**
 * Exports
 */
export { KeyNumbers };
