/**
 * Imports
 */
import * as React from 'react';
import styled from 'styled-components';
import { IntroStep } from './IntroStep';
import { Step } from './Step';

/**
 * Styling
 */
const Root = styled.div`
  margin: -48px -48px 0 -48px;
  background-color: ${({ theme }) => theme.colors.Shades.Orange300};
  display: flex;

  @media (max-width: 600px) {
    display: none;
  }
`;

/**
 * Types
 */
interface StepGuideSubComponents {
  IntroStep: typeof IntroStep;
  Step: typeof Step;
}

export interface StepGuideProps {}

/**
 * StepGuide component
 */
const StepGuide: React.FC<StepGuideProps> & StepGuideSubComponents = ({
  children,
}) => {
  return <Root>{children}</Root>;
};

StepGuide.Step = Step;
StepGuide.IntroStep = IntroStep;

/**
 * Exports
 */
export { StepGuide };
