/**
 * Imports
 */
import { BaseLayout } from 'features/navigation';
import {
  CurrentBalance,
  SettlementsExportSelect,
  SettlementsTable,
} from 'features/settlements';
import React, { useRef } from 'react';

/**
 * Types
 */
export interface SettlementsPageProps {}

/**
 * SettlementsPage component
 */
const SettlementsPage: React.FC<SettlementsPageProps> = () => {
  const settlementsTableRef = useRef<HTMLDivElement | null>(null);

  return (
    <BaseLayout
      options={[
        <SettlementsExportSelect
          key="stlmn-export-select"
          printElementRef={settlementsTableRef}
        />,
        // <FixedDatePicker key="stlmn-date-picker" clearable type="settlement" />,
      ]}
    >
      <CurrentBalance />
      <SettlementsTable ref={settlementsTableRef} />
    </BaseLayout>
  );
};

/**
 * Exports
 */
export { SettlementsPage };
