import React, { FC, useMemo, useRef, useState } from 'react';
import { Text } from 'components';
import styled, { CSSProperties } from 'styled-components';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { ControllerRenderProps } from 'react-hook-form';
import { ProductData } from 'features/products/types';

export type VatSliderProps = {
  values: string[];
  field: ControllerRenderProps<ProductData, 'vat'>;
};

const Container = styled.div``;

const Slider = styled.div<{ $vatItems: number }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.colors.Border.InputBorder};
  border-radius: 16px;
  padding: 4px;
  margin-top: 8px;
  position: relative;
  z-index: 5;

  .slider-inner {
    width: 100%;
    height: 50px;
    position: relative;

    & > div:first-child {
      width: 100%;
      height: 100%;
      position: relative;
    }
  }

  .vat-item {
    width: ${({ $vatItems }) => `${100 / $vatItems}%`};
    text-align: center;
    font-weight: 600;
    padding: 12px 0;
    background-color: transparent;
    z-index: 5;
    transition: color 0.2s ease;

    &.active {
      color: ${({ theme }) => theme.colors.Text.White};
    }
  }

  .orange-thingy {
    position: absolute;
    width: ${({ $vatItems }) => `${100 / $vatItems}%`};
    height: 100%;
    top: 0;
    left: 0;
    background-color: ${({ theme }) => theme.colors.Background.Brand};
    z-index: 3;
    border-radius: 16px;
    border: 4px solid ${({ theme }) => theme.colors.Background.White};
    transition: left 0.2s ease;
    box-sizing: border-box;
  }
`;

export const VatSlider: FC<VatSliderProps> = ({
  values,
  field: { onChange },
}) => {
  const { t } = useTranslation();
  const [activeId, setId] = useState<number>(0);
  const sliderRef = useRef<HTMLSpanElement>(null);

  const style: CSSProperties = useMemo(() => {
    const elem = sliderRef.current;
    if (elem) {
      const offsetWidth = elem.offsetWidth;
      return {
        left: `${activeId * offsetWidth}px`,
        width: `${offsetWidth + 6}px`,
      };
    }
    return {};
  }, [activeId]);

  const handleClick = (id: number) => () => {
    setId(id);
    onChange(values[id]);
  };

  return (
    <Container>
      <Text size="ui3" color={'ui'}>
        {t('Products.Common.vat')}
      </Text>
      <Slider $vatItems={values.length}>
        <span className="orange-thingy" style={style} />
        {values.map((value, index) => (
          <span
            ref={index === 0 ? sliderRef : null}
            className={clsx('vat-item', index === activeId && 'active')}
            onClick={handleClick(index)}
            key={index}
          >
            {value}%
          </span>
        ))}
      </Slider>
    </Container>
  );
};
