/**
 * Imports
 */
import { GlobalOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import { Icon, Text } from 'components';
import { BaseLayout } from 'features/navigation';
import { showAddOwnerModal } from 'features/navigation/redux/actions';
import { useMerchantApi } from 'features/shared';
import {
  AddOwnerModal,
  LanguageSelector,
  UploadPhotoForm,
} from 'features/user/components';
import { SettingsForm } from 'features/user/components/SettingsForm';
import { userFirstnameSelector } from 'features/user/redux/selectors';
import React from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import * as Styles from './AccountPage.styles';

const Row = styled.div`
  padding: 24px 0px;
  display: flex;
  align-items: center;
  flex: 1;
  width: 100%;

  .anticon {
    margin-right: 12px;
  }
`;
const NonPaddedRow = styled(Row)`
  padding: 0px;
`;
const FullRow = styled(Row)`
  justify-content: space-between;
`;
const RowBordered = styled(Row)`
  border-top: 1px solid ${(props) => props.theme.colors.Background.Primary};
  border-bottom: 1px solid ${(props) => props.theme.colors.Background.Primary};
`;
const StyledCollapse = styled(Collapse)`
  width: 100%;
`;
const Group = styled.div`
  margin: 24px 0;
`;
const AddOwnerButton = styled.div`
  cursor: pointer;
`;

/**
 * Types
 */
export interface AccountPageProps {}

/**
 * AccountPage component
 */
const AccountPage: React.FC = () => {
  const userFirstName = useSelector(userFirstnameSelector);
  const { merchantCollection } = useMerchantApi();
  const dispatch = useDispatch();

  const _showOwnerModal = () => {
    dispatch(showAddOwnerModal({}));
  };

  return (
    <BaseLayout disableBreadcrumb>
      <Styles.Container>
        <Text size="ui2" bold>
          <Trans i18nKey="Account.page_name" />
        </Text>
        <Styles.TextContainer>
          {userFirstName != null && (
            <Text size="ui1" bold>
              <Trans
                i18nKey="Account.header.hello_message"
                values={{ name: userFirstName }}
              />
            </Text>
          )}

          <Text size="ui1">
            <Trans i18nKey="Account.header.help_message" />
          </Text>
        </Styles.TextContainer>
        {/* Owners */}
        <Group>
          <AddOwnerModal />
          <FullRow>
            <NonPaddedRow>
              <Icon icon={<UserOutlined />} color="Orange500" />
              <Text size="ui3" bold>
                <Trans i18nKey="Account.photo_ids.label" />
              </Text>
            </NonPaddedRow>
            <AddOwnerButton onClick={_showOwnerModal}>
              <Icon icon={<PlusOutlined />} color="Orange500" />
            </AddOwnerButton>
          </FullRow>
          <RowBordered>
            <StyledCollapse>
              {merchantCollection?.ownerData?.map((owner, ownerIndex) => (
                <Collapse.Panel key={owner.name} header={owner.name}>
                  <UploadPhotoForm
                    owner={owner}
                    ownerIndex={ownerIndex}
                    clearhausKYCdone={merchantCollection.clearhausKYCdone}
                  />
                </Collapse.Panel>
              ))}
            </StyledCollapse>
          </RowBordered>
        </Group>
        <SettingsForm data={merchantCollection} onSave={() => {}} />
        {/* Language */}
        <Group>
          <Row>
            <Icon icon={<GlobalOutlined />} color="Orange500" />
            <Text size="ui3" bold>
              <Trans i18nKey="_Common.change_language.title" />
            </Text>
          </Row>
          <RowBordered>
            <LanguageSelector />
          </RowBordered>
        </Group>
      </Styles.Container>
    </BaseLayout>
  );
};

/**
 * Exports
 */
export { AccountPage };
