/**
 * Imports
 */
import React from 'react';
import { Trans } from 'react-i18next';
import { LoginForm } from 'features/user/components';
import { AuthLayout } from 'features/navigation';

/**
 * LoginPage component
 */
const LoginPage: React.FC = () => {
  return (
    <AuthLayout
      title={<Trans i18nKey="Auth.title" />}
      subtitle={<Trans i18nKey="Auth.subtitle" />}
    >
      <LoginForm />
    </AuthLayout>
  );
};

/**
 * Exports
 */
export { LoginPage };
