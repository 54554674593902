/**
 * Imports
 */
import { Menu as AntdMenu } from 'antd';
import styled from 'styled-components';

/**
 * Menu override style
 */
const Menu = styled(AntdMenu)`
  .ant-dropdown-menu-item {
    color: ${({ theme }) => theme.colors.Text.Heading};
    padding: 8px;
  }
  .ant-dropdown-menu-item:hover {
    background-color: ${({ theme }) => theme.colors.Background.Primary};
  }
  && {
    &.ant-dropdown-menu {
      padding: 8px;
    }
  }
`;

/**
 * Exports
 */
export { Menu };
