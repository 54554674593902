import React, { useRef } from 'react';
import { CSVLink } from 'react-csv';
import { Trans } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';

import { Dropdown, Menu, VibrantIcon } from 'components';
import * as Styles from './ExportSelect.styles';
import { utils, write, writeFile } from 'xlsx';

enum ExportFormatEnum {
  CSV = 'csv',
  PRINT = 'print',
  EXCEL = 'excel',
}

const pageStyle = `
@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    display: block;
    page-break-before: auto;
  }
}

@page {
  size: auto;
  margin: 20mm;
}
`;

export type ExportSelectProps = {
  filename: string;
  headers: { label: string; key: string }[];
  data: object[];
  printElementRef: React.MutableRefObject<HTMLDivElement | null>;
};

const ExportSelect: React.FC<ExportSelectProps> = (props) => {
  const csvLinkRef = useRef<any | null>(null);

  const handlePrint = useReactToPrint({
    content: () => props.printElementRef.current,
    removeAfterPrint: true,
    pageStyle,
  });

  const handleMenuClick = (e: any) => {
    if (e?.key === ExportFormatEnum.CSV) {
      handleDownloadCSV();
      return;
    }

    if (e?.key === ExportFormatEnum.PRINT) {
      handlePrint?.();
      return;
    }

    if (e?.key === ExportFormatEnum.EXCEL) {
      handleDownloadExcel?.();
      return;
    }
  };

  const handleDownloadCSV = () => {
    if (csvLinkRef.current == null) {
      return null;
    }

    csvLinkRef.current.link?.click?.();
  };

  const handleDownloadExcel = () => {
    const header = [props.headers.map((x) => x.label)];

    const ws = utils.book_new();
    utils.sheet_add_aoa(ws, header);
    utils.sheet_add_json(ws, props.data, { origin: 'A2', skipHeader: true });

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    write(wb, { bookType: 'xlsx', type: 'array' });
    writeFile(wb, `${props.filename}.xlsx`);
  };

  const csvFileName = `${props.filename}.csv`;

  return (
    <>
      <Dropdown
        overlay={
          <Menu onClick={handleMenuClick}>
            <Menu.Item key={ExportFormatEnum.CSV}>
              <Trans i18nKey="_Common.actions.export_csv" />
            </Menu.Item>
            <Menu.Item key={ExportFormatEnum.EXCEL}>
              <Trans i18nKey="_Common.actions.export_excel" />
            </Menu.Item>
            <Menu.Item key={ExportFormatEnum.PRINT}>
              <Trans i18nKey="_Common.actions.print" />
            </Menu.Item>
          </Menu>
        }
      >
        <Styles.SelectButton>
          <Styles.Selection>
            <Styles.StyledText size="ui2">
              <Trans i18nKey="_Common.actions.export" />
            </Styles.StyledText>
            <VibrantIcon icon="chevron-down" size={10} />
          </Styles.Selection>
        </Styles.SelectButton>
      </Dropdown>

      <CSVLink
        ref={csvLinkRef}
        filename={csvFileName}
        data={props.data}
        headers={props.headers}
        style={{ display: 'none' }}
      />
    </>
  );
};

export { ExportSelect };
