/**
 * Imports
 */
import { Text } from 'components';
import { KeyNumberCard } from 'features/shared';
import { useTrxnKeyNumbers } from 'features/transactions';
import {
  trxnApprovedCountSelector,
  trxnAverageSelector,
  trxnSumSelector,
} from 'features/transactions/redux/selectors';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

/**
 * Styling
 */
const Root = styled.div``;
const CardHeader = styled.div`
  padding: 48px 0 24px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 600px) {
    padding: 32px 0 0;
  }
`;
const CardRow = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 24px;

  @media (max-width: 600px) {
    padding: 24px 0 12px;
    max-width: calc(100vw - 48px);
    grid-template-columns: auto;
  }
`;
/**
 * Types
 */
export interface TrxnKeyNumbersProps {}

/**
 * TrxnKeyNumbers component
 */
const TrxnKeyNumbers: React.FC<TrxnKeyNumbersProps> = (_props) => {
  const { isFecthing } = useTrxnKeyNumbers();
  const sum = useSelector(trxnSumSelector);
  const count = useSelector(trxnApprovedCountSelector);
  const average = useSelector(trxnAverageSelector);

  return (
    <Root>
      <CardHeader>
        <Text size="ui2" bold>
          <Trans i18nKey="_Common.key_numbers.title" />
        </Text>
      </CardHeader>
      <CardRow>
        <KeyNumberCard
          title={<Trans i18nKey="_Common.key_numbers.revenue" />}
          number={sum}
          currency
          loading={isFecthing}
        />
        <KeyNumberCard
          title={<Trans i18nKey="_Common.key_numbers.transactions" />}
          number={count}
          loading={isFecthing}
        />
        <KeyNumberCard
          title={<Trans i18nKey="_Common.key_numbers.average_order" />}
          number={average}
          currency
          loading={isFecthing}
        />
      </CardRow>
    </Root>
  );
};

/**
 * Exports
 */
export { TrxnKeyNumbers };
