/**
 * Imports
 */
import moment, { Moment } from 'moment';
import { FixedDatesRange } from '../types';

/**
 * getDateRange
 */
export const getDateRange = (
  type: string,
): {
  startDate: Moment;
  endDate: Moment;
} => {
  if (type === FixedDatesRange.TODAY) {
    return {
      startDate: moment().startOf('day'),
      endDate: moment().endOf('day'),
    };
  }
  if (type === FixedDatesRange.YESTERDAY) {
    return {
      startDate: moment().add(-1, 'day').startOf('day'),
      endDate: moment().add(-1, 'day').endOf('day'),
    };
  }
  if (type === FixedDatesRange.LAST_WEEK) {
    return {
      startDate: moment().add('-1', 'week').startOf('week'),
      endDate: moment().add('-1', 'week').endOf('week'),
    };
  }
  if (type === FixedDatesRange.MONTH_TO_DATE) {
    return {
      startDate: moment().startOf('month').startOf('day'),
      endDate: moment().endOf('day'),
    };
  }
  if (type === FixedDatesRange.LAST_MONTH) {
    return {
      startDate: moment().add(-1, 'month').startOf('month').startOf('day'),
      endDate: moment().add(-1, 'month').endOf('month').endOf('day'),
    };
  }
  if (type === FixedDatesRange.YEAR_TO_DATE) {
    return {
      startDate: moment().startOf('year').startOf('day'),
      endDate: moment().endOf('day'),
    };
  }
  if (type === FixedDatesRange.LAST_YEAR) {
    return {
      startDate: moment().add(-1, 'year').startOf('year').startOf('day'),
      endDate: moment().add(-1, 'year').endOf('year').endOf('day'),
    };
  } else {
    return {
      startDate: moment().startOf('day'),
      endDate: moment().endOf('day'),
    };
  }
};
/**
 * getCompareDateRange
 */
export const getCompareDateRange = (
  type: string,
): {
  startDate: Moment;
  endDate: Moment;
  prevStartDate: Moment;
  prevEndDate: Moment;
} => {
  if (type === FixedDatesRange.TODAY) {
    return {
      startDate: moment().startOf('day'),
      endDate: moment().endOf('day'),
      prevStartDate: moment().add(-1, 'day').startOf('day'),
      prevEndDate: moment().add(-1, 'day').endOf('day'),
    };
  }
  if (type === FixedDatesRange.YESTERDAY) {
    return {
      startDate: moment().add(-1, 'day').startOf('day'),
      endDate: moment().add(-1, 'day').endOf('day'),
      prevStartDate: moment().add(-2, 'day').startOf('day'),
      prevEndDate: moment().add(-2, 'day').endOf('day'),
    };
  }
  if (type === FixedDatesRange.LAST_WEEK) {
    return {
      startDate: moment().add('-1', 'week').startOf('week'),
      endDate: moment().add('-1', 'week').endOf('week'),
      prevStartDate: moment().add('-2', 'week').startOf('week'),
      prevEndDate: moment().add('-2', 'week').endOf('week'),
    };
  }
  if (type === FixedDatesRange.MONTH_TO_DATE) {
    return {
      startDate: moment().startOf('month').startOf('day'),
      endDate: moment().endOf('day'),
      prevStartDate: moment()
        .startOf('month')
        .startOf('day')
        .add('-1', 'month'),
      prevEndDate: moment().endOf('day').add('-1', 'month'),
    };
  }
  if (type === FixedDatesRange.LAST_MONTH) {
    return {
      startDate: moment().add(-1, 'month').startOf('month').startOf('day'),
      endDate: moment().add(-1, 'month').endOf('month').endOf('day'),
      prevStartDate: moment().add(-2, 'month').startOf('month').startOf('day'),
      prevEndDate: moment().add(-2, 'month').endOf('month').endOf('day'),
    };
  } else {
    return {
      startDate: moment().startOf('day'),
      endDate: moment().endOf('day'),
      prevStartDate: moment().add(-1, 'day').startOf('day'),
      prevEndDate: moment().add(-1, 'day').endOf('day'),
    };
  }
};
