/**
 * Imports
 */
import { SoftPos } from 'types/softpos';
import {
  merchantCollectionSelector,
  userSelector,
} from 'features/user/redux/selectors';
import { FirebaseCollectionsType } from 'firebaseAPI';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import type { Firebase } from 'types/firebase';

/**
 * Types
 */
export type MerchantApiAPIHandlers = {
  onMerchantApi: () => Promise<void>;
};
export type MerchantApiAPIType = {
  handlers: MerchantApiAPIHandlers;
  merchantCollection: Firebase.Merchant;
};

/**
 * useMerchantApi
 */
const useMerchantApi = (): MerchantApiAPIType => {
  const user = useSelector(userSelector);
  /* Firestore */
  useFirestoreConnect([
    {
      collection: FirebaseCollectionsType.MERCHANTS,
      storeAs: FirebaseCollectionsType.MERCHANTS,
      doc: user.meta?.merchantId,
    },
  ]);
  const merchantCollection = useSelector(merchantCollectionSelector);

  const handlers: MerchantApiAPIHandlers = {
    /**
     * onMerchantApi
     */
    onMerchantApi: async () => {
      console.log('onMerchantApi');
    },
  };

  return { handlers, merchantCollection };
};

/**
 * Exports
 */
export { useMerchantApi };
