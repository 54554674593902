import React from 'react';
import { Modal } from 'antd';
import {
  updateEconomicsAccounts,
  updateEconomicsEnabled,
  getEconomicsAccount,
} from '../utils/economic-api';
import { useHistory } from 'react-router-dom';
import EconomicContent from './EconomicContent';

export interface EconomicsModalProps {
  showModal: boolean;
  merchantId: string;
}

export type StepKey = 'deposit' | 'bank' | 'revenue' | 'fee';

export type EconomicModel = {
  deposit: number;
  bank: number;
  revenue: number;
  fee: number;
  stepKey: StepKey;
};

const EconomicsModal: React.FC<EconomicsModalProps> = (props) => {
  const [economic, setEconomic] = React.useState<EconomicModel>({
    deposit: 5600,
    bank: 5820,
    revenue: 1010,
    fee: 3628,
    stepKey: 'deposit',
  });
  const history = useHistory();

  const handleFinish = async () => {
    await updateEconomicsAccounts(props.merchantId, {
      bankAccount: economic.bank,
      depositAccount: economic.deposit,
      feeAccount: economic.fee,
      revenueAccountWithVat: economic.revenue,
    });
    await updateEconomicsEnabled(props.merchantId, true);
  };
  const handleCancel = () => {
    history.replace('/integrations');
  };
  const initEconomics = async () => {
    const em = await getEconomicsAccount(props.merchantId);
    if (em) {
      setEconomic({
        ...{
          deposit: em.settings.depositAccount,
          bank: em.settings.bankAccount,
          revenue: em.settings.revenueAccountWithVat,
          fee: em.settings.feeAccount,
        },
        ...{ stepKey: economic.stepKey },
      });
    }
  };
  React.useEffect(() => {
    initEconomics();
  }, []);

  return (
    <>
      <Modal
        bodyStyle={{ padding: '0px' }}
        width={448}
        footer={null}
        onCancel={handleCancel}
        visible={props.showModal}
      >
        <EconomicContent
          merchantId={props.merchantId}
          economic={economic}
          setEconomicState={setEconomic}
          handleFinish={handleFinish}
          closeModal={handleCancel}
        />
      </Modal>
    </>
  );
};

export default EconomicsModal;
