/**
 * Imports
 */
import { Button } from 'components';
import * as React from 'react';
import { Trans } from 'react-i18next';

import styled from 'styled-components';

/**
 * Styling
 */
const Root = styled.div`
  padding: 24px;
`;

/**
 * Types
 */
export interface RefundButtonProps {}

/**
 * RefundButton component
 */
const RefundButton: React.FC<RefundButtonProps> = (props) => {
  const _refund = () => {
    console.log('_refund');
  };

  return (
    <Button color="danger" onClick={_refund}>
      <Trans i18nKey="TransactionsDetails.transaction_optionsbar.refund_btn" />
    </Button>
  );
};

/**
 * Exports
 */
export { RefundButton };
