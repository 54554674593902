/**
 * Imports
 */
import { Card as AntdCard } from 'antd';
import styled from 'styled-components';
import * as React from 'react';
import { CardProps as AntdCardProps } from 'antd/lib/card';

/**
 * Card ovberride style
 */

const StyledCard = styled(AntdCard)`
  && {
    .ant-card-head {
      color: ${({ theme }) => theme.colors.Text.Heading};
      border-bottom: none;
      font-size: 17px;
      line-height: 21.76px;
    }
    .ant-card-body {
      padding: 24px;
      display: flex;
      justify-content: space-between;

      @media (max-width: 600px) {
        padding: 12px 24px 24px;
      }
    }
    .ant-card-body::before {
      display: none;
    }
    .ant-card-body::after {
      display: none;
    }
  }
`;

/**
 * Types
 */
export type CardProps = AntdCardProps & Record<string, unknown>;

/**
 * Card component
 */
const Card: React.FC<CardProps> = ({ ...rest }) => {
  return <StyledCard {...rest} />;
};

export { Card };
