import { getIdToken } from 'firebaseAPI';
import axios from 'axios';
import { PROJECT_API_URL } from 'config';
import { captureError } from 'utils/error-logging';
import { Firebase } from 'types/firebase';

const confirmEmail = async (token: string): Promise<string | null> => {
  try {
    const response = await axios.post(
      `${PROJECT_API_URL}/public/v1/merchant/confirmEmail`,
      {
        emailToken: token,
      },
    );

    return response.data.result.token;
  } catch (error) {
    captureError(error.message, error);
    return null;
  }
};

const getMerchant = async (): Promise<Firebase.Merchant | null> => {
  const idToken = await getIdToken();
  try {
    const response = await axios.get(`${PROJECT_API_URL}/api/v1/merchant`, {
      headers: { token: idToken },
    });
    return response.data.result as Firebase.Merchant;
  } catch (error) {
    captureError(error.message, error);
    return null;
  }
};

const getMerchants = async (): Promise<Firebase.Merchant[] | null> => {
  const idToken = await getIdToken();
  try {
    const response = await axios.get(
      `${PROJECT_API_URL}/api/v1/owner/merchants`,
      {
        headers: { token: idToken },
      },
    );
    return response.data.result as Firebase.Merchant[];
  } catch (error) {
    captureError(error.message, error);
    return null;
  }
};

export { getMerchant, getMerchants, confirmEmail };
