/**
 * Imports
 */
import * as React from 'react';

import { Text } from 'components';
import { Switch as AntdSwitch } from 'antd';
import { SwitchProps as AntdSwitchProps } from 'antd/lib/switch';
import styled from 'styled-components';

/**
 * Styling
 */
const Root = styled(AntdSwitch)`
  && {
    background-color: ${({ theme }) => theme.colors.Shades.Grey300};
  }
  &.ant-switch-checked {
    background-color: ${({ theme }) => theme.colors.Shades.Green500};
  }
`;

/**
 * Types
 */
export type SwitcProps = AntdSwitchProps;

/**
 * Switch component
 */
const Switch: React.FC<SwitcProps> = ({ ...rest }) => {
  return <Root {...rest} />;
};

/**
 * Exports
 */
export { Switch };
