import React from 'react';

import * as Styles from './FormGroup.styles';

export type FormGroupProps = {
  icon: React.ReactNode;
  title: () => string;
  isDisabled?: boolean;
};

const FormGroup: React.FC<FormGroupProps> = (props) => {
  return (
    <Styles.Container isDisabled={Boolean(props.isDisabled)}>
      <Styles.TitleContainer>
        {props.icon}

        <Styles.TitleText size="ui3" bold>
          {props.title()}
        </Styles.TitleText>
      </Styles.TitleContainer>

      <Styles.ContentContainer>{props.children}</Styles.ContentContainer>
    </Styles.Container>
  );
};

export { FormGroup };
