/**
 * Imports
 */
import {
  List,
  VibrantIcon,
  Text,
  Snackbar,
  messageSuccess,
  messageError,
} from 'components';
import { useRequestRefund } from 'features/transactions/api';
import {
  isSupporterSelector,
  merchantCollectionSelector,
} from 'features/user/redux/selectors';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import styled from 'styled-components';
import { VibrantColors } from 'theme';
import { SoftPos } from 'types/softpos';
import { captureError } from 'utils/error-logging';

/**
 * Styling
 */
const Root = styled.div``;

/**
 * Types
 */
export interface TransactionOptionsMenuProps {
  printContent: any;
  trxnItem: SoftPos.Trxn;
}

const COLLECTORSCAGE_HACK_IDS = [
  '000000030002020',
  '000000030000030',
  '000000030000148',
];

/**
 * TransactionOptionsMenu component
 */
const TransactionOptionsMenu: React.FC<TransactionOptionsMenuProps> = ({
  printContent,
  trxnItem,
}) => {
  const [refundStatus, setRefundStatus] = React.useState<boolean | null>(null);
  const merchant = useSelector(merchantCollectionSelector);
  const { onHandleRequestRefund, onRequestRefundStatus } = useRequestRefund();
  /* CollectorsCage hack */
  const isColelctorsCage = COLLECTORSCAGE_HACK_IDS.includes(
    merchant.merchantId,
  );

  const reactToPrintContent = React.useCallback(() => {
    return printContent?.current;
  }, [printContent?.current]);

  const _handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: 'Receipt',
    removeAfterPrint: true,
  });

  const _requestRefund = async () => {
    if (refundStatus !== null) {
      return;
    }
    try {
      if (
        window.confirm(
          'Are you sure you want to request a refund for this transaction?',
        )
      ) {
        await onHandleRequestRefund(trxnItem);
        await _getRefundStatus();
        messageSuccess({ content: 'Refund successfully requested' });
      }
    } catch (error) {
      captureError('Failed to request refund', error);
      messageError({ content: 'Failed to request refund' });
    }
  };

  const _getRefundStatus = async () => {
    // Get status - set status
    const status = await onRequestRefundStatus(trxnItem);
    console.log('_getRefundStatus', status);
    setRefundStatus(status ?? null);
  };

  React.useEffect(() => {
    _getRefundStatus();
  }, []);

  return (
    <Root>
      <List bordered>
        <List.Item onClick={_handlePrint}>
          <List.Item.Meta
            avatar={<VibrantIcon icon="print" color="Grey300" />}
            title={
              <Trans i18nKey="TransactionsDetails.transaction_options_menu.print_btn" />
            }
          />
        </List.Item>
        {isColelctorsCage && (
          <List.Item
            onClick={_requestRefund}
            style={{
              backgroundColor:
                refundStatus === null
                  ? 'white'
                  : VibrantColors.Background.Disabled,
            }}
          >
            <List.Item.Meta
              style={{
                backgroundColor:
                  refundStatus === null
                    ? 'white'
                    : VibrantColors.Background.Disabled,
              }}
              avatar={<VibrantIcon icon="refund" color="Grey300" />}
              title={
                refundStatus === null ? (
                  <Trans i18nKey="TransactionsDetails.transaction_options_menu.refund_btn" />
                ) : !refundStatus ? (
                  <Trans i18nKey="TransactionsDetails.transaction_options_menu.refund_requested_status" />
                ) : (
                  <Trans i18nKey="TransactionsDetails.transaction_options_menu.refund_status_done" />
                )
              }
            />
          </List.Item>
        )}
      </List>
    </Root>
  );
};

/**
 * Exports
 */
export { TransactionOptionsMenu };
