/**
 * Imports
 */
import Icon from '@ant-design/icons';
import * as React from 'react';

/**
 * Types
 */
export interface LogoProps {
  single?: boolean;
}

/**
 * Logo in SVG
 */
const LogoSvg = () => (
  // <svg
  //   width="100%"
  //   height="100%"
  //   viewBox="0 0 166 40"
  //   fill="none"
  //   xmlns="http://www.w3.org/2000/svg"
  // >
  //   <g clipPath="url(#clip0)">
  //     <path
  //       d="M37.9277 11.6602H42.7115V39.4774H37.9277V11.6602Z"
  //       fill="currentColor"
  //     />
  //     <path
  //       d="M8.57745 22.0924H13.3613L21.5836 39.4786H16.7997L8.57745 22.0924Z"
  //       fill="currentColor"
  //     />
  //     <path
  //       d="M22.7796 39.4774H17.9957L29.9554 11.6602H34.7392L22.7796 39.4774Z"
  //       fill="currentColor"
  //     />
  //     <path
  //       d="M53.9596 35.3583H53.7323V39.4774H48.9485V0H53.7323V15.7792H53.9596C55.4976 13.1685 58.4313 11.1388 62.018 11.1388C70.2187 11.1388 75.0313 16.548 75.0875 25.3087C75.0301 34.8808 69.6494 40 62.018 40C58.6418 40 55.4976 37.9691 53.9596 35.3583ZM70.2474 25.3087C70.2474 19.3915 66.516 15.4893 61.9331 15.4893C57.4315 15.4893 53.6785 19.4487 53.6785 25.3087C53.6785 31.2259 57.2329 35.6495 61.9331 35.6495C66.5949 35.6495 70.2474 31.2832 70.2474 25.3087Z"
  //       fill="currentColor"
  //     />
  //     <path
  //       d="M79.735 11.6602H84.5188V16.4749C85.7148 13.1685 88.6114 11.1376 92.2077 11.1376C93.8258 11.1376 95.3065 11.6602 95.3065 11.6602L94.0866 16.3591C94.0866 16.3591 92.8966 15.8365 91.7126 15.8365C87.8831 15.8365 84.5188 19.7813 84.5188 26.7426V39.4761H79.735V11.6602Z"
  //       fill="currentColor"
  //     />
  //     <path
  //       d="M108.695 11.1388C103.197 11.1388 99.3823 14.0517 97.9077 18.3523L101.961 19.9409C102.925 17.4848 105.018 15.3151 108.695 15.3151C113.422 15.3151 115.415 18.1586 115.415 21.465V23.8869C113.593 23.3643 111.314 22.8429 108.24 22.8429C99.6969 22.8429 96.2226 25.8447 96.2226 30.8336C96.2226 35.9979 100.039 40 106.36 40C110.461 40 113.535 38.4613 115.302 35.4448V39.4786H120.085V21.3493C120.085 15.722 116.156 11.1388 108.695 11.1388ZM115.415 28.5639C115.415 32.8572 112.168 35.9395 107.215 35.9395C103 35.9395 101.064 33.7344 101.064 30.7764C101.064 27.2957 103.512 26.7292 108.24 26.7292C110.803 26.7292 113.024 27.0192 115.415 27.7733V28.5639Z"
  //       fill="currentColor"
  //     />
  //     <path
  //       d="M125.723 11.6602H130.507V16.3007C132.045 13.2842 135.007 11.1376 139.506 11.1376C145.941 11.1376 149.7 15.2566 149.7 22.0437V39.4761H144.916V22.7978C144.916 18.1573 142.809 15.4881 138.196 15.4881C133.356 15.4881 130.507 18.9687 130.507 24.9444V39.4774H125.723V11.6602Z"
  //       fill="currentColor"
  //     />
  //     <path
  //       d="M154.819 32.0519V3.71335H159.603V11.6615H166V15.7805H159.603V31.6474C159.603 34.4897 160.799 35.708 163.419 35.708C164.974 35.708 166.001 35.4363 166.001 35.4363V39.6138C165.09 39.8465 164.33 40.0012 162.508 40.0012C156.755 40 154.819 36.9835 154.819 32.0519Z"
  //       fill="currentColor"
  //     />
  //     <path
  //       d="M20.0288 15.5807L17.0676 18.5971C15.2486 16.6308 12.6677 15.4028 9.80452 15.4028C7.15546 15.4028 4.74679 16.4542 2.96001 18.1707L0 15.1555C2.54501 12.6677 6.00016 11.1388 9.80452 11.1388C13.823 11.1388 17.4515 12.8432 20.0288 15.5807Z"
  //       fill="currentColor"
  //     />
  //   </g>
  //   <defs>
  //     <clipPath id="clip0">
  //       <rect width="166" height="40" fill="white" />
  //     </clipPath>
  //   </defs>
  // </svg>

  <svg
    width="100%"
    height="100%"
    viewBox="0 0 100 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_6321_10507)">
      <path
        d="M90.1528 10.5637L89.6904 7.41313C89.8754 7.3668 94.1749 6.76448 99.9539 6.76448V9.91506C94.4523 9.96139 90.199 10.5637 90.1528 10.5637Z"
        fill="#060606"
      />
      <path
        d="M23.2083 7.18147H19.5098V23.5367H23.2083V7.18147Z"
        fill="#060606"
      />
      <path
        d="M34.9512 6.44015C32.4547 6.44015 31.114 7.55212 30.0968 8.66409V0.324325H26.3521V23.5367H30.0968V21.8224C31.0215 22.9344 32.316 24.0463 34.9512 24.0463C39.7131 24.0463 42.5795 20.5251 42.5795 15.0579C42.5795 9.72973 39.7594 6.44015 34.9512 6.44015ZM34.3964 20.8494C31.5763 20.8494 30.1431 18.4402 30.1431 15.0579C30.1431 11.6293 31.7612 9.63706 34.3964 9.63706C37.2628 9.63706 38.6498 12.0927 38.6498 15.0579C38.6498 17.9768 37.7251 20.8494 34.3964 20.8494Z"
        fill="#060606"
      />
      <path
        d="M54.5998 10.2857C54.5998 10.2857 53.9988 10.1467 53.4903 10.1467C50.6239 10.1467 48.6359 12.3243 48.6359 15.3822V23.4903H44.8911V7.18147H48.6359V9.5444C49.6992 7.55212 51.1324 6.57915 53.028 6.57915C53.5827 6.57915 54.0913 6.62548 54.5998 6.67182V10.2857Z"
        fill="#060606"
      />
      <path
        d="M88.5805 12.417V23.5367H84.8357V13.3436C84.8357 10.9807 83.7261 9.77606 81.5994 9.77606C78.9642 9.77606 77.2536 11.3514 77.2536 13.8069V23.5367H73.5088V7.18147H77.2536V8.75676C78.132 7.73745 79.7501 6.44016 82.6627 6.44016C85.5291 6.44016 88.5805 8.01544 88.5805 12.417Z"
        fill="#060606"
      />
      <path
        d="M96.1164 18.6718C96.1164 20.3398 96.5325 20.6178 97.7807 20.6178C98.428 20.6178 98.8903 20.4788 98.8903 20.4788L99.3989 23.4903C99.3989 23.4903 98.1968 23.8147 97.0873 23.8147C93.7585 23.8147 92.3716 22.2394 92.3716 18.5791V2.77992H96.1164V18.6718Z"
        fill="#060606"
      />
      <path
        d="M21.3594 4.35521C22.5595 4.35521 23.5323 3.38026 23.5323 2.17761C23.5323 0.974947 22.5595 0 21.3594 0C20.1594 0 19.1865 0.974947 19.1865 2.17761C19.1865 3.38026 20.1594 4.35521 21.3594 4.35521Z"
        fill="#060606"
      />
      <path
        d="M9.66251 23.8147C6.14887 23.8147 5.0393 20.2934 4.16089 17.0039C3.32871 14.0849 2.35784 10.9807 0 9.63706L1.84928 6.39382C5.50162 8.47876 6.84235 12.556 7.72076 15.9846C8.7841 20.1081 9.61627 20.0618 9.61627 20.0618C9.61627 20.0618 12.1128 19.2741 13.3148 7.13513H17.0134C17.0134 9.26641 15.5802 23.8147 9.66251 23.8147Z"
        fill="#060606"
      />
      <path
        d="M63.2916 6.44016C59.593 6.44016 57.6512 8.15444 56.2181 10.7027L59.3618 12.1853C60.1015 10.5637 61.6734 9.59073 63.5227 9.59073C65.2333 9.59073 66.9901 10.888 66.9901 13.2046V14.0386C63.6614 13.0193 59.9166 13.39 57.6512 15.0116C56.3105 15.9846 55.5708 17.3282 55.5708 18.8571C55.5708 21.9151 57.8824 23.9537 61.3498 23.9537C63.8001 23.9537 65.7419 23.3514 66.9901 21.7297V23.444H70.5962V14.1776C70.6425 8.5251 67.5449 6.44016 63.2916 6.44016ZM62.1358 20.8958C60.3327 20.8958 59.4543 20.2008 59.4543 18.6718C59.4543 15.1506 66.3429 16.5405 66.9901 16.8185C66.9901 19.4595 64.9097 20.8958 62.1358 20.8958Z"
        fill="#060606"
      />
    </g>
    <defs>
      <clipPath id="clip0_6321_10507">
        <rect width="100" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const LogoSingleSvg = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 64 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M15.3084 20.5525H23.8463L38.5208 50H29.9829L15.3084 20.5525Z"
        fill="currentColor"
      />
      <path
        d="M40.6553 50H32.1174L53.4621 2.88522H62L40.6553 50Z"
        fill="currentColor"
      />
      <path
        d="M35.746 9.52334L30.461 14.6325C27.2145 11.302 22.6083 9.22208 17.4984 9.22208C12.7705 9.22208 8.47172 11.0028 5.28282 13.9102L0 8.8032C4.54216 4.58963 10.7086 2 17.4984 2C24.6702 2 31.1462 4.88677 35.746 9.52334Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="64" height="50" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

/**
 * Types
 */
export interface LogoIconProps {
  single?: boolean;
  style: any;
}

/**
 * LogoIcon component
 */
const LogoIcon: React.FC<LogoIconProps> = ({ single, style, ...rest }) => {
  return (
    <Icon
      component={single ? LogoSingleSvg : LogoSvg}
      {...rest}
      style={style}
    />
  );
};

/**
 * Exports
 */
export { LogoIcon };
