import React from 'react';
import { Text } from 'components';
import * as Styles from './AuthLayout.styles';

export type AuthLayoutProps = {
  title: JSX.Element | string;
  subtitle: JSX.Element | string;
};

const AuthLayout: React.FC<AuthLayoutProps> = (props) => {
  return (
    <Styles.Container>
      {/* Uncommented because only mock terstimonial data exsits */}
      {/* <Styles.TestimonialContainer>
        <Styles.TestimonialImage src="https://via.placeholder.com/488x650" />

        <Styles.TestimonialContent>
          <Text size="ui1">
            Testimonial text
          </Text>

          <Space size="lg" />

          <Text size="ui3" bold>
            Testimonial text
          </Text>
        </Styles.TestimonialContent>
      </Styles.TestimonialContainer> */}

      <Styles.ContentContainer>
        <div>
          <Styles.TextContainer>
            <Text size="h3" bold>
              {props.title}
            </Text>

            <Text size="ui2">{props.subtitle}</Text>
          </Styles.TextContainer>

          <Styles.LoginFormContainer>
            {props.children}
          </Styles.LoginFormContainer>
        </div>
      </Styles.ContentContainer>
    </Styles.Container>
  );
};

export { AuthLayout };
