/**
 * Imports
 */
import { Card, Space } from 'components';
import * as React from 'react';

import styled from 'styled-components';
import { Text } from 'components';
import { useMerchantApi } from 'features/shared';

/**
 * Styling
 */
const Root = styled.div`
  padding: 24px;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

/**
 * Types
 */
export interface ActiveTerminalsProps {}

/**
 * ActiveTerminals component
 */
const ActiveTerminals: React.FC<ActiveTerminalsProps> = (_props) => {
  const { merchantCollection } = useMerchantApi();
  return (
    <Card>
      <Content>
        <Text size="h5">{merchantCollection?.terminalCollection.length}</Text>
        <Space size="sm" />
        <Text size="ui2">Active terminals</Text>
      </Content>
    </Card>
  );
};

/**
 * Exports
 */
export { ActiveTerminals };
