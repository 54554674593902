/**
 * Imports
 */
import { Table as AntdTable } from 'antd';
import { TableProps as AntdTableProps } from 'antd/lib/table';
import React from 'react';
import styled from 'styled-components';

/**
 * Styling
 */
const StyledTable = styled(AntdTable)`
  & .ant-table-thead .ant-table-cell {
    font-size: 11px !important;
  }

  & .ant-table-row .ant-table-cell {
    font-size: 11px !important;
  }

  & .ant-table-thead .ant-table-cell {
    background-color: transparent;
    padding: 0px 0px 16px 0px;
    color: ${(props) => props.theme.colors.Text.TableLabel};
    font-size: 13px;
    font-weight: bold;
  }

  & .ant-table-cell {
    cursor: pointer;
    padding: 8px 0px;
  }

  .ant-table-column-sorters {
    padding: 0px;
  }

  & .ant-table-column-sorter {
    display: none;
  }

  & .ant-pagination-total-text {
    display: none;
  }

  .ant-table-pagination.ant-pagination {
    margin: 24px 0px 0px 0px;
  }

  & .ant-table-pagination {
    align-items: center;
  }

  & .ant-pagination-prev,
  .ant-pagination-next {
    height: auto;
    display: flex;
    justify-content: center;

    svg {
      color: ${(props) => props.theme.colors.Background.TableArrow};
    }
  }

  & .ant-pagination-item {
    border: none;
    font-size: 17px;
    color: ${(props) => props.theme.colors.Text.TablePaginationInactive};
  }

  & .ant-pagination-item-active a {
    font-weight: bold;
    color: ${(props) => props.theme.colors.Text.TablePaginationActive};
  }

  & .ant-table-row .ant-table-cell:not(:last-child) {
    border: none;
  }
  & .ant-table-row .ant-table-cell:last-child {
    border: none;
  }

  /* Responsive styles */
  @media screen and (max-width: 600px) {
    padding: 8px;
    border: 1px solid ${({ theme }) => theme.colors.Border.BorderPrimary};
    max-width: calc(100vw - 48px);
    overflow: scroll;
  }

  // Header
  & .ant-table-thead .ant-table-cell {
    @media screen and (max-width: 600px) {
      display: none;
    }
  }
  // Cells
  & .ant-table-row .ant-table-cell {
    @media screen and (max-width: 600px) {
      display: flex;
      padding: 12px;
    }
  }
  & .ant-table-row .ant-table-cell:first-child {
    @media screen and (max-width: 600px) {
      padding-top: 16px;
    }
  }
  & .ant-table-row .ant-table-cell:last-child {
    @media screen and (max-width: 600px) {
      padding-bottom: 16px;
      border-bottom: 1px solid
        ${({ theme }) => theme.colors.Border.BorderPrimary};
    }
  }
  & .ant-table-row:last-child .ant-table-cell:last-child {
    @media screen and (max-width: 600px) {
      border-bottom: none;
    }
  }
`;
/**
 * Types
 */
export type TableProps<T> = AntdTableProps<T> & {
  color?: string;
};

/**
 * Table component
 */
function Table<T>({ ...props }: TableProps<T>): JSX.Element {
  // TODO: This break pagination and only shows one result and not total, fix this so override of prev and next is easier
  // const itemRender = (
  //   _: JSX.Element,
  //   type: string,
  //   originalElement: JSX.Element,
  // ) => {
  //   if (type === 'prev') {
  //     return <VibrantIcon icon="arrow-long-left" />;
  //   } else if (type === 'next') {
  //     return <VibrantIcon icon="arrow-long-right" />;
  //   } else {
  //     return originalElement;
  //   }
  // };

  return <StyledTable<any> {...props} />;
}

/**
 * Exports
 */
export { Table };
