/**
 * Imports
 */
import { Button } from 'components';
import * as React from 'react';
import { Trans } from 'react-i18next';

import styled from 'styled-components';

/**
 * Styling
 */
const Root = styled.div`
  padding: 24px;
`;

/**
 * Types
 */
export interface SendReceiptButtonProps {}

/**
 * SendReceiptButton component
 */
const SendReceiptButton: React.FC<SendReceiptButtonProps> = (props) => {
  const _sendReceipt = () => {
    console.log('_sendReceipt');
  };
  return (
    <Button color="secondary" onClick={_sendReceipt}>
      <Trans i18nKey="TransactionsDetails.transaction_optionsbar.send_btn" />
    </Button>
  );
};

/**
 * Exports
 */
export { SendReceiptButton };
