/**
 * Imports
 */
import {
  changeAccount,
  changeOrganization,
  disableIntegration,
  getOrganizations,
  getTransferAccounts,
} from 'apis';
import { messageError } from 'components';
import { merchantCollectionSelector } from 'features/user/redux/selectors';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Firebase } from 'types/firebase';
import { VibrantAPI } from 'types/vibrant';
import { updateEconomicsEnabled } from '../economics/utils/economic-api';

/**
 * Types
 */
export type IntegrationAPIHandlers = {
  onDisableIntegration: (
    integrationName: Firebase.IntegrationNames,
  ) => Promise<void>;
  onChangeTransferAccount: (
    integrationName: Firebase.IntegrationNames,
    accountId: number,
  ) => Promise<any>;
  onGetTransferAccounts: (
    integrationName: Firebase.IntegrationNames,
  ) => Promise<VibrantAPI.TransferAccount[]>;
  onChangeOrganization: (
    integrationName: Firebase.IntegrationNames,
    organizationId: number,
  ) => Promise<any>;
  onGetOrganizations: (
    integrationName: Firebase.IntegrationNames,
  ) => Promise<VibrantAPI.Organization[]>;
};
export type IntegrationAPIType = {
  handlers: IntegrationAPIHandlers;
  isLoading: boolean;
  isfetchingData: boolean;
};

/**
 * useIntegrationApi
 */
const useIntegrationApi = (): IntegrationAPIType => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isfetchingData, setIsfetchingData] = React.useState(false);
  const merchant = useSelector(merchantCollectionSelector);

  const handlers: IntegrationAPIHandlers = {
    /**
     * onDisableIntegration
     */
    onDisableIntegration: async (
      integrationName: Firebase.IntegrationNames,
    ) => {
      setIsLoading(true);
      try {
        if (integrationName === 'dinero') {
          const resp = await disableIntegration(
            integrationName,
            merchant.merchantId,
            merchant.clearhausMerchantId,
          );
          setIsLoading(false);
          return resp;
        } else {
          await updateEconomicsEnabled(merchant.merchantId, false);
          setIsLoading(false);
        }
      } catch (e) {
        messageError({ content: e.message });
        setIsLoading(false);
      }
    },
    /**
     * onGetTransferAccounts
     */
    onGetTransferAccounts: async (
      integrationName: Firebase.IntegrationNames,
    ) => {
      try {
        setIsfetchingData(true);
        const resp = await getTransferAccounts(
          integrationName,
          merchant.merchantId,
          merchant.clearhausMerchantId,
        );
        setIsfetchingData(false);
        return resp;
      } catch (e) {
        messageError({ content: e.message });
        setIsfetchingData(false);
        return [];
      }
    },
    /**
     * onChangeTransferAccount
     */
    onChangeTransferAccount: async (
      integrationName: Firebase.IntegrationNames,
      accountId: number,
    ) => {
      try {
        setIsLoading(true);
        const resp = await changeAccount(
          integrationName,
          accountId,
          merchant.merchantId,
          merchant.clearhausMerchantId,
        );
        setIsLoading(false);
        return resp;
      } catch (e) {
        messageError({ content: e.message });
        setIsLoading(false);
      }
    },
    /**
     * onGetOrganizations
     */
    onGetOrganizations: async (integrationName: Firebase.IntegrationNames) => {
      try {
        setIsfetchingData(true);
        const resp = await getOrganizations(
          integrationName,
          merchant.clearhausMerchantId,
        );
        setIsfetchingData(false);
        return resp;
      } catch (e) {
        messageError({ content: e.message });
        setIsfetchingData(false);
        return [];
      }
    },
    /**
     * onChangeOrganization
     */
    onChangeOrganization: async (
      integrationName: Firebase.IntegrationNames,
      organizationId: number,
    ) => {
      try {
        setIsLoading(true);
        const resp = await changeOrganization(
          integrationName,
          organizationId,
          merchant.merchantId,
          merchant.clearhausMerchantId,
        );
        setIsLoading(false);
        return resp;
      } catch (e) {
        messageError({ content: e.message });
        setIsLoading(false);
      }
    },
  };

  return { handlers, isLoading, isfetchingData };
};

/**
 * Exports
 */
export { useIntegrationApi };
