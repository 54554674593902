/**
 * Imports
 */
import React from 'react';
import { Trans } from 'react-i18next';

import { ForgotPasswordForm } from 'features/user/components';
import { AuthLayout } from 'features/navigation';

/**
 * LoginPage component
 */
const ForgotPasswordPage: React.FC = () => {
  return (
    <AuthLayout
      title={<Trans i18nKey="ForgotPassword.title" />}
      subtitle={<Trans i18nKey="ForgotPassword.subtitle" />}
    >
      <ForgotPasswordForm />
    </AuthLayout>
  );
};

/**
 * Exports
 */
export { ForgotPasswordPage };
