/**
 * Imports
 */
import { CreditCard, Logo, Space, Spinner, Text } from 'components';
import { PROJECT_CONFIG } from 'config';
import { OrderItems } from 'features/transactions';
import moment from 'moment';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { SoftPos } from 'types/softpos';
import { DATE_FORMATS, formatCurrency } from 'utils';

/**
 * Styling
 */
const Root = styled.div<{ $amountWithMinus?: boolean }>`
  border-radius: 4px;
  padding: 48px;
  min-height: 534px;
  background-color: ${({ theme }) => theme.colors.Background.Primary};
  background-color: ${({ theme, $amountWithMinus }) =>
    $amountWithMinus && theme.colors.Background.InReview};
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;
const GridRow = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  justify-content: space-between;

  & div:nth-child(3n + 3) {
    text-align: right;
  }
`;
const Column = styled.div<{ rightAlign?: boolean }>`
  display: flex;
  flex-direction: column;
  text-align: ${({ rightAlign }) => rightAlign && 'right'};
`;
const TitleRow = styled(GridRow)`
  margin: 64px 0 16px 0;
`;
const TrxnDataRow = styled(GridRow)`
  border-top: 1px solid ${({ theme }) => theme.colors.Border.BorderTertiary};
  border-bottom: 1px solid ${({ theme }) => theme.colors.Border.BorderTertiary};
  padding: 32px 0;
`;
const SpinnerWrapper = styled.div`
  display: flex;
  align-items: stretch;
  align-items: center;
  height: calc(534px - (48px * 2));
`;
const LinkWrapper = styled.div`
  cursor: pointer;
`;
/**
 * Types
 */
export interface ReceiptProps {
  isFecthing?: boolean;
  trxnItem: SoftPos.Trxn;
  vat: string;
}

/**
 * Receipt component
 */
const Receipt: React.FC<ReceiptProps> = ({ trxnItem, isFecthing, vat }) => {
  const history = useHistory();
  const _goToReceipt = (receiptId: string) => {
    const url = `${PROJECT_CONFIG.receiptsUrl}/${receiptId}`;
    window.location.href = url;
  };
  const vatNumber = parseInt(vat, 10);
  const amountWithMinus = formatCurrency(
    trxnItem?.statusCode === 'V' ? -trxnItem?.amount : trxnItem?.amount,
    trxnItem?.currency,
  );
  return (
    <Root $amountWithMinus={trxnItem?.statusCode === 'V'}>
      {isFecthing ? (
        <SpinnerWrapper>
          <Spinner showSpinner size={64} />
        </SpinnerWrapper>
      ) : (
        <>
          <Row>
            <Column>
              <LinkWrapper onClick={() => _goToReceipt(trxnItem?.receiptId)}>
                <Text size="p1" bold link>
                  <Trans
                    i18nKey="TransactionsDetails.receipt.title_id"
                    values={{ id: trxnItem?.receiptId }}
                  />
                </Text>
              </LinkWrapper>
              <Space size="sm" />
              <Text size="ui2" color="ui">
                {trxnItem?.date &&
                  moment(trxnItem?.date).format(DATE_FORMATS.time)}
              </Text>
            </Column>
            <Row>
              <CreditCard card={trxnItem?.cardSource} />
              <Space vertical />
              <Text size="ui2" color="ui">
                <Trans
                  i18nKey="TransactionsDetails.receipt.text_scheme"
                  values={{ scheme: trxnItem?.cardSource }}
                />
              </Text>
            </Row>
          </Row>
          <TitleRow>
            <Text size="ui3" bold color="ui">
              <Trans i18nKey="TransactionsDetails.receipt.product_name_title" />
            </Text>
            <Text size="ui3" bold color="ui">
              <Trans i18nKey="TransactionsDetails.receipt.quantity" />
            </Text>
            <Text size="ui3" bold color="ui">
              <Trans i18nKey="TransactionsDetails.receipt.amount" />
            </Text>
          </TitleRow>
          <OrderItems />
          <Space size="sm" />
          <Row>
            <Column>
              <Text size="ui2" bold>
                <Trans i18nKey="TransactionsDetails.receipt.total" />
              </Text>
              <Space size="xs" />
              <Text size="ui3" color="ui">
                <Trans i18nKey="TransactionsDetails.receipt.vat" />:
              </Text>
            </Column>
            <Column rightAlign>
              <Text size="ui2" bold>
                {trxnItem?.amount && amountWithMinus}
              </Text>
              <Space size="xs" />
              <Text size="ui2">
                {trxnItem?.amount &&
                  formatCurrency(
                    trxnItem.amount - trxnItem?.amount / (vatNumber / 100 + 1),
                    trxnItem?.currency,
                  )}
              </Text>
            </Column>
          </Row>
          <Space size="xxl" />
          <Row>
            <Logo single size={24} color="Orange500" />
          </Row>
        </>
      )}
    </Root>
  );
};

/**
 * Exports
 */
export { Receipt };
