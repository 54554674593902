/**
 * Imports
 */
import { Select, VibrantIcon } from 'components';
import { useDashboardSettings } from 'features/shared';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

const SelectWrapper = styled.div``;
const LanguageSelect = styled(Select)`
  && {
    .ant-select-selector {
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme.colors.Border.BorderPrimary};
      height: 32px;
      display: flex;
      align-items: center;
    }
    .ant-select-arrow {
      color: ${({ theme }) => theme.colors.Text.Heading};
    }
    // Dropdown item
    .ant-select-item {
      background-color: none;
      color: ${({ theme }) => theme.colors.Text.Heading};
      height: 32px;

      display: flex;
      align-items: center;
    }
    // Selected item
    .ant-select-item-option-active {
      background-color: ${({ theme }) => theme.colors.Background.Primary};
    }
  }
`;

/**
 * Types
 */
export interface LanguageSelectorProps {}
export enum LanguageTypes {
  da = 'da',
  en = 'en',
  es = 'es',
  ca = 'ca',
}
/**
 * LanguageSelector component
 */
const LanguageSelector: React.FC<LanguageSelectorProps> = (_props) => {
  const { i18n } = useTranslation();
  const {
    dashboardSettings,
    handlers: { onChangeLanguage },
  } = useDashboardSettings();

  const _changeLanguage = async (language: LanguageTypes) => {
    i18n.changeLanguage(language);
    await onChangeLanguage(language);
  };

  if (!dashboardSettings?.language) {
    return null;
  }

  return (
    <SelectWrapper>
      <LanguageSelect
        defaultValue={dashboardSettings.language}
        suffixIcon={<VibrantIcon icon="chevron-down" size={12} />}
        // @ts-ignore
        onChange={(val) => _changeLanguage(val)}
        // Needed to override select dropdown style
        getPopupContainer={(trigger) => {
          return trigger;
        }}
      >
        <Select.Option value={LanguageTypes.da}>
          <Trans i18nKey="_Common.change_language.da_long" />
        </Select.Option>
        <Select.Option value={LanguageTypes.en}>
          <Trans i18nKey="_Common.change_language.en_long" />
        </Select.Option>
        <Select.Option value={LanguageTypes.es}>
          <Trans i18nKey="_Common.change_language.es_long" />
        </Select.Option>
        <Select.Option value={LanguageTypes.ca}>
          <Trans i18nKey="_Common.change_language.ca_long" />
        </Select.Option>
      </LanguageSelect>
    </SelectWrapper>
  );
};

/**
 * Exports
 */
export { LanguageSelector };
