/**
 * Imports
 */
import React from 'react';
import { Input as AntdInput } from 'antd';
import { InputProps as AntdInputProps } from 'antd/lib/input';

import { Text, Space } from 'components';
import styled from 'styled-components';

/**
 * Styled components
 */
const StyledInput = styled(AntdInput)`
  && {
    border-color: ${(props) => props.theme.colors.Input.BorderDefault};
    border-radius: 4px;
    color: ${(props) => props.theme.colors.Input.ColorDefault};
    font-size: 17px;
    font-family: 'BasierRegular', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
    height: 48px;
    line-height: 21.76px;
    outline: none;

    &::placeholder {
      color: ${(props) => props.theme.colors.Input.ColorPlaceholder};
    }

    &:focus {
      color: ${(props) => props.theme.colors.Input.ColorFocus};
      box-shadow: none;
      border-color: ${(props) => props.theme.colors.Input.BorderFocus};
    }

    &.ant-input {
      padding: 12px 16px;
    }

    &.ant-input[disabled] {
      background-color: ${(props) =>
        props.theme.colors.Input.BackgroundDisabled};
      border-color: ${(props) => props.theme.colors.Input.BorderDisabled};
    }
  }
`;

StyledInput.Search = styled(AntdInput.Search)`
  && {
    border-color: ${(props) => props.theme.colors.Input.BorderDefault};
    border-radius: 4px;
    color: ${(props) => props.theme.colors.Input.ColorDefault};
    font-size: 17px;
    font-family: 'BasierRegular', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
    height: 48px;
    line-height: 21.76px;
    outline: none;

    &::placeholder {
      color: ${(props) => props.theme.colors.Input.ColorPlaceholder};
    }

    .ant-input-affix-wrapper {
      height: 48px;
    }

    .ant-input-search-button {
      background-color: transparent;
      border: 1px solid ${(props) => props.theme.colors.Input.BorderDefault};
      height: 48px;
    }
  }
`;

StyledInput.Password = styled(AntdInput.Password)`
  && {
    border-color: ${(props) => props.theme.colors.Input.BorderDefault};
    border-radius: 4px;
    color: ${(props) => props.theme.colors.Input.ColorDefault};
    font-size: 17px;
    font-family: 'BasierRegular', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
    height: 48px;
    line-height: 21.76px;
    outline: none;

    &.ant-input-password {
      padding: 12px 16px;
    }
  }
`;

export const Root = styled.div`
  width: 100%;
`;

export type InputProps = AntdInputProps & {
  label?: string;
};

const Input = (props: InputProps): JSX.Element => {
  return (
    <Root>
      {props.label != null && (
        <>
          <Text size="ui2">{props.label}</Text>
          <Space size="xs" />
        </>
      )}
      <StyledInput {...props} />
    </Root>
  );
};

/**
 * Exports
 */
export { Input };
