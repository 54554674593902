/**
 * Imports
 */
import * as React from 'react';
import { LogoIcon } from './LogoSvg';
import { VibrantColors } from 'theme';
import { ColorShadesType } from 'theme/colors';

/**
 * Types
 */
type LogoProps = {
  size?: number;
  single?: boolean;
  color?: ColorShadesType;
};

/**
 * Icon component
 */
const Logo: React.FC<LogoProps> = ({ size, color, single, ...rest }) => {
  return (
    <LogoIcon
      {...rest}
      single={single}
      style={{
        color: color
          ? VibrantColors.Shades[color]
          : VibrantColors.Shades.Grey900,
        height: size ? size : '32px',
        width: single ? size : 'auto',
      }}
    />
  );
};

/**
 * Exports
 */
export { Logo };
