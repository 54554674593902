import { logEvent, setUserId } from '@redux-beacon/amplitude';

// Events
const userIdEvent = setUserId((action) => {
  console.log('userid', action);
  return action.payload.id;
});

const userLoggedInEvent = logEvent((action) => {
  return {
    type: 'USER_LOGGED_IN',
    properties: {
      merchantId: action.payload.meta?.merchantId,
    },
  };
});

export { userIdEvent, userLoggedInEvent };
