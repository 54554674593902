/**
 * Imports
 */
import { createAction } from '@reduxjs/toolkit';
import { VibrantlyAction } from 'store/types';
import { Overview } from '../types';

/**
 * Constants
 */
const PREFIX = 'overview';

/**
 * GET_TRANSACTIONS
 */
export const GET_TRANSACTIONS = `${PREFIX}/GET_TRANSACTIONS`;
export type GetTransctionsAction = VibrantlyAction<
  Overview.GetKeynumbersPayload
>;
export const getTransactions = createAction<GetTransctionsAction['payload']>(
  GET_TRANSACTIONS,
);
/**
 * SET_DATES
 */
export const SET_DATES = `${PREFIX}/SET_DATES`;
export type SetDatesAction = VibrantlyAction<Overview.SetDatesPayload>;
export const setOverviewDates = createAction<SetDatesAction['payload']>(
  SET_DATES,
);
