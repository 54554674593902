/**
 * Imports
 */
import { createSelector } from '@reduxjs/toolkit';

import { VibrantlyState } from 'store/types';

/**
 * Selectors
 */
export const rootSelector = (state: VibrantlyState) => state.overview;

// keynumbers
export const trxnCountSelector = createSelector(
  [rootSelector],
  (root) => root.trxnCount,
);
export const trxnSumSelector = createSelector(
  [rootSelector],
  (root) => root.trxnSum,
);
export const trxnAverageSelector = createSelector(
  [rootSelector],
  (root) => root.trxnAverage,
);
export const sumGrowthSelector = createSelector(
  [rootSelector],
  (root) => root.sumGrowth,
);
export const countGrowthSelector = createSelector(
  [rootSelector],
  (root) => root.countGrowth,
);
export const averageGrowthSelector = createSelector(
  [rootSelector],
  (root) => root.averageGrowth,
);
export const trxnCurrencySelector = createSelector(
  [rootSelector],
  (root) => root.trxnCurrency,
);
// Dates
export const endDateSelector = createSelector(
  [rootSelector],
  (root) => root.endDate,
);
export const startDateSelector = createSelector(
  [rootSelector],
  (root) => root.startDate,
);
export const prevEndDateSelector = createSelector(
  [rootSelector],
  (root) => root.prevEndDate,
);
export const prevStartDateSelector = createSelector(
  [rootSelector],
  (root) => root.prevStartDate,
);
export const fixedRangeSelector = createSelector(
  [rootSelector],
  (root) => root.fixedRange,
);
