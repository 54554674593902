import styled from 'styled-components';
import { Divider as AntdDivider } from 'antd';

export const Root = styled.div`
  max-width: 432px;
  width: 100;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const Divider = styled(AntdDivider)`
  & > .ant-divider-inner-text {
    color: ${(props) => props.theme.colors.Text.LoginDividerLabel};
  }

  & > .ant-divider-horizontal {
    color: ${(props) => props.theme.colors.Border.BorderPrimary};
  }
`;
