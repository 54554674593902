/**
 * Imports
 */
import { createSelector } from '@reduxjs/toolkit';

import { VibrantlyState } from 'store/types';

/**
 * Selectors
 */
export const rootSelector = (state: VibrantlyState) => state.settlements;

export const currentBalanceSelector = createSelector(
  [rootSelector],
  (root) => root.currentBalance,
);
export const settlementsSelector = createSelector(
  [rootSelector],
  (root) => root.settlements,
);
export const settlementsCountSelector = createSelector(
  [rootSelector],
  (root) => root.settlementsCount,
);
export const postingsSelector = createSelector(
  [rootSelector],
  (root) => root.postings,
);
export const endDateSelector = createSelector(
  [rootSelector],
  (root) => root.endDate,
);
export const startDateSelector = createSelector(
  [rootSelector],
  (root) => root.startDate,
);
export const fixedRangeSelector = createSelector(
  [rootSelector],
  (root) => root.fixedRange,
);
