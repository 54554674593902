/**
 * Imports
 */
import { PlusOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { Button, VibrantIcon, Text, Icon, Anchor, Box } from 'components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { theme } from 'theme';
import { UploadOwnerDocumentsForm } from '../UploadOwnerDocumentsForm';

const AnchorButton = styled.div`
  color: ${({ theme }) => theme.colors.Text.Brand};
  cursor: pointer;

  display: flex;
  align-items: center;
`;

/**
 * Types
 */
export interface UploadOwnerDocumentsModalProps {
  ownerIndex: number;
}

/**
 * UploadOwnerDocumentsModal component
 */
const UploadOwnerDocumentsModal: React.FC<UploadOwnerDocumentsModalProps> = (
  props,
) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = React.useState(false);

  const _handleCancel = () => {
    setShowModal(false);
  };

  const _showModal = () => {
    setShowModal(true);
  };

  return (
    <>
      <Box display={'flex'} justifyContent="flex-end" mr="16px">
        <AnchorButton onClick={_showModal}>
          <Text size="ui2" bold>
            {t('Account.upload_document.open_upload_btn')}
          </Text>
        </AnchorButton>
      </Box>
      <Modal
        visible={showModal}
        onCancel={_handleCancel}
        footer={null}
        closeIcon={<VibrantIcon icon="close" size={20} />}
      >
        <Box my="24px">
          <UploadOwnerDocumentsForm ownerIndex={props.ownerIndex} />
        </Box>
      </Modal>
    </>
  );
};

/**
 * Exports
 */
export { UploadOwnerDocumentsModal };
