/**
 * Imports
 */
import { SettingOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { Button, Icon, Select, Snackbar, Space, Text } from 'components';
import { useIntegrationApi } from 'features/integrations';
import {
  integrationCollectionSelector,
  isFeatureTesterSelector,
} from 'features/user/redux/selectors';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Firebase } from 'types/firebase';
import { VibrantAPI } from 'types/vibrant';
import EconomicsModal from 'features/integrations/economics/components/EconomicsModal';
import { useLocation, useHistory } from 'react-router-dom';
/**
 * Styling
 */
const SettingsButton = styled.div``;
const Form = styled.div``;
const Content = styled.div`
  padding: 8px 0 24px;
`;
const StyledSelect = styled(Select)`
  width: 100%;
`;
const ButtonRow = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
`;

/**
 * Types
 */
export interface IntegrationSettingsProps {
  integrationId: Firebase.IntegrationNames;
}

export interface IntegrationEconomicSettingsProps {
  merchantId: string;
}
const EconomicIntegrationSettings: React.FC<IntegrationEconomicSettingsProps> = ({
  merchantId,
}) => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const showIntegation = params.get('open_modal') === 'economic';
  const history = useHistory();

  const _showSettings = () => {
    history.replace('/integrations?open_modal=economic');
  };

  return (
    <>
      <SettingsButton onClick={_showSettings}>
        <Text size="h6" link>
          <Icon icon={<SettingOutlined />} size={20} color="White000" />
        </Text>
      </SettingsButton>
      <EconomicsModal showModal={showIntegation} merchantId={merchantId} />
    </>
  );
};
//
/**
 * IntegrationSettings component
 */
const IntegrationSettings: React.FC<IntegrationSettingsProps> = ({
  integrationId,
}) => {
  const {
    handlers: {
      onGetTransferAccounts,
      onChangeTransferAccount,
      onChangeOrganization,
      onGetOrganizations,
    },
    isLoading,
    isfetchingData,
  } = useIntegrationApi();
  const { t } = useTranslation();
  const integrationCollection = useSelector(integrationCollectionSelector);
  /* Local state */
  const [showModal, setShowModal] = React.useState(false);
  const [showNote, setShowNote] = React.useState(false);
  const [selectedAccount, setSelectedAccount] = React.useState<number | null>(
    null,
  );
  const [options, setOptions] = React.useState<VibrantAPI.TransferAccount[]>(
    [],
  );
  const [selectedOrganization, setSelectedOrganization] = React.useState<
    number | null
  >(null);
  const [organizationOptions, setOrganizationOptions] = React.useState<
    VibrantAPI.Organization[]
  >([]);
  /* In-use var */
  const hasAccountNumber = integrationCollection?.dinero?.settings?.account;
  const hasOrgId = integrationCollection?.dinero?.settings?.organization_id;

  /**
   * Show modal
   */
  const _showSettings = () => {
    setShowModal(!showModal);
  };

  /**
   * _setDefaultValue
   */
  const _setDefaultValue = () => {
    if (hasAccountNumber) {
      setSelectedAccount(integrationCollection.dinero.settings.account!);
    }
    if (hasOrgId) {
      setSelectedOrganization(
        integrationCollection.dinero.settings.organization_id!,
      );
    }
  };

  /**
   * _onGetTransferAccounts
   */
  const _onGetTransferAccounts = async () => {
    const resp = await onGetTransferAccounts(integrationId);
    setOptions(resp);
  };

  /**
   * _changeAccount
   */
  const _changeAccount = async (accountId: SelectValue) => {
    setSelectedAccount(accountId as number);
  };

  /**
   * _onGetOrganization
   */
  const _onGetOrganization = async () => {
    const resp = await onGetOrganizations(integrationId);
    setOrganizationOptions(resp);
  };

  /**
   * _changeOrganization
   */
  const _changeOrganization = async (organizationId: SelectValue) => {
    setSelectedOrganization(organizationId as number);
  };

  /**
   * _onSaveChanges
   */
  const _onSaveChanges = async () => {
    try {
      if (
        integrationCollection?.dinero?.settings?.account !== selectedAccount
      ) {
        await onChangeTransferAccount(integrationId, selectedAccount!);
      }
      if (
        integrationCollection?.dinero?.settings?.organization_id !==
        selectedOrganization
      ) {
        await onChangeOrganization(integrationId, selectedOrganization!);
      }

      setShowModal(false);
    } catch (e) {}
  };

  React.useEffect(() => {
    _setDefaultValue();
    if (!hasOrgId) {
      setShowModal(true);
      setShowNote(true);
    }
    if (hasOrgId && showNote) {
      setShowNote(false);
    }
  }, [integrationCollection]);

  React.useEffect(() => {
    if (showModal) {
      _onGetTransferAccounts();
      _onGetOrganization();
    }
  }, [showModal]);

  return (
    <>
      <Modal
        title="Settings"
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={null}
      >
        <Content>
          {/* Accounts */}
          <Form>
            <Text size="ui1" bold>
              {t([
                `Integrations.${integrationId}.settings_select_account_heading`,
              ])}
            </Text>
            <Space size="xs" />
            <StyledSelect
              // @ts-ignore
              value={selectedAccount}
              loading={isfetchingData}
              onChange={(val) => _changeAccount(val)}
              placeholder={t([
                `Integrations.${integrationId}.settings_select_account_placeholder`,
              ])}
            >
              {options?.map((o) => {
                return (
                  <Select.Option key={o.AccountNumber} value={o.AccountNumber}>
                    {o.Name}
                  </Select.Option>
                );
              })}
            </StyledSelect>
          </Form>
          <Space size="xl" />
          {/* Organizations */}
          {showNote && (
            <>
              <Snackbar type="info">
                {t([
                  `Integrations.${integrationId}.settings_notification_text`,
                ])}
              </Snackbar>
              <Space size="xs" />
            </>
          )}
          <Form>
            <Text size="ui1" bold>
              {t([`Integrations.${integrationId}.settings_select_org_heading`])}
            </Text>
            <Space size="xs" />
            <StyledSelect
              // @ts-ignore
              value={selectedOrganization}
              loading={isfetchingData}
              onChange={(val) => _changeOrganization(val)}
              placeholder={t([
                `Integrations.${integrationId}.settings_select_org_placeholder`,
              ])}
            >
              {organizationOptions?.map((o) => {
                return (
                  <Select.Option key={o.id} value={o.id}>
                    {o.name}
                  </Select.Option>
                );
              })}
            </StyledSelect>
          </Form>
          <ButtonRow>
            <Button type="primary" onClick={_onSaveChanges} loading={isLoading}>
              {t([`Integrations.${integrationId}.settings_save_changes_btn`])}
            </Button>
          </ButtonRow>
        </Content>
      </Modal>
      <SettingsButton onClick={_showSettings}>
        <Text size="h6" link>
          <Icon icon={<SettingOutlined />} size={20} color="White000" />
        </Text>
      </SettingsButton>
    </>
  );
};

/**
 * Exports
 */
export { IntegrationSettings, EconomicIntegrationSettings };
