/**
 * Imports
 */
import { Anchor, Text, Space, VibrantIcon } from 'components';
import * as React from 'react';
import styled, { css } from 'styled-components';

/**
 * Color variants
 */
export const CARD_THEME = {
  // Types
  green: css`
    background-color: ${({ theme }) => theme.colors.Shades.Green300};
  `,
  blue: css`
    background-color: ${({ theme }) => theme.colors.Shades.VismaBlue};
  `,
};

/**
 * Styling
 */

const Root = styled.div<{ cardTheme: CardThemes }>`
  border-radius: 16px;
  padding-top: 24px;
  width: 272px;
  max-height: 400px;
  margin-left: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${({ cardTheme }) => cardTheme && CARD_THEME[cardTheme]}
`;
const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  width: 100%;
`;
const Body = styled.div`
  padding: 0 24px;
`;
const Content = styled.div`
  padding: 24px;
`;
const Bottom = styled.div`
  border-top: 1px solid rgba(20, 20, 20, 0.1);
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  background-color: ${({ theme }) => theme.colors.Shades.VismaBlue};
  padding: 16px 24px;
  margin-top: 24px;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
`;

/**
 * Types
 */
export type CardThemes = 'green' | 'blue';
export interface RoundedCardProps {
  body?: React.ReactNode;
  footer?: React.ReactNode;
  heading?: React.ReactNode;
  subtext?: React.ReactNode;
  guideLinkHref?: string;
  guideLinkText?: React.ReactNode;
  theme?: CardThemes;
  top?: React.ReactNode;
}

/**
 * RoundedCard component
 */
const RoundedCard: React.FC<RoundedCardProps> = ({
  heading,
  top,
  body,
  footer,
  subtext,
  guideLinkText,
  guideLinkHref,
  theme,
}) => {
  return (
    <Root cardTheme={theme || 'blue'}>
      <Top>{top}</Top>
      <Content>
        <Text size="h5" color="white">
          {heading}
        </Text>
        <Space size="xs" />
        <Text size="p2" color="white">
          {subtext}
        </Text>
        {guideLinkHref && (
          <>
            <Space size="xs" />
            <Anchor
              bold
              color="white"
              target="_blank"
              size="ui2"
              href={guideLinkHref}
            >
              <Row>
                {guideLinkText}
                <Space size="sm" vertical />
                <VibrantIcon
                  icon="arrow-long-right"
                  color="White000"
                  size={20}
                />
              </Row>
            </Anchor>
          </>
        )}
      </Content>
      <Body>{body}</Body>
      <Bottom>{footer}</Bottom>
    </Root>
  );
};

/**
 * Exports
 */
export { RoundedCard };
