/**
 * Imports
 */
import * as React from 'react';

import styled from 'styled-components';
import { Text, VibrantIcon, Anchor, CreditCard } from 'components';
import { version } from '../../../../../package.json';
import { useTranslation } from 'react-i18next';

/**
 * Styling
 */
const Root = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.Border.BorderPrimary};
  padding: 16px 24px;
  background-color: red;

  display: flex;
  align-items: center;
  justify-content: center;
`;

/**
 * Types
 */
export interface ImportantNoteProps {}

/**
 * ImportantNote component
 */
const ImportantNote: React.FC<ImportantNoteProps> = (_props) => {
  const { t } = useTranslation();
  return (
    <Root>
      <Text size="p1" color="white">
        {t('_Common.important_msg')}
      </Text>
    </Root>
  );
};

/**
 * Exports
 */
export { ImportantNote };
