/**
 * Imports
 */
import { Skeleton, Upload } from 'antd';
import { Button } from 'components';
import { useUpload } from 'features/user/api';
import { usePhotoidsApi } from 'features/user/api/usePhotoIds';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Firebase } from 'types/firebase';
import { ALLOWED_IMAGE_TYPES } from 'utils';
import { UploadOwnerDocumentsModal } from '../UploadOwnerDocumentsModal';
import { FilePreview } from './FilePreview';
/**
 * Styling
 */
const Root = styled.div`
  margin-bottom: 24px;
`;
const Wrapper = styled.div`
  margin-top: 24px;
`;
const WideRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ChangeButton = styled(Button)`
  color: ${(props) => props.theme.colors.Text.Brand};
`;
/**
 * Types
 */
export interface UploadPhotoFormProps {
  owner: Firebase.Owner;
  ownerIndex: number;
  clearhausKYCdone: number | null;
}

/**
 * UploadPhotoForm component
 */
const UploadPhotoForm: React.FC<UploadPhotoFormProps> = ({
  owner,
  ownerIndex,
  clearhausKYCdone,
}) => {
  const { t } = useTranslation();
  const { isLoading, fileUrls } = usePhotoidsApi({
    owner,
    ownerIndex,
  });

  const {
    handlers: { onUpdateImage },
    isUploading,
  } = useUpload();

  /**
   * _onUpdateImage
   */
  const _onUpdateImage = async ({ file }: any, fileIndex: number) => {
    await onUpdateImage(file, ownerIndex, fileIndex);
  };

  return (
    <Root>
      {!clearhausKYCdone && (
        <UploadOwnerDocumentsModal ownerIndex={ownerIndex} />
      )}
      {fileUrls.map((url) => {
        return (
          <WideRow key={url.fileIndex}>
            <Wrapper>
              {isUploading ? (
                <Skeleton.Avatar active={true} size={40} shape="square" />
              ) : (
                <FilePreview downloadUrl={url.downloadUrl} />
              )}
            </Wrapper>
            {clearhausKYCdone === null && (
              <Wrapper>
                <Upload
                  beforeUpload={() => false}
                  showUploadList={false}
                  onChange={(file) => _onUpdateImage(file, url.fileIndex)}
                  accept={`${ALLOWED_IMAGE_TYPES}`}
                >
                  <ChangeButton type="text">
                    {t('Account.upload_document.change_btn')}
                  </ChangeButton>
                </Upload>
              </Wrapper>
            )}
          </WideRow>
        );
      })}
    </Root>
  );
};

/**
 * Exports
 */
export { UploadPhotoForm };
