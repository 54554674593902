import * as React from 'react';
import styled from 'styled-components';
import { Text } from 'components';
import { FieldError } from 'react-hook-form';

const Container = styled.div``;

const InputContainer = styled.div<{ $suffix?: string }>`
  position: relative;
  display: inline-block;
  width: 100%;

  &&:after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.5em;
    transition: all 0.25 ease-in-out;
    content: ${({ $suffix }) => `${$suffix ?? ''}`};
  }

  &&:hover:after,
  &&:focus-within:after {
    right: 1.5em;
  }

  input {
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.colors.Border.InputBorder};
    padding: 10px 0;
    box-shadow: none;

    &:hover,
    &:focus,
    &:focus-within,
    &:active {
      box-shadow: none;
      border-bottom-color: ${({ theme }) => theme.colors.Border.InputBorder};
    }
  }
`;

type ProductInputProps = {
  label: string;
  suffix?: string;
  error?: FieldError;
};

// TODO: Handle errors. Still waiting on design to tell me how they should look ¯\_(ツ)_/¯

export const ProductInput: React.FC<ProductInputProps> = ({
  label,
  suffix,
  error,
  children,
}) => (
  <Container>
    <Text size="ui3" color="ui">
      {label}
    </Text>
    <InputContainer $suffix={suffix}>{children}</InputContainer>
  </Container>
);
