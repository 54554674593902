import React from 'react';
import styled from 'styled-components';
import { Typography, Button, Tag } from 'antd';
import { Input } from 'components';
const { Title } = Typography;
import { EconomicModel } from './EconomicsModal';
import { useTranslation } from 'react-i18next';
import EconomicOverviewImg from '../../../../assets/images/integrations/economic_overview.png';
import { getEconomicsAccount } from '../utils/economic-api';
import { merchantCollectionSelector } from 'features/user/redux/selectors';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
`;

const Header = styled.div`
  display: flex;
  height: 224px;
  background: #f5d1ee;
  border-radius: 8px 8px 0px 0px;
  justify-content: center;
`;
const Middle = styled.div`
  display: flex;
  height: 300px;
  padding: 24px;
  flex-direction: column;
  justify-content: space-between;
`;
const Footer = styled.div`
  padding: 24px;
  display: flex;
  border-top: 1px solid #ededed;
  height: 70px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const MyInput = styled(Input)`
  color: #c6c6c6;
  width: 100px;
  height: 22px;
  margin-top: 20px;
`;

const Img = styled.img`
  margin-top: 40px;
`;

type EconomicContentProps = {
  merchantId: string;
  closeModal: () => void;
  handleFinish: () => void;
  economic: EconomicModel;
  setEconomicState: (o: EconomicModel) => void;
};
const EconomicContent: React.FC<EconomicContentProps> = (props) => {
  const stepKey = props.economic.stepKey;
  const [error, setError] = React.useState<boolean>(false);
  const { t } = useTranslation();
  const getTitleText = () => {
    if (stepKey === 'deposit') {
      return t('Integrations.economic.settings_select_deposit_account_heading');
    }
    if (stepKey === 'bank') {
      return t('Integrations.economic.settings_select_bank_account_heading');
    }
    if (stepKey === 'revenue') {
      return t('Integrations.economic.settings_select_revenue_account_heading');
    }
    if (stepKey === 'fee') {
      return t('Integrations.economic.settings_select_fee_account_heading');
    }
  };

  const getNextStep = () => {
    if (stepKey === 'deposit') {
      return 'bank';
    }
    if (stepKey === 'bank') {
      return 'revenue';
    }
    return 'fee';
  };

  const buttonText =
    props.economic.stepKey !== getNextStep()
      ? t('Integrations.economic.settings_next_btn')
      : t('Integrations.economic.settings_save_changes_btn');

  const getContentText = () => {
    if (stepKey === 'deposit') {
      return t('Integrations.economic.settings_select_deposit_account_content');
    }
    if (stepKey === 'bank') {
      return t('Integrations.economic.settings_select_bank_account_content');
    }
    if (stepKey === 'revenue') {
      return t('Integrations.economic.settings_select_revenue_account_content');
    }
    if (stepKey === 'fee') {
      return t('Integrations.economic.settings_select_fee_account_content');
    }
  };
  const getIndex = () => {
    if (stepKey === 'deposit') {
      return '1';
    }
    if (stepKey === 'bank') {
      return '2';
    }
    if (stepKey === 'revenue') {
      return '3';
    }
    if (stepKey === 'fee') {
      return '4';
    }
  };

  const validateInput = async (value: number): Promise<boolean> => {
    const economicAccount = await getEconomicsAccount(props.merchantId);
    if (
      isNaN(value) ||
      !economicAccount ||
      !economicAccount.accounts.includes(value)
    ) {
      setError(true);
      setTimeout(() => setError(false), 3000);
      return false;
    } else {
      return true;
    }
  };
  const nextStep = async () => {
    const v = props.economic[stepKey];
    if (await validateInput(v)) {
      const nextStep = getNextStep();
      props.setEconomicState({
        ...props.economic,
        ...{ stepKey: nextStep },
      });
      if (nextStep === props.economic.stepKey) {
        props.handleFinish();
        props.closeModal();
      }
    } else {
    }
  };

  const onChange = (currentValue: string) => {
    const v = parseInt(currentValue);
    const em = { ...props.economic };
    em[stepKey] = v;
    props.setEconomicState(em);
  };

  return (
    <Content>
      <Header>
        <Img src={EconomicOverviewImg} />
      </Header>
      <Middle>
        <Title level={2}>{getTitleText()}</Title>
        <div> {getContentText()} </div>
        <MyInput
          value={props.economic[stepKey] == 0 ? '' : props.economic[stepKey]}
          onChange={(e) => onChange(e.currentTarget.value)}
        />
        {error && <Tag color="red">Invalid account</Tag>}
      </Middle>
      <Footer>
        <div>{getIndex()} of 4</div>
        <div>
          <Button onClick={nextStep}>{buttonText}</Button>
        </div>
      </Footer>
    </Content>
  );
};

export default EconomicContent;
