/**
 * Imports
 */
import React from 'react';
import { Button } from 'components';
import { VibrantIcon } from 'components/VibrantIcon';
import { firebaseGoogleLogin } from 'firebaseAPI';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'router/RootRouter';

import styled from 'styled-components';

/**
 * Styling
 */
const StyledButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.Background.GoogleLogin};

  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    margin-right: 12px;
  }
`;

/**
 * Types
 */
export interface GoogleLoginProps {}

/**
 * GoogleLogin component
 */
const GoogleLogin: React.FC<GoogleLoginProps> = (_props) => {
  const navigation = useHistory();
  /* Local state */
  const [isLoading, setIsLoading] = React.useState(false);

  const _handleGoogleLogin = async () => {
    setIsLoading(true);
    try {
      await firebaseGoogleLogin();
      setIsLoading(false);
      navigation.push(ROUTES.home.path);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <StyledButton
      type="primary"
      onClick={_handleGoogleLogin}
      block
      loading={isLoading}
    >
      <VibrantIcon icon="google" color="White000" />
      <Trans i18nKey="Auth.btn_google_login" />
    </StyledButton>
  );
};

/**
 * Exports
 */
export { GoogleLogin };
