/**
 * Imports
 */
import { Space, Text, VibrantIcon } from 'components';
import { useSingleSettlementApi } from 'features/settlements/hooks/useSingleSettlementApi';
import { settlementsSelector } from 'features/settlements/redux/selectors';
import { Currency, useMerchantApi } from 'features/shared';
import moment from 'moment';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { DATE_FORMATS, formatCurrency } from 'utils';

/**
 * Styling
 */
const Root = styled.div`
  margin: 48px 0;
  display: flex;
  flex-direction: column;
`;
const BoxRow = styled.div`
  display: flex;
  margin: 0 0 24px 0;
`;
const Box = styled.div<{ first?: boolean; last?: boolean }>`
  background-color: ${({ theme }) => theme.colors.Background.Primary};
  border: ${(props) =>
    props.last && '1px solid' + props.theme.colors.Base.Brand};
  border-left: none;
  height: 114px;
  position: relative;
  padding: ${({ first }) => (first ? '24px' : '24px 24px 24px 40px')};
  width: 25%;

  display: flex;
  flex-direction: column;

  & svg {
    color: ${(props) => props.last && props.theme.colors.Base.Brand}!important;
    fill: transparent;
  }
`;
const ArrowPointer = styled.div`
  position: absolute;
  top: 0;
  left: -48px;
  & svg {
    color: ${(props) => props.theme.colors.Border.BorderPrimary};
    fill: transparent;
  }
`;
// Payout information
const Row = styled.div`
  display: flex;
  align-items: center;
`;
const Entry = styled.div`
  display: flex;
  align-items: center;

  .vibranticon {
    margin-right: 12px;
  }
`;
const EntryRow = styled.div`
  display: flex;
  align-items: flex-end;
`;
/**
 * Types
 */
export interface PayoutNumbersProps {}

/**
 * PayoutNumbers component
 */
const PayoutNumbers: React.FC<PayoutNumbersProps> = (_props) => {
  const params = useParams<{ id: string }>();
  const settlement = useSelector(settlementsSelector);
  useSingleSettlementApi(params.id);
  const { merchantCollection } = useMerchantApi();

  if (!settlement[0]) {
    return null;
  }

  return (
    <Root>
      <BoxRow>
        {/* No trxn */}
        <Box first>
          <Text size="ui2" color="uiDark">
            <Trans i18nKey="SettlementDetails.summary_payout.number_of_transactions" />
          </Text>
          <Space size="xs" />
          <Text size="h5">
            {settlement[0].numberOfDebitTransactions +
              settlement[0].numberOfCreditTransactions}
          </Text>
        </Box>
        {/* Gross */}
        <Box>
          <Text size="ui2" color="uiDark">
            <Trans i18nKey="SettlementDetails.summary_payout.payout_sales" />
          </Text>
          <Space size="xs" />
          <EntryRow>
            <Text size="h5">
              {formatCurrency(settlement[0].grossAmount / 100)}
            </Text>
            <Space vertical size="sm" />
            <Currency />
          </EntryRow>
          <ArrowPointer>
            <VibrantIcon icon="arrow-pointer" size={114} />
          </ArrowPointer>
        </Box>
        {/* Refund */}
        <Box>
          <Text size="ui2" color="uiDark">
            <Trans i18nKey="SettlementDetails.summary_payout.payout_refund" />
          </Text>
          <Space size="xs" />
          <EntryRow>
            <Text size="h5">
              {formatCurrency(settlement[0].creditAmount / 100)}
            </Text>
            <Space vertical size="sm" />
            <Currency />
          </EntryRow>
          <ArrowPointer>
            <VibrantIcon icon="arrow-pointer" size={114} />
          </ArrowPointer>
        </Box>
        {/* Fees */}
        <Box>
          <Text size="ui2" color="uiDark">
            <Trans i18nKey="SettlementDetails.summary_payout.payout_fees" />
          </Text>
          <Space size="xs" />
          <EntryRow>
            <Text size="h5">
              {formatCurrency(settlement[0].settlementFeeAmount / 100)}
            </Text>
            <Space vertical size="sm" />
            <Currency />
          </EntryRow>
          <ArrowPointer>
            <VibrantIcon icon="arrow-pointer" size={114} />
          </ArrowPointer>
        </Box>
        {/* Payout */}
        <Box last>
          <Text size="ui2" color="uiDark">
            <Trans i18nKey="SettlementDetails.summary_payout.payout_amount" />
          </Text>
          <Space size="xs" />
          <EntryRow>
            <Text size="h5" color="brand">
              {formatCurrency(settlement[0].settlementAmount / 100)}
            </Text>
            <Space vertical size="sm" />
            <Currency />
          </EntryRow>
          <ArrowPointer>
            <VibrantIcon icon="arrow-pointer" size={114} />
          </ArrowPointer>
        </Box>
      </BoxRow>
      {/* Payout information */}
      <Row>
        <Entry>
          <VibrantIcon icon="card" size={20} color="Grey300" />
          <Text size="ui2" color="ui">
            <Trans i18nKey="SettlementDetails.summary_payout.payout_iban" />
          </Text>
          <Text size="ui2">&nbsp;{merchantCollection?.iban}</Text>
        </Entry>
        <Space vertical size="xxl" />
        {settlement[0]?.payoutDate && (
          <Entry>
            <VibrantIcon icon="watch" size={20} color="Grey300" />
            <Text size="ui2" color="ui">
              <Trans i18nKey="SettlementDetails.summary_payout.payout_date" />
            </Text>
            <Text size="ui2">
              &nbsp;
              {moment(settlement[0]?.payoutDate).format(DATE_FORMATS.date)}
            </Text>
          </Entry>
        )}
      </Row>
    </Root>
  );
};

/**
 * Exports
 */
export { PayoutNumbers };
