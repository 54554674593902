/**
 * Imports
 */
import * as React from 'react';

import styled from 'styled-components';

/**
 * Styled components
 */
const Flex = styled.div<LayoutProps>`
  display: flex;
  flex-direction: ${(props) => props.direction};
  align-items: ${(props) => props.align};
`;

/**
 * Types
 */
export type FlexDirection = 'row' | 'row-reverse' | 'column-reverse';
export interface LayoutProps {
  direction?: string;
  align?: string;
}

/**
 * Layout component
 */
const Layout: React.FC<LayoutProps> = ({ children, direction, align }) => {
  return (
    <Flex direction={direction || 'column'} align={align || 'center'}>
      {children}
    </Flex>
  );
};

/**
 * Exports
 */
export { Layout };
