/**
 * Imports
 */
import * as React from 'react';
import { Button, Alert, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import { firebaseResetPassword } from 'firebaseAPI';
import { Input, Space, Text } from 'components';
import * as Styles from './ForgotPasswordForm.styles';
import { ROUTES } from 'router/RootRouter';

/**
 * ForgotPasswordForm component
 */
const ForgotPasswordForm: React.FC = () => {
  const [email, setEmail] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const history = useHistory();
  const { t } = useTranslation();

  const handleResetPassword = async () => {
    try {
      setIsLoading(true);
      await firebaseResetPassword(email);

      history.push(ROUTES.login.path);
      message.success(t('ForgotPassword.msg_reset_password'));
    } catch (error) {
      setErrorMessage(error.message);
      setTimeout(() => setErrorMessage(null), 3000);
    } finally {
      setIsLoading(false);
    }
  };

  const _handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleResetPassword();
    }
  };

  return (
    <Styles.Root>
      <Space size="lg" />

      <Input
        label={t('Auth.labels.email')}
        placeholder="hello@example.com"
        value={email}
        onChange={(event) => setEmail(event.target.value)}
        onKeyDown={_handleKeyDown}
      />

      <Space size="xl" />

      <Button
        type="primary"
        onClick={handleResetPassword}
        loading={isLoading}
        block
      >
        <Text size="ui2" bold>
          <Trans i18nKey="ForgotPassword.btn_reset_password" />
        </Text>
      </Button>

      {errorMessage && <Alert type="error" message={errorMessage} />}
    </Styles.Root>
  );
};

/**
 * Exports
 */
export { ForgotPasswordForm };
