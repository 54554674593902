/**
 * Imports
 */
import { IntegrationCard, INTEGRATION_OPTIONS } from 'features/integrations';
import { BaseLayout } from 'features/navigation';
import {
  integrationCollectionSelector,
  userSelector,
} from 'features/user/redux/selectors';
import { FirebaseCollectionsType } from 'firebaseAPI';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import styled from 'styled-components';

/**
 * Styling
 */
const Root = styled.div`
  margin-top: 32px;
  display: flex;
`;

/**
 * IntegrationsPage component
 */
const IntegrationsPage: React.FC = () => {
  const user = useSelector(userSelector);
  const integrationCollection = useSelector(integrationCollectionSelector);

  useFirestoreConnect([
    {
      collection: FirebaseCollectionsType.MERCHANT_INTEGRATIONS,
      storeAs: FirebaseCollectionsType.MERCHANT_INTEGRATIONS,
      doc: user?.meta.merchantId,
    },
  ]);

  return (
    <BaseLayout>
      <Root>
        {Object.values(INTEGRATION_OPTIONS).map((integration) => {
          const hasIntegration = !!integrationCollection;
          return (
            <IntegrationCard
              key={integration.integrationId}
              hasIntegration={hasIntegration}
              integration={integration}
              integrationId={integration.integrationId}
            />
          );
        })}
      </Root>
    </BaseLayout>
  );
};

/**
 * Exports
 */
export { IntegrationsPage };
