/**
 * Imports
 */
import { getTransactionsApi } from 'apis';
import { meanBy, round, sumBy } from 'lodash';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { VibrantAPI } from 'types/vibrant';
import { setTrxnKeyNumbers } from '../redux/actions';
import {
  endDateSelector,
  startDateSelector,
  terminalIdSelector,
} from '../redux/selectors';
/**
 * types
 */

export type KeyNumbersAPIType = {
  isFecthing: boolean;
};

/**
 * useTrxnKeyNumbers API
 */
const useTrxnKeyNumbers = (): KeyNumbersAPIType => {
  const [isFecthing, setIsFetching] = React.useState(false);
  const dispatch = useDispatch();
  const startDate = useSelector(startDateSelector);
  const endDate = useSelector(endDateSelector);
  const terminalId = useSelector(terminalIdSelector);

  const fecthTrxnItems = React.useCallback(
    async (currentPage, pageSize) => {
      setIsFetching(true);
      try {
        const resp = (await getTransactionsApi(
          startDate,
          endDate,
          currentPage,
          pageSize,
        )) as VibrantAPI.GetTrxnsResult;

        const approvedTrxns = resp.trxns.filter((t) => t.status === 'approved');
        const newTrxns = terminalId
          ? approvedTrxns.filter((t) => t.terminalId === terminalId)
          : approvedTrxns;
        const trxnApprovedCount = newTrxns.length;
        const sumRaw = sumBy(newTrxns, (p) => p.amount);
        const trxnSum = round(sumRaw, 2);
        const averageRaw = meanBy(newTrxns, (p) => p.amount) || 0;
        const trxnAverage = Math.round(averageRaw * 100) / 100;

        dispatch(
          setTrxnKeyNumbers({
            trxnApprovedCount,
            trxnAverage,
            trxnSum,
          }),
        );
        setIsFetching(false);
      } catch (exception) {
        console.log('exception', exception);
        setIsFetching(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [endDate, startDate, terminalId],
  );

  React.useEffect(() => {
    fecthTrxnItems(1, 10000000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate, startDate, terminalId]);

  return { isFecthing };
};

/**
 * Exports
 */

export { useTrxnKeyNumbers };
