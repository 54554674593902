/**
 * Imports
 */
import { ColumnsType } from 'antd/lib/table/interface';
import { CreditCard, Table, Text, VibrantIcon } from 'components';
import { TransactionsStatus } from 'features/transactions';
import { useTransactionsApi } from 'features/transactions/api';
import { terminalCollectionSelector } from 'features/user/redux/selectors';
import moment from 'moment';
import React, { forwardRef } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'router/RootRouter';
import styled from 'styled-components';
import { SoftPos } from 'types/softpos';
import { DATE_FORMATS, formatCurrency } from 'utils';

/**
 *Styling
 */
export const Container = styled.div`
  margin-top: 48px;

  & .pinrequested {
    opacity: 0.5;
  }
  & .normal {
  }
`;

/**
 * Helpers
 */
const itemRender = (_current: any, type: any, originalElement: any) => {
  if (type === 'prev') {
    return <VibrantIcon icon="arrow-long-left" />;
  }
  if (type === 'next') {
    return <VibrantIcon icon="arrow-long-right" />;
  }
  return originalElement;
};

/**
 * Types
 */
export interface TransactionsTableProps {}

/**
 * CONST
 */
const DEFAULT_PAGINATED_PAGE_SIZE = 1000;

/**
 * TransactionsTable component
 */
const TransactionsTable = forwardRef<HTMLDivElement, TransactionsTableProps>(
  (props, ref) => {
    const navigation = useHistory();
    const terminalCollection = useSelector(terminalCollectionSelector);

    const {
      isFecthing,
      tableChangeHandler,
      pagination,
      filteredTrxns,
    } = useTransactionsApi();

    const hasNotes = filteredTrxns.some((trxn) => trxn.note);
    /**
     * Table columns
     */
    const columns: ColumnsType<SoftPos.Trxn> = [
      {
        title: () => <Trans i18nKey="Transactions.table_header.card_source" />,
        dataIndex: 'cardSource',
        key: 'cardSouce',
        responsive: ['sm'],
        render: (cardSource: SoftPos.CardSource) => {
          return <CreditCard rounded card={cardSource} />;
        },
      },
      {
        title: () => <Trans i18nKey="Transactions.table_header.amount" />,
        dataIndex: 'amount',
        key: 'amount',
        render: (amount: number, record: SoftPos.Trxn) => {
          return (
            <Text size="ui2">{formatCurrency(amount, record.currency)}</Text>
          );
        },
      },
      {
        title: () => <Trans i18nKey="Transactions.table_header.date" />,
        dataIndex: 'date',
        key: 'date',
        render: (date: string) => {
          return (
            <Text size="ui2">{moment(date).format(DATE_FORMATS.time)}</Text>
          );
        },
      },
      {
        title: () => <Trans i18nKey="Transactions.table_header.status" />,
        dataIndex: 'statusCode',
        key: 'statusCode',
        render: (_statusCode: SoftPos.TrxnStatusCode, record: SoftPos.Trxn) => {
          return <TransactionsStatus trxn={record} />;
        },
      },
      {
        title: () => (
          <Trans i18nKey="SettlementDetails.table_header.last_four" />
        ),
        dataIndex: 'cardNumber',
        key: 'cardNumber',
        render: (cardNumber: string) => {
          return (
            <Text size="ui2">
              **{cardNumber?.substring(cardNumber?.length - 4)}
            </Text>
          );
        },
      },
      {
        title: () => <Trans i18nKey="Transactions.table_header.terminal_id" />,
        dataIndex: 'terminalId',
        responsive: ['lg'],
        key: 'terminalId',
        render: (terminalId: string) => {
          return <Text size="ui2">{terminalId}</Text>;
        },
      },
      {
        title: () => (
          <Trans i18nKey="Transactions.table_header.terminal_name" />
        ),
        dataIndex: 'terminalId',
        key: 'terminalId',
        render: (terminalId: string) => {
          const name = terminalCollection.find(
            (terminal) => terminal.terminalId === terminalId,
          )?.name;
          return <Text size="ui2">{name}</Text>;
        },
      },
    ];
    if (hasNotes) {
      columns.push({
        title: () => 'Note',
        dataIndex: 'note',
        responsive: ['lg'],
        key: 'note',
        render: (note: string) => {
          return <Text size="ui2">{note}</Text>;
        },
      });
    }

    /**
     * OnRowClick
     */
    const _onRowClick = (trxnId: string) => {
      const path = `${ROUTES.transactionDetails.path}/${trxnId}`;
      navigation.push(path);
    };

    return (
      <Container ref={ref}>
        <Table<SoftPos.Trxn>
          columns={columns}
          dataSource={filteredTrxns}
          loading={isFecthing}
          pagination={{
            ...pagination,
            itemRender,
            showSizeChanger: false,
            showTotal: (total, range) => (
              <Trans
                i18nKey="_Common.pagination.total"
                values={{ rangeStart: range[0], rangeEnd: range[1], total }}
              />
            ),
            defaultPageSize: DEFAULT_PAGINATED_PAGE_SIZE,
          }}
          rowKey={(record) => record.trxnId}
          onChange={tableChangeHandler}
          rowClassName={(record: SoftPos.Trxn) => {
            const pinRequest =
              (record.responseCode === '70' && record.cardSource === 'VISA') ||
              (record.responseCode === '65' && record.cardSource === 'MC');
            return pinRequest ? 'pinrequested' : 'normal';
          }}
          onRow={(record) => {
            const id = record.trxnId;
            return {
              onClick: () => _onRowClick(id),
            };
          }}
        />
      </Container>
    );
  },
);

/**
 * Exports
 */
export { TransactionsTable };
