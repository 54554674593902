/**
 * Project API url
 */
const getProjectApiUrl = () => {
  const hostname = window.location.hostname;
  if (hostname.includes('dev')) {
    return 'https://api.dev.vibrant.one';
  }
  if (hostname.includes('uat')) {
    return 'https://api.uat.vibrant.one';
  }
  if (hostname.includes('me.vibrant.io')) {
    return 'https://api.vibrant.one';
  }
  /// should be used for localhost
  // return 'https://api.vibrant.one';
  // return 'https://api.uat.vibrant.one';
  return 'https://api.dev.vibrant.one';
};

export const PROJECT_API_URL = getProjectApiUrl();

const getFirebaseConfig = () => {
  /**
   * Uat
   */
  if (PROJECT_API_URL.includes('uat')) {
    console.log('Running uat');
    return {
      apiKey: 'AIzaSyDVDd4j87hxMN6VRnxKqV9Pv2ynjCDp_CI',
      appId: '1:922162229168:web:f0eac8ad6b4b76b65a7075',
      authDomain: 'vibrant-fb-uat.firebaseapp.com',
      databaseURL: 'https://vibrant-fb-uat.firebaseio.com',
      measurementId: 'G-NSSDJNP6GX',
      messagingSenderId: '922162229168',
      projectId: 'vibrant-fb-uat',
      storageBucket: 'vibrant-fb-uat.appspot.com',
    };
  }
  /**
   * Production
   */
  if (PROJECT_API_URL.includes('api.vibrant.one')) {
    console.log('Running production');
    return {
      apiKey: 'AIzaSyAbIbKSw_SmtYM8WLqs9ZUoFsb07q7i28o',
      appId: '1:836686013166:web:194693f30c3ecf32c409ff',
      authDomain: 'vibrantly-production.firebaseapp.com',
      databaseURL: 'https://vibrantly-production.firebaseio.com',
      measurementId: 'G-03B6DQFP5X',
      messagingSenderId: '836686013166',
      projectId: 'vibrantly-production',
      storageBucket: 'vibrantly-production.appspot.com',
    };
  }
  /**
   * Dev
   */
  console.log('Running dev');
  return {
    apiKey: 'AIzaSyCv-etousHNny0jlIiLLkXopYTyBJbeBLY',
    appId: '1:400993474174:web:aaff4627ca65a0e1d42a2f',
    authDomain: 'staging-727fe.firebaseapp.com',
    databaseURL: 'https://staging-727fe.firebaseio.com',
    measurementId: 'G-JZ5VW89G74',
    messagingSenderId: '400993474174',
    projectId: 'staging-727fe',
    storageBucket: 'staging-727fe.appspot.com',
  };
};
export const FIREBASE_CONFIG = getFirebaseConfig();

/**
 * Project environment settings
 */
const getProjectConfig = () => {
  if (PROJECT_API_URL.includes('uat')) {
    console.log('Running uat');
    return {
      amplitudeApiKey: 'f06c3339f4ff373b063eb551bc15f5b5',
      receiptsUrl: ' https://receipt.uat.vibrant.one',
      integrationUrl:
        'https://accounting-integration.uat.vibrant.one/dinero/cb',
      environment: 'uat',
      gitRelease: 'PENDING_1.0.0',
      sentryDsn:
        'https://1b825ec8ff674674aacf04abf42155d6@o551933.ingest.sentry.io/5773627',
      accountingUrl: 'https://economics.integrations.uat.vibrant.one',
    };
  }
  if (PROJECT_API_URL.includes('api.vibrant.one')) {
    console.log('Running production');
    return {
      amplitudeApiKey: '25975873e7a8d012252c35b6802e6888',
      receiptsUrl: 'https://receipt.vibrant.io',
      integrationUrl: 'https://accounting-integration.vibrant.one/dinero/cb',
      environment: 'production',
      gitRelease: 'PENDING_1.0.0',
      sentryDsn:
        'https://1b825ec8ff674674aacf04abf42155d6@o551933.ingest.sentry.io/5773627',
      accountingUrl: 'https://economics.integrations.prod.vibrant.one',
    };
  }
  console.log('Running dev');
  return {
    amplitudeApiKey: 'd90f089e70e77959bf28c8d922fc050f',
    receiptsUrl: 'https://receipt.dev.vibrant.one',
    integrationUrl: 'https://accounting-integration.dev.vibrant.one/dinero/cb',
    environment: 'localhost',
    gitRelease: 'PENDING_1.0.0',
    sentryDsn:
      'https://1b825ec8ff674674aacf04abf42155d6@o551933.ingest.sentry.io/5773627',
    accountingUrl: 'https://economics.integrations.dev.vibrant.one',
  };
};
export const PROJECT_CONFIG = getProjectConfig();
export const ACCOUNTING_API_URL = getProjectConfig().accountingUrl;
