import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { VibrantIcon } from '../VibrantIcon';
import { Text, Box } from 'components';

const StRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
`;

const StFlex = styled(Box)`
  display: flex;
  align-items: center;
`;

const LockScreenContent: React.FC = () => {
  const { t } = useTranslation();
  return (
    <StRow>
      <StFlex>
        <VibrantIcon icon="lock" color="Blue800" />
        <Text size="p2" bold textIndent="10px" color="inReview">
          {t('LockScreen.Common.status')}
        </Text>
        <Text size="p2" textIndent="4px" color="inReview">
          {t('LockScreen.InReview.in_review')}
        </Text>
      </StFlex>
      <StFlex>
        <Text size="h2" color="heading">
          {t('LockScreen.InReview.application_success')}
        </Text>
      </StFlex>
      <StFlex margin="24px 0">
        <Text size="ui5" color="paragraph">
          {t('LockScreen.InReview.description')}
        </Text>
      </StFlex>
      <StFlex>
        <Text size="ui5" color="black" bold>
          {t('LockScreen.Common.status')}
        </Text>
        <Text size="ui5" textIndent="4px" color="paragraph">
          {t('LockScreen.InReview.in_review')}
        </Text>
      </StFlex>
      <StFlex>
        <Text size="ui5" color="black" bold>
          {t('LockScreen.Common.merchant_id')}
        </Text>
        <Text size="ui5" textIndent="4px" color="paragraph">
          {t('LockScreen.InReview.not_issued')}
        </Text>
      </StFlex>
      <StFlex>
        <Text size="ui5" color="black" bold>
          {t('LockScreen.Common.terminal_id')}
        </Text>
        <Text size="ui5" textIndent="4px" color="paragraph">
          {t('LockScreen.InReview.not_issued')}
        </Text>
      </StFlex>
      <StFlex>
        <Text size="ui5" color="black" bold>
          {t('LockScreen.Common.activation_code')}
        </Text>
        <Text size="ui5" textIndent="4px" color="paragraph">
          {t('LockScreen.InReview.not_issued')}
        </Text>
      </StFlex>
      <StFlex marginTop="24px">
        <Text size="ui4" color="inReview">
          {t('LockScreen.InReview.help_text')}
        </Text>
      </StFlex>
    </StRow>
  );
};

export { LockScreenContent };
