import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { trxnItemsSelector } from 'features/transactions/redux/selectors';
import { terminalCollectionSelector } from 'features/user/redux/selectors';
import { ExportSelect } from 'features/shared';
import { SoftPos } from 'types/softpos';
import { DATE_FORMATS, formatCurrency } from 'utils';
import moment from 'moment';

export type TransactionsExportSelectProps = {
  printElementRef: React.MutableRefObject<HTMLDivElement | null>;
};

const TransactionsExportSelect: React.FC<TransactionsExportSelectProps> = (
  props,
) => {
  const transactionsItems = useSelector(trxnItemsSelector);
  const terminalCollection = useSelector(terminalCollectionSelector);
  const { t } = useTranslation();

  const hasNotes = transactionsItems.some((trxn) => trxn.note);

  const csvData = useMemo(() => {
    const data = [];

    const headers: {
      label: string;
      key: keyof (SoftPos.Trxn & { terminalName: string });
    }[] = [
      { label: t('Transactions.table_header.card_source'), key: 'cardSource' },
      { label: t('Transactions.table_header.fee'), key: 'fee' },
      { label: t('Transactions.table_header.amount'), key: 'amount' },
      { label: t('Transactions.table_header.date'), key: 'date' },
      {
        label: t('SettlementDetails.table_header.last_four'),
        key: 'cardNumber',
      },
      { label: t('Transactions.table_header.terminal_id'), key: 'terminalId' },
      {
        label: t('Transactions.table_header.terminal_name'),
        key: 'terminalName',
      },
    ];

    if (hasNotes) {
      headers.push({ label: 'note', key: 'note' });
    }

    for (const trxn of transactionsItems.filter(
      (t) =>
        t.statusCode === 'N' &&
        t.status === 'approved' &&
        t.responseCode === '00',
    )) {
      const temp: Record<string, string | number> = {};

      for (const header of headers) {
        if (header.key === 'date') {
          temp[header.key] = moment(trxn[header.key]).format(DATE_FORMATS.time);
        } else if (header.key === 'cardNumber') {
          temp[header.key] =
            '**' +
            trxn['cardNumber']?.substring(trxn['cardNumber']?.length - 4);
        } else if (header.key === 'terminalName') {
          const name = terminalCollection.find(
            (terminal) => terminal.terminalId === trxn['terminalId'],
          );
          temp[header.key] = name ? name.name : 'NA';
        } else if (header.key === 'amount') {
          const amount = formatCurrency(trxn?.amount);
          temp[header.key] = amount ? amount : '';
        } else {
          temp[header.key] = trxn[header.key];
        }
      }

      data.push(temp);
    }
    console.log(data);

    return { headers, data };
  }, [transactionsItems]);

  return (
    <ExportSelect
      filename="transactions"
      printElementRef={props.printElementRef}
      headers={csvData.headers}
      data={csvData.data}
    />
  );
};

export { TransactionsExportSelect };
