import styled from 'styled-components';

export const Row = styled.div`
  background-color: ${({ theme }) => theme.colors.Background.White};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 11px 24px;
  border-bottom: 1px dashed ${({ theme }) => theme.colors.Border.BorderPrimary};

  &.head {
    border-radius: 16px 16px 0 0;
    padding: 24px;
  }

  &:last-of-type {
    border-bottom: none;
    border-radius: 0 0 16px 16px;
  }

  .column {
    display: flex;
    flex-direction: row;
    align-items: center;

    &.name {
      width: 55%;
    }

    &.vat {
      width: 15%;
    }

    &.price {
      width: 15%;
    }

    &.actions {
      width: 15%;
      justify-content: space-around;
    }
  }
`;
