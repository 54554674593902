/**
 * Imports
 */
import { createReducer } from '@reduxjs/toolkit';
import { addedIntegration } from './actions';

/**
 * Types
 */
export interface integrationState {
  loading: boolean;
}

/**
 * Constants
 */
const INITIAL_STATE: integrationState = {
  loading: false,
};

/**
 * `integrations` reducer
 */
const integrationReducer = createReducer(INITIAL_STATE, {
  /**
   * ADDED_INTEGRATION
   */
  [addedIntegration.type]: (state) => {
    state.loading = true;
  },
});

/**
 * Exports
 */
export default integrationReducer;
