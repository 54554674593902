import styled from 'styled-components';

import { Text } from 'components';

export const SelectButton = styled.div`
  cursor: pointer;
  border-radius: 4px;

  display: flex;
  align-items: center;
`;

export const Selection = styled.div`
  display: flex;
  align-items: center;
`;
export const StyledText = styled(Text)`
  margin-right: 8px;
`;
