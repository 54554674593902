/**
 * Imports
 */
import { getSettlementApi } from 'apis';
import { userSelector } from 'features/user/redux/selectors';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSettlements } from '../redux/actions';

/**
 * Types
 */
type UsePostingsReturnType = {
  isFecthing: boolean;
};

/**
 * useSingleSettlementApi component
 */
const useSingleSettlementApi = (postingsId: string): UsePostingsReturnType => {
  const [isFecthing, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const user = useSelector(userSelector);

  const fecthPostings = React.useCallback(
    async () => {
      setLoading(true);
      try {
        const resp = await getSettlementApi(user.meta.merchantId, postingsId);
        dispatch(
          getSettlements({
            settlements: [resp],
            settlementsCount: 1,
          }),
        );
        setLoading(false);
      } catch (exception) {
        console.log('Fetching postings failed: ', exception);
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  React.useEffect(() => {
    fecthPostings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postingsId]);

  return { isFecthing };
};

/**
 * Exports
 */
export { useSingleSettlementApi };
