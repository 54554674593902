/**
 * Imports
 */
import { createReducer } from '@reduxjs/toolkit';
import { SoftPos } from 'types/softpos';
import { FixedDatesRange } from 'features/shared/types';
import moment from 'moment';
import { Moment } from 'moment';

import {
  setDates,
  SetDatesAction,
  getSettlements,
  getPostings,
  GetSettlementsAction,
  GetPostingsAction,
  getCurrentBalance,
  CurrentBalanceAction,
} from './actions';

/**
 * Types
 */
export interface TrxnState {
  currentBalance: SoftPos.Settlement[];
  settlements: SoftPos.Settlement[];
  postings: SoftPos.Posting[];
  settlementsCount: number;
  startDate: Moment;
  endDate: Moment;
  fixedRange: FixedDatesRange;
}

/**
 * Constants
 */
const INITIAL_STATE: TrxnState = {
  currentBalance: [],
  settlements: [],
  postings: [],
  settlementsCount: 0,
  startDate: moment().startOf('month').startOf('day'),
  endDate: moment().endOf('day'),
  fixedRange: FixedDatesRange.MONTH_TO_DATE,
};

/**
 * `settlements` reducer
 */
const settlementsReducer = createReducer(INITIAL_STATE, {
  /**
   * SET_DATES
   */
  [setDates.type]: (state, action: SetDatesAction) => {
    state.endDate = action.payload.endDate;
    state.startDate = action.payload.startDate;
    state.fixedRange = action.payload.fixedRange;
  },
  /**
   * GET_SETTLEMENTS
   */
  [getSettlements.type]: (state, action: GetSettlementsAction) => {
    state.settlements = action.payload.settlements;
    state.settlementsCount = action.payload.settlementsCount;
  },
  /**
   * CURRENT_BALANCE
   */
  [getCurrentBalance.type]: (state, action: CurrentBalanceAction) => {
    state.currentBalance = action.payload.settlements;
  },
  /**
   * GET_POSTINGS
   */
  [getPostings.type]: (state, action: GetPostingsAction) => {
    state.postings = action.payload.postings;
  },
});

/**
 * Exports
 */
export default settlementsReducer;
