import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { ProductTable } from '../ProductTable';
import { AddProductModal } from 'features/products/components';
import { NoProducts } from '../NoProducts';
import { useCollection } from 'react-firebase-hooks/firestore';
import { getFirebaseInstance } from 'firebaseAPI';
import {
  merchantCollectionSelector,
  userSelector,
} from 'features/user/redux/selectors';
import { useSelector } from 'react-redux';
import { productModalOpen } from 'features/products/redux/selectors';
import { orderBy } from 'lodash';
import { vatMap } from 'features/shared/utils/constants';

const Container = styled.section`
  width: 100%;
  height: 100%;
`;

export const ProductList: FC = () => {
  const { meta } = useSelector(userSelector);
  const merchant = useSelector(merchantCollectionSelector);
  const db = getFirebaseInstance().firestore();
  const [value, loading, error] = useCollection(
    db.collection(`products/${meta.merchantId}/productList`),
  );
  const open = useSelector(productModalOpen);

  if (error) {
    console.log(error);
  }

  const products = useMemo(
    () =>
      orderBy(
        value?.docs.map((doc) => {
          const data = doc.data();
          return {
            archivedAt: data?.archivedAt || null,
            id: doc.id,
            name: data.name,
            price: data.price,
            vat: data.vat,
          };
        }),
        [(p) => p?.name?.toLowerCase()],
        ['asc'],
      ),
    [value],
  );

  if (loading) {
    return null;
  }

  return (
    <>
      <AddProductModal
        vat={vatMap[merchant.countryCode].map((v) => v.toString())}
        visible={open}
      />
      <Container>
        {(products?.length ?? 0) > 0 && (
          <ProductTable products={products ?? []} />
        )}
        {(products?.length ?? 0) === 0 && <NoProducts />}
      </Container>
    </>
  );
};
