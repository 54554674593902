/**
 * Imports
 */
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import userFeature from 'features/user';
import { getFirebaseInstance, getUser } from 'firebaseAPI';
import { ProviderProps } from 'react-redux';
import { actionTypes } from 'react-redux-firebase';
import { rootReducer } from './reducer';
import { amplitudeMiddleware } from './middleware/amplitude';

/**
 * Functions
 */
const storePromise: Promise<ProviderProps['store']> = new Promise(
  (resolve, reject) => {
    try {
      const store = configureStore({
        reducer: rootReducer,
        middleware: [
          /**
           * We need to disable the serialization check here because of Firebase's
           * auth object. See this link for more info:
           *
           * https://github.com/prescottprue/react-redux-firebase/issues/761
           */
          ...getDefaultMiddleware({
            serializableCheck: {
              ignoredActions: [actionTypes.LOGIN],
            },
          }),
          amplitudeMiddleware,
        ],
      });
      const firebase = getFirebaseInstance();
      firebase.auth().onAuthStateChanged(async (authState) => {
        if (!authState) {
          store.dispatch(userFeature.redux.actions.logout({}));
        }
        if (authState) {
          const user = await getUser(authState.uid);
          if (user) {
            store.dispatch(userFeature.redux.actions.userUpdated(user));
          }
          resolve(store);
        } else {
          resolve(store);
        }
      });
    } catch (error) {
      reject(error);
    }
  },
);
/**
 * Redux store
 */
export { storePromise };
