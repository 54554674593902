/**
 * Imports
 */
import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, Icon, Input, Label, Space } from 'components';
import { ActiveTerminals, useTerminal } from 'features/terminals';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Text } from 'components';
import styled from 'styled-components';

/**
 * Styling
 */
const Root = styled.div`
  margin-top: 48px;
`;
const Main = styled.div`
  display: grid;
  grid-gap: 32px;
  grid-template-columns: 3fr 1fr;
`;
const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.Background.Primary};
  padding: 24px;

  display: flex;
  align-items: flex-end;
`;
const InputWrapper = styled.div`
  width: 100%;
  max-width: 320px;
`;
const ButtonWrapper = styled.div`
  .anticon {
    margin-right: 8px;
  }
`;

/**
 * Types
 */
export interface AddTerminalProps {}

/**
 * AddTerminal component
 */
const AddTerminal: React.FC<AddTerminalProps> = (props) => {
  const {
    handlers: { onCreateTerminal },
    isLoading,
  } = useTerminal();
  const { t } = useTranslation();
  /* Local state */
  const [value, setValue] = React.useState('');
  const [valid, setValid] = React.useState(false);

  const _onAddTerminal = async () => {
    await onCreateTerminal(value);
    setValue('');
    setValid(false);
  };

  const _onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setValue(event.target.value);
    setValid(event.target.value.length > 1 ? true : false);
  };

  return (
    <Root>
      <Text size="ui2" bold>
        Setup your new terminal
      </Text>
      <Space />
      <Main>
        <Container>
          <InputWrapper>
            <Label text={'Terminal name'} />
            <Input
              placeholder={'My terminal'}
              value={value}
              onChange={_onChange}
            />
          </InputWrapper>
          <Space vertical size="xs" />
          <ButtonWrapper>
            <Button
              loading={isLoading}
              disabled={!valid}
              onClick={_onAddTerminal}
              type="primary"
              icon={<Icon icon={<PlusOutlined />} color="White000" />}
            >
              Add terminal
            </Button>
          </ButtonWrapper>
        </Container>
        <ActiveTerminals />
      </Main>
    </Root>
  );
};

/**
 * Exports
 */
export { AddTerminal };
