/**
 * Imports
 */
import * as React from 'react';

import { Logo, Spinner, Text } from 'components';
import { VibrantColors } from 'theme';
import styled from 'styled-components';
import { Trans } from 'react-i18next';

/**
 * Styling
 */
const Root = styled.div`
  background-color: ${VibrantColors.Background.White};
  min-height: 100vh;
  width: 100%;
  padding: 24px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`;
const Main = styled.div`
  /* margin-top: -200px; */
`;
const Content = styled.div`
  margin-top: 32px;
`;

/**
 * Types
 */
export interface SplashScreenProps {}

/**
 * SplashScreen component
 */
const SplashScreen: React.FC<SplashScreenProps> = (_props) => {
  return (
    <Root>
      <Main>
        <Spinner showSpinner size={48} />
        <Content>
          <Text size="h4">
            <Trans i18nKey="_Common.loading.heading_splash_screen" />{' '}
          </Text>
        </Content>
      </Main>
    </Root>
  );
};

/**
 * Exports
 */
export { SplashScreen };
