import { Button, Space, Text, VibrantIcon } from 'components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  closeProductModal,
  openProductModal,
} from 'features/products/redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { productModalOpen } from 'features/products/redux/selectors';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 360px;
`;

const Preview = styled.div`
  border: 1.5px dashed ${({ theme }) => theme.colors.Border.BorderSecondary};
  border-radius: 32px;
  padding: 24px;
`;

const PreviewItem = styled.div`
  border-radius: 16px;
  padding: 16px 18px;
  background-color: ${({ theme }) => theme.colors.Background.White};
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  &:last-of-type {
    margin-bottom: 0;
  }

  .left {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }

  .right {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${({ theme }) => theme.colors.Background.Primary};
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }
  }
`;

const Footer = styled.div`
  margin-top: 32px;
  text-align: center;
  padding: 0 8px;

  button {
    border-radius: 28px;
    margin: 24px auto 0;
  }
`;

export const NoProducts: FC = () => {
  const dispatch = useDispatch();
  const modalOpened = useSelector(productModalOpen);
  const { t } = useTranslation();

  const toggleModal = () => {
    dispatch(modalOpened ? closeProductModal({}) : openProductModal({}));
  };

  return (
    <Container>
      <Wrapper>
        <Preview>
          <PreviewItem>
            <div className="left">
              <Text size="ui2" bold>
                {t('Products.NoProducts.product_placeholder_1_title')}
              </Text>
              <Space size="xs" />
              <Text size="ui2" color={'ui'}>
                {t('Products.NoProducts.product_placeholder_1_price')}
              </Text>
            </div>
            <div className="right">
              <span>
                <VibrantIcon size={24} icon="plus" />
              </span>
            </div>
          </PreviewItem>
          <PreviewItem>
            <div className="left">
              <Text size="ui2" bold>
                {t('Products.NoProducts.product_placeholder_2_title')}
              </Text>
              <Space size="xs" />
              <Text size="ui2" color={'ui'}>
                {t('Products.NoProducts.product_placeholder_2_price')}
              </Text>
            </div>
            <div className="right">
              <span>
                <VibrantIcon size={24} icon="plus" />
              </span>
            </div>
          </PreviewItem>
          <PreviewItem>
            <div className="left">
              <Text size="ui2" bold>
                {t('Products.NoProducts.product_placeholder_3_title')}
              </Text>
              <Space size="xs" />
              <Text size="ui2" color={'ui'}>
                {t('Products.NoProducts.product_placeholder_3_price')}
              </Text>
            </div>
            <div className="right">
              <span>
                <VibrantIcon size={24} icon="plus" />
              </span>
            </div>
          </PreviewItem>
        </Preview>
        <Footer>
          <Text size="h5">{t('Products.NoProducts.product_list')}</Text>
          <Text size="ui2">
            {t('Products.NoProducts.no_products_placeholder')}
          </Text>
          <Button type="primary" onClick={() => toggleModal()}>
            {t('Products.Common.add_product')}
          </Button>
        </Footer>
      </Wrapper>
    </Container>
  );
};
