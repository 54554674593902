/**
 * Imports
 */
// Sentry imports
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { PROJECT_CONFIG } from 'config';
import { SplashScreen } from 'features/navigation';
import {
  dashboardSettingsSelector,
  merchantCollectionSelector,
  userSelector,
} from 'features/user/redux/selectors';
import firebase from 'firebase/app';
import { FirebaseCollectionsType } from 'firebaseAPI';
import React from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Provider, ProviderProps, useSelector } from 'react-redux';
// Firebase
import {
  ReactReduxFirebaseProvider,
  useFirestoreConnect,
} from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';
import { RootRouter } from 'router/RootRouter';
import { ThemeProvider } from 'theme';
// Global style
import './index.css';
import reportWebVitals from './reportWebVitals';
// Store setup
import { storePromise } from './store';
// Antd style overrides
import './styles/antd/less/antd-overrides.less';
// Font
import './styles/fonts/basier.css';
// translate config
import './translations/config';
import { Bold, Notification } from 'components/Notification';

/**
 * Sentry setup
 */
Sentry.init({
  enabled: PROJECT_CONFIG.environment === 'production',
  dsn: PROJECT_CONFIG.sentryDsn,
  environment: PROJECT_CONFIG.environment,
  integrations: [new Integrations.BrowserTracing()],
  release: PROJECT_CONFIG.gitRelease,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

/**
 * CONST
 */
const REACT_REDUX_FIREBASE_CONFIG = {
  // userProfile: 'fooUsers',
  // useFirestoreForProfile: true,
};

/**
 * `NonAuthenticatedContent` component
 */
const NonAuthenticatedContent = () => {
  return <RootRouter />;
};
/**
 * `AuthenticatedContent` component
 */
const AuthenticatedContent = () => {
  const merchant = useSelector(merchantCollectionSelector);
  const settings = useSelector(dashboardSettingsSelector);
  const user = useSelector(userSelector);
  const { i18n } = useTranslation();

  useFirestoreConnect([
    {
      collection: FirebaseCollectionsType.SOFTPOS_DASHBOARD_SETTINGS,
      storeAs: FirebaseCollectionsType.SOFTPOS_DASHBOARD_SETTINGS,
      doc: user?.id,
    },
  ]);
  useFirestoreConnect([
    {
      collection: FirebaseCollectionsType.MERCHANTS,
      storeAs: FirebaseCollectionsType.MERCHANTS,
      doc: user?.meta?.merchantId,
    },
  ]);

  React.useEffect(() => {
    if (settings?.language) {
      i18n.changeLanguage(settings.language);
    }
  }, [settings?.language]);

  // We wait for settings and merchant collection to be loaded
  if (!settings && !merchant) {
    return <SplashScreen />;
  }

  return <RootRouter />;
};

/**
 * `App` component
 */
const App = () => {
  /* Initialize hooks */
  useTranslation();
  /* Local state */
  const [store, setStore] = React.useState<ProviderProps['store'] | null>(null);
  const [isAuthenticationLoaded, setIsAuthenticationLoaded] = React.useState(
    false,
  );
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);

  /**
   * Initialize redux store
   */
  React.useEffect(() => {
    storePromise
      .then((_store) => setStore(_store))
      .catch(() => console.log('Error occurred getting store ready'));
  }, []);

  /**
   * Listen to onAuthStateChanged
   */
  React.useEffect(() => {
    if (firebase) {
      firebase.auth().onAuthStateChanged(async (authUser) => {
        if (authUser) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
        setIsAuthenticationLoaded(true);
      });
    }
  }, []);

  if (!store) {
    return <SplashScreen />;
  }
  if (!isAuthenticationLoaded) {
    return <SplashScreen />;
  }

  /* Firebase setup*/
  const reactReduxFirebaseProps = {
    firebase,
    config: REACT_REDUX_FIREBASE_CONFIG,
    dispatch: store.dispatch,
    createFirestoreInstance,
  };

  return (
    <React.StrictMode>
      <ReactReduxFirebaseProvider {...reactReduxFirebaseProps}>
        <ThemeProvider>
          <Provider store={store}>
            {isAuthenticated && <AuthenticatedContent />}
            {!isAuthenticated && <NonAuthenticatedContent />}
          </Provider>
        </ThemeProvider>
      </ReactReduxFirebaseProvider>
    </React.StrictMode>
  );
};

/**
 * Entry point
 */
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
