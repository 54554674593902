/**
 * Imports
 */
import { VibrantIcon } from 'components';
import { VibrantIconTypes } from 'components/VibrantIcon/VibrantIcon';
import * as React from 'react';
import { css } from 'styled-components';

import styled from 'styled-components';
import { SoftPos } from 'types/softpos';
import { StringLiteralPick } from 'utils';

/**
 * Styling
 */
const CARD_CSS: { [key in CardSizes]: any } = {
  lg: css`
    height: 42px;
    width: 64px;
  `,
  md: css`
    height: 30px;
    width: 48px;
  `,
  sm: css`
    height: 22px;
    width: 32px;
  `,
};
const Rectangle = styled.div<{ size: keyof typeof CardSizes }>`
  background-color: ${({ theme }) => theme.colors.Background.Secondary};
  border-radius: 4px;
  ${({ size }) => size && CARD_CSS[size]}

  display: flex;
  justify-content: center;
  align-items: center;
`;
const Rounded = styled.div<{ card: CardSourceType }>`
  background-color: ${({ theme, card }) =>
    card === 'VISA'
      ? theme.colors.Shades.Blue100
      : theme.colors.Shades.Orange100};
  border-radius: 50%;
  height: 48px;
  width: 48px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

/**
 * Types
 */
export type CardSourceType = SoftPos.LongScheme;
export type CardTypes = StringLiteralPick<
  VibrantIconTypes,
  'visa' | 'master-card'
>;
export type CardSingleTypes = StringLiteralPick<
  VibrantIconTypes,
  'visa-single' | 'master-card-single'
>;
export type CardLookupType = { [key in CardSourceType]: CardTypes };
export type CardSingleLookupType = {
  [key in CardSourceType]: CardSingleTypes;
};
const CARD_MAPPER: CardLookupType = {
  MC: 'master-card',
  VISA: 'visa',
  M: 'master-card',
  V: 'visa',
};
const CARD_MAPPER_SINGLE: CardSingleLookupType = {
  MC: 'master-card-single',
  VISA: 'visa-single',
  M: 'master-card-single',
  V: 'visa-single',
};
// export type CardSizes = 'sm' | 'md' | 'lg';

export enum CardSizes {
  sm = 'sm',
  md = 'md',
  lg = 'lg',
}
export interface CreditCardProps {
  card: CardSourceType;
  size?: keyof typeof CardSizes;
  rounded?: boolean;
}

/**
 * CreditCard component
 */
const CreditCard: React.FC<CreditCardProps> = ({ card, size, rounded }) => {
  return (
    <>
      {rounded ? (
        <Rounded card={card}>
          <VibrantIcon icon={CARD_MAPPER_SINGLE[card]} size={20} />
        </Rounded>
      ) : (
        <Rectangle size={size ? size : 'md'}>
          <VibrantIcon
            icon={CARD_MAPPER[card]}
            size={size === 'sm' ? 12 : size === 'lg' ? 24 : 20}
          />
        </Rectangle>
      )}
    </>
  );
};

/**
 * Exports
 */
export { CreditCard };
