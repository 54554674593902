/**
 * Imports
 */
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/firestore';
import { FIREBASE_CONFIG } from 'config';

/**
 * Constants
 */

let _firebaseRef: typeof firebase | null = null;
const getFirebaseInstance = (): typeof firebase => {
  if (!_firebaseRef) {
    firebase.initializeApp(FIREBASE_CONFIG);
    firebase.analytics();
    _firebaseRef = firebase;
  }
  return _firebaseRef;
};

/**
 * Exports
 */
export { getFirebaseInstance };
