/**
 * Imports
 */
import { createReducer } from '@reduxjs/toolkit';
import { SoftPos } from 'types/softpos';
import { Firebase } from 'types/firebase';
import { FixedDatesRange } from 'features/shared/types';
import moment from 'moment';
import { Moment } from 'moment';

import {
  GetTransctionsAction,
  getTransactions,
  setDates,
  SetDatesAction,
  GetTransctionAction,
  getSingleTransaction,
  setTrxnKeyNumbers,
  SetKeyNumbersAction,
  filterTransactions,
  FilterTransctionsAction,
} from './actions';

/**
 * Types
 */
export interface TrxnState {
  endDate: Moment;
  fixedRange: FixedDatesRange;
  startDate: Moment;
  trxnApprovedCount: number;
  trxnAverage: number;
  trxnCount: number;
  trxnItem: SoftPos.Trxn[];
  trxnOrderItems: Firebase.OrderItem[];
  trxnItems: SoftPos.Trxn[];
  trxnSum: number;
  terminalId: string | null;
}

/**
 * Constants
 */
const INITIAL_STATE: TrxnState = {
  endDate: moment().endOf('day'),
  fixedRange: FixedDatesRange.TODAY,
  startDate: moment().startOf('day'),
  terminalId: null,
  trxnApprovedCount: 0,
  trxnAverage: 0,
  trxnCount: 0,
  trxnItem: [],
  trxnOrderItems: [],
  trxnItems: [],
  trxnSum: 0,
};

/**
 * `transacations` reducer
 */
const transacationsReducer = createReducer(INITIAL_STATE, {
  /**
   * SET_DATES
   */
  [setDates.type]: (state, action: SetDatesAction) => {
    state.endDate = action.payload.endDate;
    state.startDate = action.payload.startDate;
    state.fixedRange = action.payload.fixedRange;
  },
  /**
   * GET_TRANSACTION
   */
  [getSingleTransaction.type]: (state, action: GetTransctionAction) => {
    state.trxnItem = action.payload.trxn;
    state.trxnOrderItems = action.payload?.trxnOrderItems || [];
  },
  /**
   * GET_TRANSACTIONS
   */
  [getTransactions.type]: (state, action: GetTransctionsAction) => {
    state.trxnItems = action.payload.trxnItems;
    state.trxnCount = action.payload.trxnCount;
  },
  /**
   * FILTER_TRANSACTIONS
   */
  [filterTransactions.type]: (state, action: FilterTransctionsAction) => {
    state.terminalId = action.payload.terminalId;
  },
  /**
   * SET_KEY_NUMBERS
   */
  [setTrxnKeyNumbers.type]: (state, action: SetKeyNumbersAction) => {
    state.trxnApprovedCount = action.payload.trxnApprovedCount;
    state.trxnAverage = action.payload.trxnAverage;
    state.trxnSum = action.payload.trxnSum;
  },
});

/**
 * Exports
 */
export default transacationsReducer;
