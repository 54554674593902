/**
 * Imports
 */
import { TableOptionsBar } from 'components';
import { Breadcrumb, ImportantNote, MobileMenu } from 'features/navigation';
import {
  isLink4PaySelector,
  maybeUserSelector,
  terminalCollectionSelector,
} from 'features/user/redux/selectors';
import useBreakpoint from 'hooks/useBreakpoint';
import * as React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Footer, FooterLink4Pay } from '../Footer';
import { Sidebar } from '../Sidebar';
import { LockScreen } from 'components';
import { useLocation } from 'react-router-dom';

/* Layout for overlay */
// const Wrapper = styled.div`
//   display: flex;
//   flex: 1;
//   justify-content: center;
//   align-items: center;
//   left: 0;
//   z-index: 99999999;
// `;
// const Notis = styled.div`
//   background-color: ${({ theme }) =>
//     theme.colors.Background.LoginTestimonal};
//   padding: 64px;
//   border-radius: 4px;
// `;
// const Overlay = styled.div`
//   opacity: 0.25;
//   height: 100vh;
//   width: 100vw;
//   pointer-events: none;

//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   z-index: 9999999;

//   display: flex;
//   flex: 1;
// `;
const Root = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
const Body = styled.div`
  display: flex;
  flex: 1;
`;
const Main = styled.div`
  height: 100%;
  padding: 48px 48px 0px 48px;

  display: flex;
  flex-direction: column;
  flex: 1;

  @media (max-width: 600px) {
    padding: 24px 24px 0px 24px;
    max-width: 100vw;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Content = styled.div`
  width: 100%;
  align-self: center;
  flex: 1;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
/**
 * Types
 */
export interface BaseLayoutProps {
  disableBreadcrumb?: boolean;
  options?: JSX.Element[];
}

/**
 * BaseLayout component
 */
const BaseLayout: React.FC<BaseLayoutProps> = ({
  children,
  disableBreadcrumb,
  options,
}) => {
  const user = useSelector(maybeUserSelector);
  const isLink4Pay = useSelector(isLink4PaySelector);
  const terminalCollection = useSelector(terminalCollectionSelector);
  const { isXs, isSm } = useBreakpoint();
  const location = useLocation();
  const shouldShowSidebar = !isXs && !isSm;
  const hasTerminals =
    terminalCollection &&
    terminalCollection.length &&
    terminalCollection.length > 0;

  return (
    <Root>
      <Body>
        {user && shouldShowSidebar && <Sidebar />}
        <Column>
          {user && !shouldShowSidebar && <MobileMenu />}

          {!hasTerminals && location.pathname === '/' ? (
            <LockScreen />
          ) : (
            <Main>
              <TitleContainer>
                {!disableBreadcrumb && <Breadcrumb />}
              </TitleContainer>
              {options != null && <TableOptionsBar options={options} />}
              <Content>{children}</Content>
              {isLink4Pay ? <FooterLink4Pay /> : <Footer />}
            </Main>
          )}
        </Column>
      </Body>
    </Root>
  );
};

BaseLayout.defaultProps = {
  disableBreadcrumb: false,
};

/**
 * Exports
 */
export { BaseLayout };
