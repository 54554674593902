/**
 * Imports
 */
import styled from 'styled-components';
import {
  alignItems,
  AlignItemsProps,
  alignSelf,
  AlignSelfProps,
  border,
  BorderProps,
  color,
  ColorProps,
  flexbox,
  flexDirection,
  FlexDirectionProps,
  FlexProps,
  fontFamily,
  flexGrow,
  FlexGrowProps,
  FontFamilyProps,
  justifyContent,
  JustifyContentProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from 'styled-system';

/**
 * Types
 */

type BoxProps = BorderProps &
  FlexDirectionProps &
  FlexProps &
  FlexGrowProps &
  LayoutProps &
  SpaceProps &
  AlignItemsProps &
  JustifyContentProps &
  ColorProps &
  FontFamilyProps &
  AlignSelfProps;

/**
 * Styling
 */
const Box = styled.div<BoxProps>`
  ${border}
  ${color}
  ${flexbox}
  ${flexGrow}
  ${flexDirection}
  ${alignItems}
  ${alignSelf}
  ${fontFamily}
  ${justifyContent}
  ${space}
  ${layout}
`;

/**
 * Exports
 */
export { Box };
