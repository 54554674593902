/**
 * Imports
 */
import { createAction } from '@reduxjs/toolkit';
import { VibrantlyAction } from 'store/types';
import { Transactions } from '../types';

/**
 * Constants
 */
const PREFIX = 'transactions';

/**
 * GET_TRANSACTIONS
 */
export const GET_TRANSACTIONS = `${PREFIX}/GET_TRANSACTIONS`;
export type GetTransctionsAction = VibrantlyAction<
  Transactions.GetTransctionsPayload
>;
export const getTransactions = createAction<GetTransctionsAction['payload']>(
  GET_TRANSACTIONS,
);
/**
 * FILTER_TRANSACTIONS
 */
export const FILTER_TRANSACTIONS = `${PREFIX}/FILTER_TRANSACTIONS`;
export type FilterTransctionsAction = VibrantlyAction<
  Transactions.FilterTransctionPayload
>;
export const filterTransactions = createAction<
  FilterTransctionsAction['payload']
>(FILTER_TRANSACTIONS);
/**
 * GET_TRANSACTION
 */
export const GET_TRANSACTION = `${PREFIX}/GET_TRANSACTION`;
export type GetTransctionAction = VibrantlyAction<
  Transactions.GetTransctionPayload
>;
export const getSingleTransaction = createAction<
  GetTransctionAction['payload']
>(GET_TRANSACTION);
/**
 * SET_KEY_NUMBERS
 */
export const SET_KEY_NUMBERS = `${PREFIX}/SET_KEY_NUMBERS`;
export type SetKeyNumbersAction = VibrantlyAction<
  Transactions.SetKeyNumbersPayload
>;
export const setTrxnKeyNumbers = createAction<SetKeyNumbersAction['payload']>(
  SET_KEY_NUMBERS,
);
/**
 * SET_DATES
 */
export const SET_DATES = `${PREFIX}/SET_DATES`;
export type SetDatesAction = VibrantlyAction<Transactions.SetDatesPayload>;
export const setDates = createAction<SetDatesAction['payload']>(SET_DATES);
