/**
 * Imports
 */
import {
  IntegrationsPage,
  IntegrationCallbackPage,
} from 'features/integrations';
import { HomePage } from 'features/overview';
import { ProductsPage } from 'features/products/pages';
import { SettlementDetailsPage, SettlementsPage } from 'features/settlements';
import { TerminalsPage } from 'features/terminals';
import {
  TransactionDetailsPage,
  TransactionsPage,
} from 'features/transactions';
import {
  AccountPage,
  ForgotPasswordPage,
  LoginPage,
  LogoutPage,
  TokenLoginPage,
  VerifyEmail,
} from 'features/user/pages';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';

/**
 * Styling
 */
const Root = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.Background.White};

  height: 100%;
  min-height: 100%;
`;

/**
 * Types
 */
export interface RootRouterProps {}

export type RoutesType =
  | 'account'
  | 'home'
  | 'login'
  | 'logout'
  | 'forgotPassword'
  | 'integrations'
  | 'settlements'
  | 'settlementDetails'
  | 'terminals'
  | 'transactionDetails'
  | 'transactions'
  | 'tokenLogin'
  | 'verifyEmail'
  | 'products'
  | 'guides';

export type RouteLookup = {
  [k in RoutesType]: RouteType;
};

export type RouteType = {
  pageName: () => string | React.ReactNode;
  component: React.FC;
  path: string;
  route: string;
  isPrivate: boolean;
  dontCheckAuth?: boolean;
};

/**
 * ROUTES
 */
export const ROUTES: RouteLookup = {
  home: {
    pageName: (): string | React.ReactNode => (
      <Trans i18nKey="_Common.breadcrumb.home" />
    ),
    component: HomePage,
    path: '/',
    route: '/',
    isPrivate: true,
  },
  tokenLogin: {
    pageName: (): string | React.ReactNode => (
      <Trans i18nKey="_Common.breadcrumb.token_login" />
    ),
    component: TokenLoginPage,
    path: '/tokenLogin',
    route: '/tokenLogin/:token',
    isPrivate: false,
  },
  logout: {
    pageName: (): string | React.ReactNode => '',
    component: LogoutPage,
    dontCheckAuth: true,
    isPrivate: false,
    path: '/logout',
    route: '/logout',
  },
  verifyEmail: {
    pageName: (): string | React.ReactNode => (
      <Trans i18nKey="_Common.breadcrumb.verify_email" />
    ),
    component: VerifyEmail,
    path: '/verify-email',
    route: '/verify-email/:token',
    isPrivate: false,
    dontCheckAuth: true,
  },
  login: {
    pageName: (): string | React.ReactNode => (
      <Trans i18nKey="_Common.breadcrumb.login" />
    ),
    component: LoginPage,
    path: '/login',
    route: '/login',
    isPrivate: false,
  },
  forgotPassword: {
    pageName: (): string | React.ReactNode => (
      <Trans i18nKey="_Common.breadcrumb.forgot_password" />
    ),
    component: ForgotPasswordPage,
    path: '/forgot-password',
    route: '/forgot-password',
    isPrivate: false,
  },
  integrations: {
    pageName: (): string | React.ReactNode => (
      <Trans i18nKey="_Common.breadcrumb.integrations" />
    ),
    component: IntegrationsPage,
    path: '/integrations',
    route: '/integrations',
    isPrivate: true,
  },
  terminals: {
    pageName: (): string | React.ReactNode => (
      <Trans i18nKey="_Common.breadcrumb.terminals" />
    ),
    component: TerminalsPage,
    path: '/terminals',
    route: '/terminals',
    isPrivate: true,
  },
  transactions: {
    pageName: (): string | React.ReactNode => (
      <Trans i18nKey="_Common.breadcrumb.transactions" />
    ),
    component: TransactionsPage,
    path: '/transactions',
    route: '/transactions',
    isPrivate: true,
  },
  transactionDetails: {
    pageName: (): string | React.ReactNode => (
      <Trans i18nKey="_Common.breadcrumb.transaction_details" />
    ),
    component: TransactionDetailsPage,
    path: '/transactions',
    route: '/transactions/:id',
    isPrivate: true,
  },
  settlements: {
    pageName: (): string | React.ReactNode => (
      <Trans i18nKey="_Common.breadcrumb.settlements" />
    ),
    component: SettlementsPage,
    path: '/settlements',
    route: '/settlements',
    isPrivate: true,
  },
  settlementDetails: {
    pageName: (): string | React.ReactNode => (
      <Trans i18nKey="_Common.breadcrumb.settlements" />
    ),
    component: SettlementDetailsPage,
    path: '/settlements',
    route: '/settlements/:id',
    isPrivate: true,
  },
  account: {
    pageName: (): string | React.ReactNode => (
      <Trans i18nKey="_Common.breadcrumb.account" />
    ),
    component: AccountPage,
    path: '/account',
    route: '/account',
    isPrivate: true,
  },
  products: {
    pageName: (): string | React.ReactNode => (
      <Trans i18nKey="_Common.breadcrumb.products" />
    ),
    component: ProductsPage,
    path: '/products',
    route: '/products',
    isPrivate: true,
  },
};

/**
 * RootRouter component
 */
const RootRouter: React.FC<RootRouterProps> = () => {
  return (
    <BrowserRouter>
      <Root>
        <Switch>
          {Object.values(ROUTES).map((route) => {
            const RouteContainer = route.isPrivate ? PrivateRoute : PublicRoute;

            return (
              <RouteContainer
                exact
                key={route.route}
                path={route.route}
                component={route.component}
                dontCheckAuth={route.dontCheckAuth}
              />
            );
          })}
          <Route
            path="/integration_callback/:integrationName"
            component={IntegrationCallbackPage}
            dontCheckAuth={true}
            exact
          />

          <Route path="*" component={() => <h1>404</h1>} />
        </Switch>
      </Root>
    </BrowserRouter>
  );
};

/**
 * Exports
 */
export { RootRouter };
