/**
 * Imports
 */

import { BaseLayout } from 'features/navigation';
import { KeyNumbers } from 'features/overview';
import { RecentTransactions } from 'features/transactions';
import * as React from 'react';
import { ReadyToWork } from 'components/LockScreen/ReadyToWork';

/**
 * CONST
 */

/**
 * Types
 */
export interface HomePageProps {}

/**
 * HomePage component
 */
const HomePage: React.FC<HomePageProps> = (_props) => {
  return (
    <BaseLayout disableBreadcrumb>
      <ReadyToWork />
      <KeyNumbers />
      <RecentTransactions />
    </BaseLayout>
  );
};

/**
 * Exports
 */
export { HomePage };
