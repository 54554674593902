/**
 * Imports
 */
import { combineReducers } from '@reduxjs/toolkit';
import features from 'features';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';

/**
 * The app's reducer
 */
const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  overview: features.overview.redux.reducer,
  settlements: features.settlements.redux.reducer,
  sidebar: features.sidebar.redux.reducer,
  transactions: features.transactions.redux.reducer,
  integrations: features.integrations.redux.reducer,
  user: features.user.redux.reducer,
  productModal: features.products.redux.default.reducer,
});

/**
 * Exports
 */
export { rootReducer };
