/**
 * Imports
 */
import { LanguageTypes } from 'features/user/components';
import {
  dashboardSettingsSelector,
  userSelector,
} from 'features/user/redux/selectors';
import { FirebaseCollectionsType } from 'firebaseAPI';
import { useSelector } from 'react-redux';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import { Firebase } from 'types/firebase';
/**
 * Types
 */
export type DashboardSettingsAPIHanders = {
  onCloseGuide: (guide: 'setup' | 'terminal') => Promise<void>;
  onChangeLanguage: (language: LanguageTypes) => Promise<void>;
  // onSetViewedCode: () => Promise<void>;
};

export type DashboardSettingsApiType = {
  handlers: DashboardSettingsAPIHanders;
  dashboardSettings: Firebase.SoftPosDashboardSettings;
};

/**
 * useDashboardSettings
 */
const useDashboardSettings = (): DashboardSettingsApiType => {
  /* Firebase */
  const firestore = useFirestore();
  const user = useSelector(userSelector);
  /* Firestore */
  useFirestoreConnect([
    {
      collection: FirebaseCollectionsType.SOFTPOS_DASHBOARD_SETTINGS,
      storeAs: FirebaseCollectionsType.SOFTPOS_DASHBOARD_SETTINGS,
      doc: user.id,
    },
  ]);
  const dashboardSettings = useSelector(dashboardSettingsSelector);

  const handlers: DashboardSettingsAPIHanders = {
    /**
     * Update merchant company details 3
     */
    onCloseGuide: async (guide: 'setup' | 'terminal') => {
      await firestore
        .collection(FirebaseCollectionsType.SOFTPOS_DASHBOARD_SETTINGS)
        .doc(user.id)
        .set({ guides: { [guide]: false } }, { merge: true });
    },
    /**
     * Update dashboard language
     */
    onChangeLanguage: async (language: LanguageTypes) => {
      await firestore
        .collection(FirebaseCollectionsType.SOFTPOS_DASHBOARD_SETTINGS)
        .doc(user.id)
        .set({ language }, { merge: true });
    },
    /**
     * Update dashboard language
     */
    // onSetViewedCode: async () => {
    //   await firestore
    //     .collection(FirebaseCollectionsType.SOFTPOS_DASHBOARD_SETTINGS)
    //     .doc(user.id)
    //     .set({ viewedCode: true }, { merge: true });
    // },
  };

  return { handlers, dashboardSettings };
};

/**
 * Exports
 */
export { useDashboardSettings };
