/**
 * Imports
 */
import * as React from 'react';

import styled from 'styled-components';

/**
 * Styling
 */
const WHITE_SPACE_SIZE: WhiteSpaceLookup = {
  lg: 32,
  md: 24,
  sm: 12,
};
const Vertical = styled.div`
  border-right: 1px solid
    ${(props) => props.theme.colors.Border.BorderSecondary};
  width: 1px;
  height: 24px;

  margin: 0 12px 0 24px;
`;
const Horizontal = styled.div<{ whiteSpace: WhiteSpaceSizes }>`
  border-bottom: 1px solid
    ${(props) => props.theme.colors.Border.BorderSecondary};
  width: 100%;
  height: 1px;
  margin: ${(props) => WHITE_SPACE_SIZE[props.whiteSpace]}px 0;
`;
/**
 * Types
 */
type WhiteSpaceLookup = {
  [kein in WhiteSpaceSizes]: number;
};
type WhiteSpaceSizes = 'sm' | 'md' | 'lg';
export interface DividerProps {
  vertical?: boolean;
  whiteSpace?: WhiteSpaceSizes;
}

/**
 * Divider component
 */
const Divider: React.FC<DividerProps> = ({ vertical, whiteSpace }) => {
  return (
    <>
      {vertical ? (
        <Vertical />
      ) : (
        <Horizontal whiteSpace={whiteSpace ? whiteSpace : 'md'} />
      )}
    </>
  );
};

/**
 * Exports
 */
export { Divider };
