/**
 * Imports
 */
import { createReducer } from '@reduxjs/toolkit';

import {
  collapseSidebar,
  hideSidebar,
  openSidebar,
  showAddOwnerModal,
  hideAddOwnerModal,
} from './actions';

/**
 * Types
 */
export interface SidebarState {
  visible: boolean;
  collapsed: boolean;
  modals: {
    ownerModal: boolean;
  };
}

/**
 * Constants
 */
const INITIAL_STATE: SidebarState = {
  visible: true,
  collapsed: false,
  modals: {
    ownerModal: false,
  },
};

/**
 * `sidebar` reducer
 */
const sidebarReducer = createReducer(INITIAL_STATE, {
  /**
   * COLLAPSE_SIDEBAR
   */
  [collapseSidebar.type]: (state) => {
    state.collapsed = true;
  },
  /**
   * HIDE_SIDEBAR
   */
  [hideSidebar.type]: (state) => {
    state.visible = false;
  },
  /**
   * OPEN_SIDEBAR
   */
  [openSidebar.type]: (state) => {
    state.collapsed = false;
    state.visible = true;
  },
  /**
   * SHOW_OWNER_MODAL
   */
  [showAddOwnerModal.type]: (state) => {
    state.modals.ownerModal = true;
  },
  /**
   * HIDE_OWNER_MODAL
   */
  [hideAddOwnerModal.type]: (state) => {
    state.modals.ownerModal = false;
  },
});

/**
 * Exports
 */
export default sidebarReducer;
