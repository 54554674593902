/**
 * Imports
 */
import { PaginationProps } from 'antd/lib/pagination';
import { TablePaginationConfig } from 'antd/lib/table';
import { getSettlementsApi } from 'apis';
import { orderBy } from 'lodash';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { VibrantAPI } from 'types/vibrant';
import { getCurrentBalance, getSettlements } from '../redux/actions';
import { endDateSelector, startDateSelector } from '../redux/selectors';

/**
 * Types
 */
type UseSettlementsReturnType = {
  isFecthing: boolean;
  tableChangeHandler: (pagination: PaginationProps) => void;
  pagination: TablePaginationConfig;
};

/**
 * useSettlementsApi component
 */
const useSettlementsApi = (): UseSettlementsReturnType => {
  const [isFecthing, setLoading] = React.useState(false);
  const [pagination, setPagination] = React.useState<PaginationProps>({});

  const dispatch = useDispatch();

  const startDate = useSelector(startDateSelector);
  const endDate = useSelector(endDateSelector);

  const _buildPagination = (totalRecords: number) => {
    const nextPagination = { ...pagination };
    nextPagination.total = totalRecords ? totalRecords : undefined;
    setPagination(nextPagination);
  };

  const fecthSettlementsItems = React.useCallback(
    async (currentPage, pageSize) => {
      setLoading(true);
      try {
        const resp = (await getSettlementsApi(
          startDate,
          endDate,
          currentPage,
          pageSize,
        )) as VibrantAPI.GetSettlementsResult;
        const filteredSettlements = resp.settlements.filter(
          (s) => s.closedAt !== null,
        );
        const currentBalance = resp.settlements.filter(
          (s) => s.closedAt === null,
        );
        const settlements = orderBy(
          filteredSettlements,
          ['startedAt'],
          ['desc'],
        );
        dispatch(
          getCurrentBalance({
            settlements: currentBalance,
          }),
        );
        dispatch(
          getSettlements({
            settlements,
            settlementsCount: resp.count,
          }),
        );
        _buildPagination(resp.count);
        setLoading(false);
      } catch (exception) {
        console.log('exception', exception);
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [endDate, startDate],
  );

  const tableChangeHandler = (pagination: PaginationProps) => {
    const pager = { ...pagination };
    pager.current = pagination.current;
    setPagination(pager);
    fecthSettlementsItems(pagination.current, pagination.pageSize);
  };

  React.useEffect(() => {
    fecthSettlementsItems(1, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate, startDate]);

  return { isFecthing, tableChangeHandler, pagination };
};

/**
 * Exports
 */
export { useSettlementsApi };
