/**
 * Imports
 */
import { ArrowLeftOutlined } from '@ant-design/icons';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Icon, Text } from 'components';
import { Trans } from 'react-i18next';

/**
 * Styling
 */
const Root = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const StyledText = styled(Text)`
  margin-left: 8px;
`;

/**
 * Types
 */
export interface GoBackButtonProps {}

/**
 * GoBackButton component
 */
const GoBackButton: React.FC<GoBackButtonProps> = (props) => {
  const navigation = useHistory();

  const _goBack = () => {
    navigation.goBack();
  };

  return (
    <Root onClick={_goBack}>
      <Icon icon={<ArrowLeftOutlined />} size={12} color="Grey300" />
      <StyledText size="ui1" color="ui">
        <Trans i18nKey="_Common.actions.go_back_btn" />
      </StyledText>
    </Root>
  );
};

/**
 * Exports
 */
export { GoBackButton };
