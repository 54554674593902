/**
 * Imports
 */
import { Tag } from 'antd';
import { SoftPos } from 'types/softpos';
import { Text } from 'components';
import * as React from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { terminalCollectionSelector } from 'features/user/redux/selectors';

/**
 * Styling
 */
const Root = styled.div`
  margin-top: 48px;
`;
const Grid = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 12px;
`;

/**
 * Types
 */
export interface PaymentInformationProps {
  trxnItem: SoftPos.Trxn;
}

/**
 * PaymentInformation component
 */
const PaymentInformation: React.FC<PaymentInformationProps> = ({
  trxnItem,
}) => {
  const terminalCollection = useSelector(terminalCollectionSelector);
  const name = terminalCollection.find(
    (terminal) => terminal.terminalId === trxnItem?.terminalId,
  )?.name;

  return (
    <Root>
      <Text size="ui2" bold>
        <Trans i18nKey="TransactionsDetails.payment_information.title" />
      </Text>
      <Grid>
        <Text size="ui3" color="ui">
          <Trans i18nKey="TransactionsDetails.payment_information.descriptor" />
        </Text>
        <Text size="ui3" color="ui">
          <Trans i18nKey="TransactionsDetails.payment_information.payment_card" />
        </Text>
        <Text size="ui3" color="ui">
          <Trans i18nKey="TransactionsDetails.payment_information.fees" />
        </Text>
        <Text size="ui3" color="ui">
          <Trans i18nKey="Transactions.table_header.status" />
        </Text>
        <Text size="ui3" color="ui">
          <Trans i18nKey="SettlementDetails.table_header.last_four" />
        </Text>
        <Text size="ui3" color="ui">
          <Trans i18nKey="Transactions.table_header.terminal_name" />
        </Text>

        <Text size="ui2">{trxnItem?.descriptor}</Text>
        <Text size="ui2">{trxnItem?.cardSource}</Text>
        <Text size="ui2">
          {/* TODO: Get fee from merchant collection */}
          {trxnItem?.fee} %
          {/* {trxnItem?.amount &&
            formatCurrency(trxnItem?.amount * 0.0139, trxnItem?.currency)} */}
        </Text>
        <Text size="ui2">
          {trxnItem?.status === 'approved' && (
            <Tag color="green">
              <Trans i18nKey="_Common.status_type.approved" />
            </Tag>
          )}
          {trxnItem?.status === 'declined' && (
            <Tag color="red">
              <Trans i18nKey="_Common.status_type.declined" />
            </Tag>
          )}
        </Text>
        <Text size="ui2">
          **{trxnItem?.cardNumber?.substring(trxnItem?.cardNumber?.length - 4)}
        </Text>
        <Text size="ui2">{name}</Text>
      </Grid>
    </Root>
  );
};

/**
 * Exports
 */
export { PaymentInformation };
