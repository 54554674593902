import { ApiOutlined, UserOutlined } from '@ant-design/icons';
import axios, { AxiosError } from 'axios';
import { Icon, Link4PayLogo, Logo, Text, VibrantIcon } from 'components';
import {
  collapseSidebar,
  openSidebar,
} from 'features/navigation/redux/actions';
import { sidebarCollapsedSelector } from 'features/navigation/redux/selectors';
import { useMerchantApi } from 'features/shared';
import {
  firebaseUserSelector,
  isDanishMerchantSelector,
  isLink4PaySelector,
  terminalCollectionSelector,
} from 'features/user/redux/selectors';
import { firebaseLogout } from 'firebaseAPI';
import React, { useEffect, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTES } from 'router/RootRouter';
import { captureInfo } from 'utils/error-logging';
import { SidebarAccountDetails } from './components';
import * as Styles from './Sidebar.styles';
import { getIsAvailablePage } from 'utils';

/**
 * Types
 */
export interface SidebarProps {}

/**
 * Sidebar component
 */
const Sidebar: React.FC<SidebarProps> = (_props) => {
  const [currentPath, setCurrentPath] = React.useState('string');
  const collapsed = useSelector(sidebarCollapsedSelector);
  const terminalCollection = useSelector(terminalCollectionSelector);
  const navigate = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const isDanishMerchant = useSelector(isDanishMerchantSelector);
  const isLink4Pay = useSelector(isLink4PaySelector);
  const { merchantCollection } = useMerchantApi();
  const { uid: userId, displayName, email, photoURL } = useSelector(
    firebaseUserSelector,
  );
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    const getToken = async () => {
      try {
        const urlParams = new URLSearchParams();
        const dn = displayName || email;
        urlParams.set('userId', userId);
        urlParams.set('displayName', dn ?? '');
        urlParams.set('photoURL', photoURL ?? '');
        const tokenResponse = await axios.get<{ token: string }>(
          `/api/token?${urlParams.toString()}`,
        );
        setToken(tokenResponse.data.token);
      } catch (error) {
        captureInfo((error as AxiosError).message);
        setToken(null);
      }
    };
    getToken();
  }, []);

  const sidebarItems = useMemo(() => {
    return [
      {
        icon: <VibrantIcon icon="overview" />,
        path: ROUTES.home.path,
        text: () => <Trans i18nKey="Sidebar.links.overview" />,
      },
      {
        icon: <VibrantIcon icon="transaction" />,
        path: ROUTES.transactions.path,
        text: () => <Trans i18nKey="Sidebar.links.transactions" />,
      },
      {
        icon: <VibrantIcon icon="home" />,
        path: ROUTES.settlements.path,
        text: () => <Trans i18nKey="Sidebar.links.settlements" />,
      },
      {
        icon: <VibrantIcon icon="nfc" />,
        path: ROUTES.terminals.path,
        text: () => <Trans i18nKey="Sidebar.links.terminals" />,
      },
      {
        icon: <VibrantIcon icon="product" />,
        path: ROUTES.products.path,
        text: () => <Trans i18nKey="Sidebar.links.products" />,
      },
      {
        icon: <Icon icon={<ApiOutlined />} />,
        path: ROUTES.integrations.path,
        text: () => <Trans i18nKey="Sidebar.links.integrations" />,
        hide: !isDanishMerchant,
      },
      {
        icon: <Icon icon={<UserOutlined />} />,
        path: ROUTES.account.path,
        text: () => <Trans i18nKey="Sidebar.links.account" />,
      },
    ];
  }, [isDanishMerchant, token]);

  useEffect(() => {
    const pathName = location.pathname.split('/')[1];
    setCurrentPath(`/${[pathName]}`);
  }, [location.pathname]);

  const toggleCollaped = () => {
    dispatch(collapsed ? openSidebar({}) : collapseSidebar({}));
  };

  const _navigateTo = (path: string) => {
    navigate.push(path);
  };

  const _handleLogout = async () => {
    await firebaseLogout();

    navigate.push(ROUTES.login.path);
  };

  return (
    <Styles.StyledMenu
      mode="inline"
      inlineCollapsed={collapsed}
      selectedKeys={[currentPath]}
    >
      <Styles.Top onClick={() => _navigateTo(ROUTES.home.path)}>
        {merchantCollection?.countryCode && (
          <>
            {isLink4Pay ? (
              <div>
                <Link4PayLogo width={124} />
              </div>
            ) : (
              <Logo size={collapsed ? 16 : 20} single={collapsed} />
            )}
          </>
        )}
      </Styles.Top>

      {sidebarItems.map((item) => {
        if (item.hide) {
          return null;
        }
        return (
          <Styles.MenuItem
            key={item.path}
            icon={item.icon}
            onClick={() => _navigateTo(item.path)}
            disabled={
              terminalCollection?.length
                ? false
                : !getIsAvailablePage(item.path)
            }
          >
            <Text size="ui2">{item.text()}</Text>
          </Styles.MenuItem>
        );
      })}

      {!collapsed && (
        <Styles.ShortcutsContainer>
          <Styles.AnchorText
            size="ui3"
            href="https://support.vibrant.io/"
            target="_blank"
          >
            <Trans i18nKey="Sidebar.links.faq" />
          </Styles.AnchorText>
          <Styles.AnchorText
            size="ui3"
            href={
              isLink4Pay
                ? 'https://link4pay.com/contact-us/'
                : 'https://www.vibrant.io/contact'
            }
            target="_blank"
          >
            <Trans i18nKey="Sidebar.links.contact" />
          </Styles.AnchorText>
          <Styles.LogoutButton size="ui3" onClick={_handleLogout}>
            <Trans i18nKey="_Common.actions.logout_btn" />
          </Styles.LogoutButton>
        </Styles.ShortcutsContainer>
      )}

      <SidebarAccountDetails
        collapsed={collapsed}
        onOpenAccount={() => _navigateTo(ROUTES.account.path)}
      />

      <Styles.CollapseContainer>
        <Styles.CollapseButton onClick={toggleCollaped}>
          <VibrantIcon
            icon={collapsed ? 'arrow-long-right' : 'arrow-long-left'}
          />
        </Styles.CollapseButton>
      </Styles.CollapseContainer>
    </Styles.StyledMenu>
  );
};

/**
 * Exports
 */
export { Sidebar };
