import React, { FC, useState } from 'react';
import { Row } from './Row';
import { ProductDataWithId } from '../types';
import { Button, Dropdown, Input, Text, VibrantIcon } from 'components';
import { useTranslation } from 'react-i18next';
import { useMerchantApi } from 'features/shared';
import { useSelector } from 'react-redux';
import { userSelector } from 'features/user/redux/selectors';
import { deleteProduct, updateProduct } from '../api';
import { formatCurrency } from 'utils';
import { vatMap } from 'features/shared/utils/constants';

interface Props {
  product: ProductDataWithId;
}

export const ProductRow: FC<Props> = ({ product }) => {
  const { t } = useTranslation();
  const { merchantCollection } = useMerchantApi();
  const { meta } = useSelector(userSelector);
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(product.name);
  const [price, setPrice] = useState(product.price);
  const [vat, setVat] = useState(product.vat);
  const countryCode = merchantCollection.countryCode;
  const vatOptions = vatMap[countryCode];

  const dismissChanges = () => {
    setIsEditing(false);
    setName(product.name);
    setPrice(product.price);
    setVat(product.vat);
  };

  const saveChanges = async () => {
    updateProduct(
      {
        id: product.id,
        name,
        price,
        priceInCents: Math.round(price * 100),
        vat,
      },
      meta.merchantId,
    );
    setIsEditing(false);
  };

  return (
    <Row>
      <div className="column name">
        {isEditing ? (
          <Input
            name="name"
            type="text"
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
        ) : (
          <Text size="ui2">{product.name}</Text>
        )}
      </div>
      <div className="column vat">
        {isEditing ? (
          <select onChange={(event) => setVat(Number(event.target.value))}>
            {vatOptions.map((vat, index) => (
              <option
                defaultChecked={vat === product.vat}
                key={`${vat}_${index}`}
                value={vat}
              >
                {vat}
              </option>
            ))}
          </select>
        ) : (
          <Text size="ui2">{product.vat}%</Text>
        )}
      </div>
      <div className="column price">
        {isEditing ? (
          <Input
            name="price"
            type="number"
            value={price}
            onChange={(event) =>
              setPrice(parseFloat(event.target.value.replace(',', '.')))
            }
          />
        ) : (
          <Text size="ui2">
            {formatCurrency(product.price, merchantCollection.currency)}
          </Text>
        )}
      </div>
      <div className="column actions">
        {isEditing ? (
          <>
            <Button type="text" onClick={saveChanges}>
              {t('Products.Common.save')}
            </Button>
            <Button type="text" onClick={dismissChanges}>
              {t('Products.Common.cancel')}
            </Button>
          </>
        ) : (
          <>
            <Button type="text" onClick={() => setIsEditing((prev) => !prev)}>
              <VibrantIcon icon="pen" />
            </Button>
            <Button
              type="text"
              onClick={() => {
                deleteProduct(product.id, meta.merchantId);
              }}
            >
              <VibrantIcon icon="close" />
            </Button>
          </>
        )}
      </div>
    </Row>
  );
};
