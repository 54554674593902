import { createAction } from '@reduxjs/toolkit';
import { VibrantlyAction } from 'store/types';

const PREFIX = 'productModal';

export const OPEN_PRODUCT_MODAL = `${PREFIX}/OPEN_PRODUCT_MODAL`;
export type OpenProductModalAction = VibrantlyAction<Record<string, any>>;
export const openProductModal = createAction<OpenProductModalAction['payload']>(
  OPEN_PRODUCT_MODAL,
);

export const CLOSE_PRODUCT_MODAL = `${PREFIX}/CLOSE_PRODUCT_MODAL`;
export type CloseProductModalAction = VibrantlyAction<Record<string, any>>;
export const closeProductModal = createAction<
  CloseProductModalAction['payload']
>(CLOSE_PRODUCT_MODAL);
