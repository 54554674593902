/**
 * Imports
 */
import * as React from 'react';

import { Text } from 'components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import {
  trxnItemSelector,
  trxnOrderItemsSelector,
} from 'features/transactions/redux/selectors';
import { formatCurrency } from 'utils';
/**
 * Styling
 */
const GridRow = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  justify-content: space-between;

  & div:nth-child(3n + 3) {
    text-align: right;
  }
`;
const TrxnDataRow = styled(GridRow)`
  border-top: 1px solid ${({ theme }) => theme.colors.Border.BorderTertiary};
  border-bottom: 1px solid ${({ theme }) => theme.colors.Border.BorderTertiary};
  padding: 32px 0;
`;

/**
 * OrderItems component
 */
const OrderItems: React.FC = () => {
  const { t } = useTranslation();
  const trxnItem = useSelector(trxnItemSelector);
  const trxnOrderItems = useSelector(trxnOrderItemsSelector);
  const hasOrderItems = trxnOrderItems.length > 0;

  const _getFormattedPrice = () => {
    const formattedPrice = formatCurrency(
      trxnItem[0]?.statusCode === 'V'
        ? -trxnItem[0]?.amount
        : trxnItem[0]?.amount,
      trxnItem[0]?.currency,
    );
    return formattedPrice;
  };
  const _getFormattedOrderAmount = (amount: number) => {
    const formattedPrice = formatCurrency(
      trxnItem[0]?.statusCode === 'V' ? -amount : amount,
      trxnItem[0]?.currency,
    );
    return formattedPrice;
  };

  return (
    <TrxnDataRow>
      {!hasOrderItems && (
        <>
          <Text size="ui2">
            {t('TransactionsDetails.receipt.product_name_text')}
          </Text>
          <Text size="ui2">01</Text>
          <Text size="ui2">{_getFormattedPrice()}</Text>
        </>
      )}
      {hasOrderItems &&
        trxnOrderItems.map((item) => {
          return (
            <>
              <Text size="ui2">{item.name}</Text>
              <Text size="ui2"> {item.quantity}</Text>
              <Text size="ui2">
                {_getFormattedOrderAmount((item.price * item.quantity) / 100)}
              </Text>
            </>
          );
        })}
    </TrxnDataRow>
  );
};

/**
 * Exports
 */
export { OrderItems };
