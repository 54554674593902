/**
 * Imports
 */
import { getSingleTransactionApi } from 'apis';
import { SoftPos } from 'types/softpos';
import { Firebase } from 'types/firebase';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSingleTransaction } from '../redux/actions';
import { messageError } from 'components';
import { useFirestore } from 'react-redux-firebase';
import { FirebaseCollectionsType, getFirebaseInstance } from 'firebaseAPI';
import moment from 'moment';
import { DATE_FORMATS } from 'utils';
import { merchantCollectionSelector } from 'features/user/redux/selectors';
import { trxnItemsSelector } from '../redux/selectors';

/**
 * Types
 */
type UseTransactionReturnType = {
  isFecthing: boolean;
};

/**
 * useSingleTransactionApi component
 */
const useSingleTransactionApi = (trxnId: string): UseTransactionReturnType => {
  const [isFecthing, setLoading] = React.useState(false);
  const merchant = useSelector(merchantCollectionSelector);
  const trxnItems = useSelector(trxnItemsSelector);
  const dispatch = useDispatch();
  const firestore = useFirestore();

  const _trxnHasOrder = async (trxn: SoftPos.Trxn) => {
    const rrnId = trxn.receiptId.split('-')[0];
    const trxnDate = moment(trxn.date).format(DATE_FORMATS.dateAlt);
    if (rrnId) {
      const documentRef = firestore
        .collection(FirebaseCollectionsType.ORDER_RRN)
        .where('rrn', '==', rrnId)
        .where('dateCreated', '==', trxnDate);
      const data = await documentRef.get();
      const orderMap: Firebase.OrderToRRNItem[] = [];
      data.forEach(async (doc) => {
        orderMap.push(doc.data() as Firebase.OrderToRRNItem);
      });

      if (orderMap.length > 0) {
        const orderId = orderMap[0].orderId;
        const documentRef = firestore
          .collection(FirebaseCollectionsType.ORDERS)
          .doc(merchant.merchantId)
          .collection(FirebaseCollectionsType.ORDER_SUBCOLLECTION)
          .doc(orderId);
        const data = (await documentRef.get()).data();
        if (data?.orderItems) {
          const orderItems: Firebase.OrderItem[] = data.orderItems;
          return orderItems;
        } else {
          return [];
        }
      } else {
        return [];
      }
    } else {
      throw 'Could not find RRN';
    }
  };

  const fecthTrxn = React.useCallback(
    async () => {
      setLoading(true);
      try {
        const selectedTxn = trxnItems?.find((txn) => txn.trxnId === trxnId);
        // const resp = (await getSingleTransactionApi(trxnId)) as SoftPos.Trxn;
        if (selectedTxn) {
          const trxnOrderItems = await _trxnHasOrder(selectedTxn);
          dispatch(
            getSingleTransaction({
              trxn: [selectedTxn],
              trxnOrderItems,
            }),
          );
          setLoading(false);
        }
      } catch (exception) {
        messageError({
          content: exception?.message,
        });
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  React.useEffect(() => {
    fecthTrxn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isFecthing };
};

/**
 * Exports
 */
export { useSingleTransactionApi };
