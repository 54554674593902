/**
 * Imports
 */
import { getTransactionsApi } from 'apis';
import { orderBy } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { VibrantAPI } from 'types/vibrant';

/**
 * Types
 */
type UseTransactionsReturnType = {
  isFecthing: boolean;
  transactions: any;
};

/**
 * useRecentTransactions component
 */
const useRecentTransactions = (): UseTransactionsReturnType => {
  const [isFecthing, setIsFetching] = React.useState(false);
  const [transactions, settransactions] = React.useState<any>();
  const startDate = moment().add(-1, 'month').startOf('month').startOf('day');
  const endDate = moment().endOf('day');
  const fecthTrxnItems = React.useCallback(
    async (currentPage, pageSize) => {
      setIsFetching(true);
      try {
        const resp = (await getTransactionsApi(
          startDate,
          endDate,
          currentPage,
          pageSize,
        )) as VibrantAPI.GetTrxnsResult;
        const filterNullTrxn = resp.trxns.filter(
          (trxn) => trxn.responseCode !== null,
        );

        settransactions(
          orderBy(filterNullTrxn, ['trxnTime'], ['desc']).slice(0, 5),
        );
        setIsFetching(false);
      } catch (exception) {
        setIsFetching(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  React.useEffect(() => {
    fecthTrxnItems(1, 20);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isFecthing, transactions };
};

/**
 * Exports
 */
export { useRecentTransactions };
