import { getIdToken } from 'firebaseAPI';
import axios from 'axios';
import { PROJECT_API_URL } from 'config';
import { Moment } from 'moment';
import { VibrantAPI } from 'types/vibrant';
import { SoftPos } from 'types/softpos';

/**
 * Get all settlements from a specific period
 */
export const getSettlementsApi = async (
  startDate: Moment,
  endDate: Moment,
  currentPage: number,
  pageSize: number,
): Promise<VibrantAPI.GetSettlementsResult | []> => {
  const idToken = await getIdToken();
  try {
    const utcStart = startDate.clone().utc();
    const utcEnd = endDate.clone().utc();

    const params = {
      from: utcStart.format(),
      to: utcEnd.format(),
      page: currentPage - 1,
      size: pageSize,
    };
    const response = (await axios.get(
      `${PROJECT_API_URL}/api/softpos/v2/settlements`,
      {
        params,
        headers: { token: idToken },
      },
    )) as VibrantAPI.GetSettlementsResponse;
    return response.data.result;
  } catch (error) {
    console.log('getSettlementsApi error: ', error);
    return [];
  }
};
/**
 * Get single settlement
 */
export const getSettlementApi = async (
  merchantId: string,
  settlementId: string,
): Promise<SoftPos.Settlement> => {
  const idToken = await getIdToken();
  try {
    const response = (await axios.get(
      `${PROJECT_API_URL}/api/softpos/v2/settlement?merchant_id=${merchantId}&settlement_id=${settlementId}`,
      {
        headers: { token: idToken },
      },
    )) as VibrantAPI.GetSettlementResponse;
    return response.data.result;
  } catch (error) {
    console.log(error);
    throw new Error('Failed to get settlement');
  }
};
/**
 * Get a postings
 * api/softpos/v2/settlement_postings?merchant_id=${merchant_id}&settlement_id=${settlement_id}
 */
export const getPostingsApi = async (
  merchantId: string,
  settlementId: string,
): Promise<SoftPos.Posting[] | Record<string, any>> => {
  const idToken = await getIdToken();
  try {
    const response = (await axios.get(
      `${PROJECT_API_URL}/api/softpos/v2/settlement_postings?merchant_id=${merchantId}&settlement_id=${settlementId}`,
      {
        headers: { token: idToken },
      },
    )) as VibrantAPI.GetPostingsResponse;
    return response.data.result;
  } catch (error) {
    console.log(error);
    return [];
  }
};
