/**
 * Imports
 */
import { Space } from 'components';
import { BaseLayout } from 'features/navigation';
import { useMerchantApi } from 'features/shared';
import { AddTerminal, TerminalGuide, TerminalsTable } from 'features/terminals';
import { isSupporterSelector } from 'features/user/redux/selectors';
import * as React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const GuideWrapper = styled.div`
  margin-top: -21px;
`;

/**
 * TerminalsPage component
 */
const TerminalsPage: React.FC = () => {
  const isSupporter = useSelector(isSupporterSelector);
  useMerchantApi();
  return (
    <BaseLayout disableBreadcrumb={isSupporter}>
      {isSupporter && (
        <GuideWrapper>
          <TerminalGuide />
        </GuideWrapper>
      )}
      {isSupporter && <AddTerminal />}
      <Space size="xl" />
      <TerminalsTable />
      <Space size="xl" />
    </BaseLayout>
  );
};

/**
 * Exports
 */
export { TerminalsPage };
