import axios from 'axios';
import { PROJECT_API_URL } from 'config';
import { default as firebaseNative } from 'firebase/app';
import { Firebase } from 'types/firebase';
import { getFirebaseInstance } from './firebaseAdapter';

/**
 * CONST
 */

export enum FirebaseCollectionsType {
  EMAIL_LEADS = 'emailLeads',
  MERCHANT_INTEGRATIONS = 'merchantIntegrations',
  MERCHANTS = 'merchants',
  ORDER_RRN = 'orderToRRN',
  ORDERS = 'orders',
  REFUND_REQUEST = 'refundRequests',
  REFUND_REQUEST_SUBCOLLECTION = 'requests',
  ORDER_SUBCOLLECTION = 'order',
  SOFTPOS_DASHBOARD_SETTINGS = 'softPosDashboardSettings',
  SOFTPOS_ONBOARDING = 'softPosOnboarding',
  USERS = 'users',
}

/**
 * Google login
 */
const firebaseGoogleLogin = async (): Promise<Firebase.User> => {
  try {
    const firebase = getFirebaseInstance();

    const resp = await firebase
      .auth()
      .signInWithPopup(new firebaseNative.auth.GoogleAuthProvider());

    if (!resp.user) {
      throw new Error('Login failed. Contact support If the error continues.');
    }
    const user = await getUser(resp.user.uid);
    return user;
  } catch (error) {
    throw error;
  }
};

/**
 * Login with email
 */
const firebaseLogin = async (
  email: string,
  password: string,
): Promise<Firebase.User> => {
  try {
    const firebase = getFirebaseInstance();
    const signinResult = await firebase
      .auth()
      .signInWithEmailAndPassword(email, password);
    if (!signinResult.user) {
      throw new Error('Login failed. Contact support If the error continues.');
    }
    const user = await getUser(signinResult.user.uid);
    return user;
  } catch (error) {
    throw error;
  }
};

/** */
const firebaseResetPassword = async (email: string): Promise<void> => {
  const firebase = getFirebaseInstance();
  return firebase.auth().sendPasswordResetEmail(email);
};

/**
 * Token login
 */
const firebaseTokenLogin = async (token: string): Promise<Firebase.User> => {
  const firebase = getFirebaseInstance();
  const signinResult = await firebase.auth().signInWithCustomToken(token);
  if (!signinResult.user) {
    throw new Error('Login failed. Contact support If the error continues.');
  }

  return getUser(signinResult.user.uid);
};
/**
 * Get id token
 */
const getIdToken = async (): Promise<string> => {
  const firebase = getFirebaseInstance();
  const token = await firebase.auth().currentUser!.getIdToken();
  return token;
};

/**
 * Logout
 */
const firebaseLogout = async (): Promise<void> => {
  const firebase = getFirebaseInstance();
  await firebase.auth().signOut();
};
/**
 * Get user
 */
const getUser = async (_userId: string): Promise<Firebase.User> => {
  try {
    const token = await getIdToken();
    const response = await axios.get(`${PROJECT_API_URL}/api/me`, {
      headers: { token },
    });
    const user = response.data.result as Firebase.User;
    const hasDashboardAccess =
      !user.merchantRoles || user.merchantRoles.includes('admin');
    if (user?.userRoles.includes('softpos-merchant') && hasDashboardAccess) {
      return user;
    } else {
      await firebaseLogout();
      throw 'Account is not authorized to login. Please contact your admin or support if you believe this is a mistake.';
    }
  } catch (error) {
    throw error;
  }
};

export {
  firebaseGoogleLogin,
  firebaseLogin,
  firebaseLogout,
  firebaseResetPassword,
  firebaseTokenLogin,
  getIdToken,
  getUser,
};
