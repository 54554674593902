import { useState, useEffect } from 'react';
import throttle from 'lodash/throttle';

export enum Breakpoints {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}

const getBreakPoint = (width: number) => {
  if (width < 320) {
    return Breakpoints.XS;
  } else if (width >= 320 && width < 720) {
    return Breakpoints.SM;
  } else if (width >= 720 && width <= 1024) {
    return Breakpoints.MD;
  } else if (width > 1024) {
    return Breakpoints.LG;
  }
};

type UseBreakpointReturnType = {
  breakpoint?: Breakpoints;
  isXs: boolean;
  isSm: boolean;
  isMd: boolean;
  isLg: boolean;
};

const useBreakpoint = (): UseBreakpointReturnType => {
  const [breakpoint, setBreakpoint] = useState(() =>
    getBreakPoint(window.innerWidth),
  );

  useEffect(() => {
    const calcInnerWidth = throttle(() => {
      setBreakpoint(getBreakPoint(window.innerWidth));
    }, 200);

    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);

  return {
    breakpoint,
    isXs: breakpoint === Breakpoints.XS,
    isSm: breakpoint === Breakpoints.SM,
    isMd: breakpoint === Breakpoints.MD,
    isLg: breakpoint === Breakpoints.LG,
  };
};

export default useBreakpoint;
