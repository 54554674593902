/**
 * Imports
 */
import { Tag } from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { SoftPos } from 'types/softpos';

/**
 * Styling
 */
const StyledTag = styled(Tag)`
  max-width: 100px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  white-space: normal;
`;

/**
 * Types
 */
export type StatusObj = {
  [key in SoftPos.TrxnStatusCode]: {
    label: string;
    color: string;
    key: SoftPos.TrxnStatusCode | 'pin_request';
  };
};
export interface TransactionsStatusProps {
  trxn: SoftPos.Trxn;
}

/**
 * Private helpers
 */
const _mapTrxnStatus = (trxn: SoftPos.Trxn) => {
  const statusObj: StatusObj = {
    A: {
      key: 'A',
      label: 'Advice',
      color: 'orange',
    },
    S: {
      key: 'S',
      label: 'Suspicious',
      color: 'red',
    },
    F: {
      key: 'F',
      label: 'Reject',
      color: 'red',
    },
    R: {
      key: 'R',
      label: 'Network Error', // Reversed
      color: 'red',
    },
    V: {
      key: 'V',
      label: 'Void',
      color: 'blue',
    },
    N: {
      key: 'N',
      label: 'Normal',
      color: 'red',
    },
    I: {
      key: 'I',
      label: 'Invalid',
      color: 'red',
    },
    P: {
      key: 'P',
      label: 'Problem',
      color: 'red',
    },
  };
  if (
    (trxn.responseCode === '70' && trxn.cardSource === 'VISA') ||
    (trxn.responseCode === '65' && trxn.cardSource === 'MC')
  ) {
    return {
      key: 'pin_request',
      label: 'PIN Request',
      color: 'blue',
    };
  } else {
    return statusObj[trxn.statusCode];
  }
};

/**
 * TransactionsStatus component
 */
const TransactionsStatus: React.FC<TransactionsStatusProps> = ({ trxn }) => {
  const { t } = useTranslation();
  const statusCodeObj = _mapTrxnStatus(trxn);
  const isApproved = trxn.status === 'approved';
  const isDeclined = trxn.status === 'declined';
  return (
    <>
      {isDeclined && (
        <StyledTag color={statusCodeObj.color}>
          {statusCodeObj.key === 'pin_request' ||
          trxn.responseDescription === 'Approved' ? (
            <>{t([`_Common.status_type.${statusCodeObj.key}`])}</>
          ) : (
            trxn.responseDescription
          )}
        </StyledTag>
      )}
      {isApproved && (
        <StyledTag color="green">{t('_Common.status_type.approved')}</StyledTag>
      )}
    </>
  );
};

/**
 * Exports
 */
export { TransactionsStatus };
