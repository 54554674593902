/**
 * Imports
 */
import { userSelector } from 'features/user/redux/selectors';
import { firebaseLogout } from 'firebaseAPI';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

/**
 * Styling
 */
const Content = styled.div`
  min-height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
`;

/**
 * LogoutPage component
 */
const LogoutPage: React.FC = () => {
  const history = useHistory();
  const user = useSelector(userSelector);
  /**
   * Logout
   */
  const _logout = async () => {
    if (user) {
      await firebaseLogout();
      history.replace('/login');
    } else {
      history.replace('/login');
    }
  };

  /**
   * Check if user is on logout page
   */
  React.useEffect(() => {
    _logout();
  }, []);
  return <></>;
};

/**
 * Exports
 */
export { LogoutPage };
