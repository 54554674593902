import React from 'react';
import styled from 'styled-components';

export type TableOptionsBarProps = {
  options: JSX.Element[];
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  flex-wrap: wrap;

  & > *:not(:first-of-type) {
    margin-left: 24px;

    @media (max-width: 600px) {
      margin-left: 0;
      margin-top: 16px;
    }
  }
  @media (max-width: 600px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

const TableOptionsBar: React.FC<TableOptionsBarProps> = (props) => {
  return <Container>{props.options}</Container>;
};

export { TableOptionsBar };
