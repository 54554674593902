import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FormGroup,
  FormGroupProps,
  FormGroupItem,
  FormGroupItemProps,
  VibrantIcon,
} from 'components';

import * as Styles from './SettingsForm.styles';
import { Firebase } from 'types/firebase';

export type SettingsFormProps = {
  data: Firebase.Merchant;
  onSave: (name: string, value: string | number) => void;
};

type FormFieldType = FormGroupProps & {
  inputs: Pick<
    FormGroupItemProps,
    'label' | 'name' | 'value' | 'isNotEditable'
  >[];
};

const SettingsForm: React.FC<SettingsFormProps> = (props) => {
  const [activeInputs, setActiveInputs] = useState<string[]>([]);
  const { t } = useTranslation();

  const handleToggleActiveInput = (name: string) => {
    const inputIndex = activeInputs.findIndex((x) => x === name);

    setActiveInputs((prevActiveInputs) => {
      if (inputIndex > -1) {
        return prevActiveInputs.filter((x) => x !== name);
      }

      return [...prevActiveInputs, name];
    });
  };

  const formFields = useMemo<FormFieldType[]>(() => {
    return [
      {
        title: () => t('Account.sections.company'),
        icon: <VibrantIcon icon="home" />,
        inputs: [
          {
            label: () => t('Account.inputs.company_name'),
            name: 'merchantTradingName',
            value: props.data?.merchantTradingName,
            isNotEditable: true,
          },
          {
            label: () => t('Account.inputs.company_reg_no'),
            name: 'companyRegistrationNo',
            value: props.data?.companyRegistrationNumber,
            isNotEditable: true,
          },
          {
            label: () => t('Account.inputs.address'),
            name: 'addressLine1',
            value: props.data?.addressLine1,
            isNotEditable: true,
          },
        ],
      },
      {
        title: () => t('Account.sections.transfers'),
        icon: <VibrantIcon icon="transaction" />,
        inputs: [
          {
            label: () => t('Account.inputs.iban'),
            name: 'iban',
            value: props.data?.iban,
            isNotEditable: true,
          },
        ],
      },
    ];
  }, [props.data?.companyRegistrationNumber]);

  return (
    <div>
      {formFields.map((form) => {
        const isFormDisabled = activeInputs.length > 0;

        return (
          <Styles.FormContainer key={form.title()}>
            <FormGroup
              icon={form.icon}
              title={form.title}
              isDisabled={isFormDisabled}
            >
              {form.inputs.map((input) => {
                const isInputDisabled =
                  isFormDisabled && !activeInputs.includes(input.name);

                const alternativeLabel = t('Account.helper.change_your', {
                  name: input.label,
                });

                return (
                  <FormGroupItem
                    key={input.name}
                    {...input}
                    alternativeLabel={alternativeLabel}
                    isDisabled={isInputDisabled}
                    onOpen={() => handleToggleActiveInput(input.name)}
                    onClose={() => handleToggleActiveInput(input.name)}
                    onSave={(newValue) => props.onSave(input.name, newValue)}
                  />
                );
              })}
            </FormGroup>
          </Styles.FormContainer>
        );
      })}
    </div>
  );
};

export { SettingsForm };
