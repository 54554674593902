/**
 * Imports
 */
import { DatePicker as DatePickerAntd } from 'antd';
import { setDates } from 'features/settlements/redux/actions';
import {
  endDateSelector,
  startDateSelector,
} from 'features/settlements/redux/selectors';
import { FixedDatesRange } from 'features/shared/types';
import moment, { Moment } from 'moment';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { DATE_FORMATS } from 'utils';
const { RangePicker } = DatePickerAntd;

/**
 * Styling
 */
const StyledRangedPicker = styled(RangePicker)`
  border: 1px solid ${(props) => props.theme.colors.Border.BorderSecondary};
  padding: 8.5px 16px;
`;

/**
 * DatePicker component
 */
const DatePicker: React.FC = () => {
  const endDate = useSelector(endDateSelector);
  const startDate = useSelector(startDateSelector);
  const dispatch = useDispatch();

  const _onChange = (dates: any) => {
    if (!!dates) {
      const [startDate, endDate] = dates as Moment[];
      dispatch(
        setDates({ endDate, startDate, fixedRange: FixedDatesRange.NONE }),
      );
    } else {
      dispatch(
        setDates({
          endDate: moment().endOf('day'),
          startDate: moment().startOf('day'),
          fixedRange: FixedDatesRange.NONE,
        }),
      );
    }
  };

  return (
    <StyledRangedPicker
      defaultValue={[startDate, endDate]}
      format={DATE_FORMATS.time}
      value={[endDate, startDate]}
      onChange={_onChange}
    />
  );
};

/**
 * Exports
 */
export { DatePicker };
