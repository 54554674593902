import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;

  button {
    color: ${(props) => props.theme.colors.Background.Breadcrumb};
    padding: 0px;
    margin-right: 18px;
  }
`;

export const TextValueContainer = styled.span`
  b {
    margin-left: 16px;
  }
`;
