import React, { useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';

import { Icon, Text, Input, Button } from 'components';

import * as Styles from './FormGroupItem.styles';

export type FormGroupItemProps = {
  label: () => string;
  alternativeLabel: string;
  name: string;
  value: string | number;
  isDisabled?: boolean;
  isNotEditable?: boolean;
  onSave: (text: string | number) => void;
  onOpen: () => void;
  onClose: () => void;
};

const FormGroupItem: React.FC<FormGroupItemProps> = (props) => {
  const [isEditing, setEditing] = useState(false);
  const [_value, setValue] = useState(props.value ?? '');

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setValue(event.target.value);
    props.onOpen();
  };

  const handleOpen = () => {
    setEditing(true);
    props.onOpen();
  };

  const handleClose = () => {
    setEditing(false);
    props.onClose();
  };

  const handleSave = () => {
    props.onSave(_value);
    setEditing(false);
  };

  return (
    <Styles.Container isDisabled={Boolean(props.isDisabled)}>
      {!isEditing && (
        <Styles.Content>
          <Styles.ContentGrid>
            <Styles.LabelContainer>
              <Styles.LabelText size="ui3">{props.label()}</Styles.LabelText>
              <Text size="ui2">{props.value}</Text>
            </Styles.LabelContainer>

            {!props.isNotEditable && (
              <Styles.ChangeButton type="text" onClick={handleOpen}>
                Change
              </Styles.ChangeButton>
            )}
          </Styles.ContentGrid>
        </Styles.Content>
      )}

      {isEditing && (
        <Styles.Content>
          <Styles.EditLabelContainer>
            <Text size="ui2" bold>
              {props.alternativeLabel}
            </Text>

            <Button ghost onClick={handleClose}>
              <Icon icon={<CloseOutlined />} />
            </Button>
          </Styles.EditLabelContainer>

          <Styles.InputContainer onSubmit={handleSave}>
            <Styles.LabelText size="ui3">{props.label()}</Styles.LabelText>

            <Styles.ContentGrid>
              <Input name={props.name} value={_value} onChange={handleChange} />
              <Styles.SaveButton type="primary" onClick={handleSave}>
                Save
              </Styles.SaveButton>
            </Styles.ContentGrid>
          </Styles.InputContainer>
        </Styles.Content>
      )}
    </Styles.Container>
  );
};

export { FormGroupItem };
