/**
 * Imports
 */
import { Button } from 'components';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import styled from 'styled-components';

/**
 * Styling
 */
const Root = styled.div`
  padding: 24px;
`;

/**
 * Types
 */
export interface PrintButtonProps {
  printContent: any;
}

/**
 * PrintButton component
 */
const PrintButton: React.FC<PrintButtonProps> = ({ printContent }) => {
  const reactToPrintContent = React.useCallback(() => {
    return printContent?.current;
  }, [printContent?.current]);

  const _handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: 'Receipt',
    removeAfterPrint: true,
  });

  return (
    <Button onClick={_handlePrint}>
      <Trans i18nKey="TransactionsDetails.transaction_optionsbar.print_btn" />
    </Button>
  );
};

/**
 * Exports
 */
export { PrintButton };
